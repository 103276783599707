const SvgAirfilter = (props) => {
  // const { colorMode } = useColorMode();
  // const iconColor = colorMode === "light" ? "white" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5rem"
      height="2.5rem"
      viewBox="0 0 100 100"
      // fill={iconColor}
      {...props}
    >
      <path d="m95.344 33.125.441-1.691a4.953 4.953 0 0 0-1.308-4.747L73.512 5.91a5.043 5.043 0 0 0-4.77-1.293l-1.722.442-.746-.742a3.527 3.527 0 0 0-4.95 0l-5.62 5.57a1.18 1.18 0 0 0 0 1.664l.097.094L11.297 55.75l-.113-.113a1.17 1.17 0 0 0-1.649 0l-5.59 5.539a3.517 3.517 0 0 0 0 4.996l.711.703-.414 1.598v-.004a5.12 5.12 0 0 0 1.352 4.914L26.418 94.02a5.203 5.203 0 0 0 4.933 1.336l1.625-.414.747.742h.003a3.527 3.527 0 0 0 4.95 0l5.617-5.567h.004a1.187 1.187 0 0 0 0-1.668l-.098-.093L88.703 44.25l.113.113a1.17 1.17 0 0 0 1.649 0l5.586-5.54h.004a3.521 3.521 0 0 0 0-4.991zM69.324 6.891a2.675 2.675 0 0 1 2.535.684l20.97 20.777c.652.648.917 1.597.687 2.492l-.098.375L68.961 6.985zm-11.855 6.406.566.563-44.508 44.102-.566-.563zM38.074 82.281l-.566-.562 44.504-44.102.566.562zm46.168-42.453.566.563-44.504 44.102-.566-.563zM35.84 80.07l-.566-.562 44.508-44.102.566.562zm-2.23-2.21-.567-.563 44.504-44.102.567.562zm-2.231-2.212-.566-.562 44.504-44.102.566.562zm-2.234-2.21-.567-.563 44.508-44.102.567.563zm-2.23-2.211-.567-.563 44.508-44.102.566.563zm-2.231-2.211-.567-.563 44.504-44.102.567.563zm-2.23-2.211-.567-.563L66.39 22.14l.566.563zm-2.235-2.211-.567-.563L64.16 19.93l.567.563zm-2.23-2.211-.567-.562L61.93 17.719l.566.562zm-2.231-2.21-.567-.563 44.504-44.102.567.562zm15.016 32.913a2.86 2.86 0 0 1-2.707-.73L7.243 71.719a2.774 2.774 0 0 1-.735-2.657l.075-.277 24.457 24.23zm6.254.934c-.457.449-1.192.449-1.649 0L5.6 64.504a1.167 1.167 0 0 1 0-1.664l4.76-4.72 31.446 31.16zm5.507-7.313-.566-.562L86.473 42.04l.566.562zM94.402 37.16l-4.761 4.719-31.445-31.16 4.777-4.734a1.17 1.17 0 0 1 1.648 0l29.781 29.512a1.167 1.167 0 0 1 0 1.664z" />
    </svg>
  );
};
export default SvgAirfilter;
