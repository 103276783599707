import { useForm } from "react-hook-form";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep11 } from "../SalesCompanyAnalysisRegStore";
import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep11() {
  const { companyPk } = useParams();
  const step11Data = useSelector((state: RootState) => state.step11);
  const { control, handleSubmit, register, watch } = useForm<ICompanyAnalsys>({
    defaultValues: step11Data,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step11"]) => {
    dispatch(updateStep11(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step10`); // 다음 페이지로 이동
    // navigate(-1); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step12`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  // console.log(watch("environments.0.is_new"));

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #4 Compressor 설치환경
        </Button>
        <Box py={3} />
        <Button
          variant={"solid"}
          shadow={"dark-lg"}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          _hover={{
            bgGradient: "linear(to-r, red.500, yellow.500)",
          }}
        >
          <Text color={"white"} fontSize={"xl"} fontWeight={"bold"}>
            룸 환경 평가
          </Text>
        </Button>
        <input
          // disabled={true} <<< 나중에 이거 사용한것들은 정리를 하는게..
          type="checkbox"
          defaultChecked
          {...register("environments.0.is_new")}
          // style={{ display: "none" }} <<< 순수 css 스타일을 적용할 경우.
          // hidden <<< 이건 순수 html 요소를 적용하는 방법!!! <<< 최고 심플함!
          hidden
        />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="룸온도차" />
            <Select
              color={"tomato"}
              defaultValue={0}
              borderLeftRadius={0}
              {...register("environments.0.room_temperature_difference")}
            >
              <option value={0}>미확인</option>
              <option value={40}>5℃미만</option>
              <option value={35}>5~10℃미만</option>
              <option value={30}>10~15℃미만</option>
              <option value={25}>15~20℃미만</option>
              <option value={20}>20℃이상</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="VOC/유분" />
            <Select
              color={"tomato"}
              defaultValue={0}
              borderLeftRadius={0}
              {...register("environments.0.voc_oilmist")}
            >
              <option value={0}>미확인</option>
              <option value={40}>0.5 mg/m3 이하</option>
              <option value={35}>0.5~1 mg/m3</option>
              <option value={32}>1~10 mg/m3</option>
              <option value={30}>10~20 mg/m3</option>
              <option value={20}>20 mg/m3 이상</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="공기오염도" />
            <Select
              color={"tomato"}
              defaultValue={0}
              borderLeftRadius={0}
              {...register("environments.0.air_pollution")}
            >
              <option value={0}>미확인</option>
              <option value={40}>Level 1</option>
              <option value={30}>Level 2</option>
              <option value={28}>Level 3</option>
              <option value={25}>Level 4</option>
              <option value={20}>Level 5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="일기동횟수" />
            <Select
              color={"tomato"}
              defaultValue={0}
              borderLeftRadius={0}
              {...register("environments.0.start_per_day")}
            >
              <option value={0}>미확인</option>
              <option value={120}>5회미만</option>
              <option value={100}>5~10회미만</option>
              <option value={90}>10~20회미만</option>
              <option value={80}>20~30회미만</option>
              <option value={60}>30회이상</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="업종구분" />
            <Select
              color={"tomato"}
              defaultValue={0}
              borderLeftRadius={0}
              {...register("environments.0.industry")}
            >
              <option value={0}>미확인</option>
              <option value={120}>전자부품,의료정밀,식음료</option>
              <option value={100}>자동차,운송장비,조립</option>
              <option value={90}>금속가공,기타기계,정유</option>
              <option value={80}>단조,조선,고무</option>
              <option value={60}>철강,시멘트,광물,주물</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={5} />
        <FormControl my={5}>
          <InputGroup>
            <VStack w={"100%"} spacing={0}>
              <InputLeftAddon
                w={"100%"}
                borderRightRadius={"md"}
                borderBottomRadius={0}
                children="접지상태"
              />
              <VStack
                w={"100%"}
                p={3}
                border={"1px"}
                borderColor={"white"}
                borderBottomRadius={"md"}
              >
                <HStack>
                  <input
                    type="checkbox"
                    {...register("environments.0.independent_grounding")}
                  />
                  <Text color={"white"} marginRight={3}>
                    단독접지
                  </Text>
                  <input
                    type="checkbox"
                    {...register("environments.0.hbeam_grounding")}
                  />
                  <Text color={"white"} marginRight={3}>
                    H빔접지
                  </Text>
                </HStack>
                <HStack>
                  <input
                    type="checkbox"
                    {...register("environments.0.vsd_joint")}
                  />
                  <Text color={"white"} marginRight={3}>
                    VSD 공동
                  </Text>
                  <input
                    type="checkbox"
                    {...register("environments.0.distribution_panel_joint")}
                  />
                  <Text color={"white"} marginRight={3}>
                    분전반 공동
                  </Text>
                </HStack>
                <HStack>
                  <input
                    type="checkbox"
                    {...register("environments.0.not_grounding")}
                  />
                  <Text color={"white"} marginRight={3}>
                    미설치
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </InputGroup>
        </FormControl>
        <Box py={5} />
        <FormControl my={5}>
          <InputGroup>
            <VStack w={"100%"} spacing={0}>
              <InputLeftAddon
                w={"100%"}
                borderRightRadius={"md"}
                borderBottomRadius={0}
                children="룸 배관"
              />
              <VStack
                w={"100%"}
                p={3}
                border={"1px"}
                borderColor={"white"}
                borderBottomRadius={"md"}
              >
                <HStack>
                  <input type="checkbox" {...register("environments.0.a25")} />
                  <Text color={"white"} marginRight={3}>
                    25A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a40")} />
                  <Text color={"white"} marginRight={3}>
                    40A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a50")} />
                  <Text color={"white"} marginRight={3}>
                    50A
                  </Text>
                </HStack>
                <HStack>
                  <input type="checkbox" {...register("environments.0.a65")} />
                  <Text color={"white"} marginRight={3}>
                    65A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a80")} />
                  <Text color={"white"} marginRight={3}>
                    80A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a100")} />
                  <Text color={"white"} marginRight={3}>
                    100A
                  </Text>
                </HStack>
                <HStack>
                  <input type="checkbox" {...register("environments.0.a125")} />
                  <Text color={"white"} marginRight={3}>
                    125A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a150")} />
                  <Text color={"white"} marginRight={3}>
                    150A
                  </Text>
                  <input type="checkbox" {...register("environments.0.a200")} />
                  <Text color={"white"} marginRight={3}>
                    200A
                  </Text>
                </HStack>
                <HStack>
                  <input
                    type="checkbox"
                    {...register("environments.0.over_a250")}
                  />
                  <Text color={"white"} marginRight={3}>
                    250A 이상
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl>
          <InputGroup>
            <InputLeftAddon borderBottomStartRadius={0} children="메인 배관" />
            <Input
              color={"white"}
              type="number"
              defaultValue={0}
              placeholder="예시)100"
              borderBottomEndRadius={0}
              {...register("environments.0.main_pipe_size")}
            />
            <InputRightAddon children="A" borderBottomRightRadius={0} />
          </InputGroup>
          <InputGroup>
            <VStack w={"100%"}>
              <VStack
                w={"100%"}
                p={3}
                border={"1px"}
                borderColor={"white"}
                borderBottomRadius={"md"}
              >
                <HStack>
                  <input
                    type="checkbox"
                    {...register("environments.0.branch_piping")}
                  />
                  <Text color={"white"} marginRight={3}>
                    가지배관
                  </Text>
                  <input
                    type="checkbox"
                    {...register("environments.0.loof_piping")}
                  />
                  <Text color={"white"} marginRight={3}>
                    루프배관
                  </Text>
                  <input
                    type="checkbox"
                    {...register("environments.0.etc_piping")}
                  />
                  <Text color={"white"} marginRight={3}>
                    기타
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </InputGroup>
        </FormControl>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
