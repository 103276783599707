import { Box, Button, Text } from "@chakra-ui/react";
import {
  AnimatePresence,
  animate,
  useAnimate,
  motion,
  useAnimation,
  useAnimationControls,
} from "framer-motion";
import OnestopLogoWhite from "../OnestopLogoWhite";
import { useEffect, useRef, useState } from "react";

export default function IntOnestop() {
  //
  //
  const controls = useAnimationControls();
  //
  //
  //

  //
  //
  //

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      marginTop={-20}
      h={"100vh"}
      bgGradient="linear(to-r, #2A2B6E, #085C94)"
    >
      {/* <motion.video
        style={{ position: "absolute" }}
        initial={{ display: "none" }}
        animate={controls}
        // animate={firstVideo}
        // transition={{ duration: 1 }}
        title="mountain"
        src="/assets/mountain2.mp4"
        autoPlay
        muted
        // controls
      /> */}
      {/* <Box position={"absolute"}>
        <motion.video
          initial={{ opacity: 0 }}
          animate={secondVideoLoad}
          transition={{ delay: 9, duration: 1 }}
          title="mountain"
          src="/assets/mountain1.mp4"
          autoPlay
          muted
          // controls
        />
      </Box> */}
      {/* <motion.div
        style={{ position: "absolute" }}
        initial={{ marginTop: 0, scale: 1, marginLeft: 200 }}
        animate={{ marginTop: -500, scale: 0.5, marginLeft: 0 }}
        transition={{ delay: 3, duration: 1 }}
      ></motion.div> */}
      <OnestopLogoWhite />

      {/* <Box position={"absolute"}>
        <motion.div
          initial={{ opacity: 0, marginTop: 0 }}
          animate={textLoad}
          transition={{ delay: 2, duration: 1 }}
        >
          <Box position={"relative"}>
            <Text
              // backgroundColor={"black"}
              color={"gray.100"}
              fontSize={"9xl"}
              fontWeight={"extrabold"}
              mixBlendMode={"difference"}
            >
              ESG 경영
            </Text>
          </Box>
          <Box position={"relative"}>
            <Text
              // backgroundColor={"black"}
              color={"gray.100"}
              fontSize={"7xl"}
              fontWeight={"extrabold"}
              mixBlendMode={"difference"}
            >
              Environment
            </Text>
          </Box>
          <Box position={"relative"}>
            <Text
              // backgroundColor={"black"}
              color={"gray.100"}
              fontSize={"5xl"}
              fontWeight={"extrabold"}
              mixBlendMode={"difference"}
            >
              에스피앤지 주식회사는 에너지절감
            </Text>
          </Box>
          <Box position={"relative"}>
            <Text
              // backgroundColor={"black"}
              color={"gray.100"}
              fontSize={"5xl"}
              fontWeight={"extrabold"}
              mixBlendMode={"difference"}
            >
              ESG 경영을 지속적으로 보급하여 지구온난화를 지연시키고
            </Text>
          </Box>
          <Box position={"relative"}>
            <Text
              // backgroundColor={"black"}
              color={"gray.100"}
              fontSize={"5xl"}
              fontWeight={"extrabold"}
              mixBlendMode={"difference"}
            >
              탄소감축을 통해 지구환경을 개선합니다
            </Text>
          </Box>
        </motion.div>
      </Box> */}
      {/* <motion.div animate={controls}>
        <h1>ㅗ모모</h1>
      </motion.div> */}
      {/* <button
        // style={{ position: "absolute" }}
        // initial={{ opacity: 1 }}
        // animate={startButton}
        // animate={controls}
        // transition={{ delay: 1, duration: 1 }}
        onClick={() => {
          controls.start({
            display: "block",
          });
        }}
      >
        start
      </button> */}
    </Box>
  );
}
