import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep2 } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep2() {
  const { companyPk } = useParams();
  const navigate = useNavigate();
  const step2Data = useSelector((state: RootState) => state.step2);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ICompanyAnalsys>({
    defaultValues: step2Data,
  });
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step2"]) => {
    dispatch(updateStep2(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step1`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step3`); // 다음 페이지로 이동
  };

  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  // console.log(watch());

  return (
    <ProtectedPage>
      {/* //
          .########..########.########....###....####.##......
          .##.....##.##..........##......##.##....##..##......
          .##.....##.##..........##.....##...##...##..##......
          .##.....##.######......##....##.....##..##..##......
          .##.....##.##..........##....#########..##..##......
          .##.....##.##..........##....##.....##..##..##......
          .########..########....##....##.....##.####.########
          // */}
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #1 기업 기본 정보 등록
        </Button>
        <Container justifyContent={"center"} alignItems={"center"}>
          <Accordion defaultIndex={[0, 1]} allowMultiple>
            <AccordionItem>
              <AccordionButton
                // borderRadius="md"
                boxShadow="dark-lg"
                backgroundColor={"red.500"}
              >
                <Box as="b" flex="1" textAlign="center" color={"white"}>
                  <Text>상 세 정 보</Text>
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="업종" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("sector")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="주요생산품" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("main_products")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="AIR 주사용처" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("used_air")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="연간 총 전력비" />
                    <Input
                      color={"white"}
                      type="number"
                      {...register("total_annual_power_cost")}
                    />
                    <InputRightAddon children="원" />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="에너지 사용등급" />
                    <Select
                      color={"tomato"}
                      placeholder="Choose a kind"
                      {...register("energy_size")}
                    >
                      <option value="all">일반</option>
                      <option value="high">에너지다소비</option>
                      <option value="goal">목표관리</option>
                      <option value="emission">배출권거래</option>
                    </Select>
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="kW단가" />
                    <Input
                      type="number"
                      color={"white"}
                      {...register("kw_cost")}
                    />
                    <InputRightAddon children="원" />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="한전고객번호" />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("kepco_number")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="한전파워플래너 아이디" />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("kepco_power_planer_id")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="한전파워플래너 비번" />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("kepco_power_planer_pass")}
                    />
                  </InputGroup>
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
