import {
  Grid,
  Text,
  Box,
  HStack,
  TabIndicator,
  VStack,
  Image,
  Button,
  useEditable,
  useBreakpointValue,
  FormControl,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import SvgAirfilter from "../../assets/Icon/Airfilter";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import SvgScrew from "../../assets/Icon/Screw";
import SvgMotor from "../../assets/Icon/Motor";
import SvgFan from "../../assets/Icon/Fan";
import SvgInvertor from "../../assets/Icon/Invertor";
import SvgCooler from "../../assets/Icon/Cooler";
import { FaRegHeart, FaStar } from "react-icons/fa";
import useUser from "../../lib/useUser";
import LoginModal from "../LoginModal";
import UnAuthorizedUser from "../UnAuthorizedUser";
import GridScrew from "./GridScrew";
import GridPart from "./GridPart";
import GridMainMotor from "./GridMainMotor";
import GridFanMotor from "./GridFanMotor";
import GridInvertor from "./GridInvertor";
import GridCooler from "./GridCooler";

export default function ServiceHome() {
  const { user } = useUser();

  return (
    <Tabs
      bgGradient="linear(to-r, blue.500, green.500)"
      isFitted
      px={3}
      position="relative"
      variant="unstyled"
    >
      <TabList>
        <Tab minW={"120"}>
          <Box>
            <VStack>
              <SvgAirfilter />
              <Text color={"white"}>Parts</Text>
            </VStack>
          </Box>
        </Tab>
        <Tab minW={"120"}>
          <VStack>
            <SvgScrew />
            <Text color={"white"}>Screw Overhaul</Text>
          </VStack>
        </Tab>
        <Tab minW={"120"}>
          <VStack>
            <SvgMotor />
            <Text color={"white"}>Main Motor</Text>
          </VStack>
        </Tab>
        <Tab minW={"120"}>
          <VStack>
            <SvgFan />
            <Text color={"white"}>Fan Motor</Text>
          </VStack>
        </Tab>
        <Tab minW={"120"}>
          <VStack>
            <SvgInvertor />
            <Text color={"white"}>Invetor</Text>
          </VStack>
        </Tab>
        <Tab minW={"120"}>
          <VStack>
            <SvgCooler />
            <Text color={"white"}>Cooler</Text>
          </VStack>
        </Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="white" borderRadius="1px" />

      {user?.is_spng ? (
        <>
          <TabPanels>
            <TabPanel className="Parts">
              <GridPart />
            </TabPanel>
            <TabPanel className="Screw">
              <GridScrew />
            </TabPanel>
            <TabPanel className="Main_Motor">
              <GridMainMotor />
            </TabPanel>
            <TabPanel className="Fan_Motor">
              <GridFanMotor />
            </TabPanel>
            <TabPanel className="Invertor">
              <GridInvertor />
            </TabPanel>
            <TabPanel className="Cooler">
              <GridCooler />
            </TabPanel>
          </TabPanels>
        </>
      ) : (
        <UnAuthorizedUser />
      )}
    </Tabs>
  );
}
