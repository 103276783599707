import ProtectedPage from "../ProtectedPage";
import GrantsSearch from "./SalesGrantsSearch";
import IntOnestop from "./SalesIntOnestop";

export default function Presentation() {
  const abc = 1;

  return (
    <ProtectedPage>
      <div>
        <IntOnestop />
        <GrantsSearch />
      </div>
    </ProtectedPage>
  );
}
