import { Box, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { RiKakaoTalkFill } from "react-icons/ri";

export default function SocialLogin() {
  let kakaoParams = {
    client_id: "5ad6185bba3b914bc083f33b75e4e0a8",
    redirect_uri: "https://spng.shop/social/kakao",
    response_type: "code",
  };

  const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?${new URLSearchParams(
    kakaoParams
  ).toString()}`;

  return (
    <Box mb={4}>
      <HStack my={3}>
        <Divider />
        <Text
          textTransform={"uppercase"} //대문자로 전환
          color={"gray.500"}
          fontSize={"xs"}
          as={"b"} //굵은 글씨로 전환
        >
          Or
        </Text>
        <Divider />
      </HStack>
      <VStack>
        <Button
          as="a"
          w="100%"
          href={kakaoUrl}
          leftIcon={
            <Box color={"black"} fontSize={"2xl"}>
              <RiKakaoTalkFill />
            </Box>
          }
          colorScheme={"yellow"}
        >
          Continue with KAKAOTALK
        </Button>
      </VStack>
    </Box>
  );
}
