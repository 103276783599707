import { Button, FormControl, Grid, Input, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React, { useRef, useEffect } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import SignaturePad from "signature_pad";
import { useParams } from "react-router-dom";

interface IForm {
  file: File;
}

export default function SignaturePadWrapper() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  const { register, handleSubmit, watch, reset, setValue } =
    useFormContext<IForm>();

  const handleClear = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const handleSave = () => {
    if (signaturePadRef.current) {
      const signatureData = signaturePadRef.current.toSVG();
      downloadSVG(signatureData, "signature.svg");
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const canvas = canvasRef.current;

    if (container && canvas) {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const canvasWidth = containerWidth;
      const canvasHeight = containerHeight;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const signaturePad = new SignaturePad(canvas, {
        minWidth: 2,
        maxWidth: 4,
        // ... 기타 옵션
      });

      signaturePadRef.current = signaturePad;

      return () => {
        signaturePad.off();
      };
    }
  }, []);

  const downloadSVG = (svgCode: string, filename: string) => {
    const blob = new Blob([svgCode], { type: "image/svg+xml" });
    const file = new File([blob], filename, {
      type: "image/svg+xml",
    });

    setValue("file", file);
  };

  // const onSubmit = (data: IForm) => {
  //   const file = data.file;
  //   if (file) {
  //     // 파일 업로드 등 필요한 처리 수행
  //     console.log("Uploaded file:", file);
  //   }
  // };

  return (
    <div ref={containerRef} style={{ height: "200px" }}>
      {/* <form></form> */}
      <canvas
        ref={canvasRef}
        style={{ border: "1px solid black", backgroundColor: "white" }}
      />
      {/* <button onClick={handleClear}>지우기</button>
      <button onClick={handleSave}>저장</button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="file"
          control={register}
          render={({ field }) => <input {...field} type="file" />}
        />
        <button type="submit">제출</button>
      </form> */}
      <VStack pt={5} gap={3}>
        <Button
          w="full"
          colorScheme="teal"
          variant="outline"
          onClick={handleClear}
        >
          지우기
        </Button>
        <Button w="full" colorScheme="red" onClick={handleSave} type="submit">
          저장
        </Button>
      </VStack>
    </div>
  );
}
