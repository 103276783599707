import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import ProtectedPage from "../ProtectedPage";
import { IApplicationProcedure } from "../../types";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function ApplyReg() {
  const { register, control } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "grant_applications",
    }
  );

  return (
    <ProtectedPage>
      <VStack align="start">
        <HStack>
          <Button
            onClick={() =>
              append({
                procedure_order: fields.length + 1,
                title: "",
                content: "",
                schedule: "",
              })
            }
          >
            입력칸 추가
          </Button>
          <Button
            backgroundColor={"tomato"}
            onClick={() =>
              fields.length > 1 ? remove(fields.length - 1) : undefined
            }
          >
            입력칸 삭제
          </Button>
        </HStack>

        <Grid templateColumns="repeat(3, 1fr)" gap={3}>
          {fields.map((item, index) => (
            <GridItem key={item.id}>
              <HStack spacing={3}>
                <VStack spacing={3}>
                  <Input
                    backgroundColor={"white"}
                    placeholder="순번"
                    {...register(
                      `grant_applications.${index}.procedure_order` as const
                    )}
                    defaultValue={index + 1}
                    isDisabled
                  />
                  <Input
                    backgroundColor={"white"}
                    placeholder="제목"
                    {...register(`grant_applications.${index}.title` as const)}
                  />
                  <Input
                    backgroundColor={"white"}
                    placeholder="세부내용"
                    {...register(
                      `grant_applications.${index}.content` as const
                    )}
                  />
                  <Input
                    backgroundColor={"white"}
                    placeholder="일정"
                    {...register(
                      `grant_applications.${index}.schedule` as const
                    )}
                  />
                </VStack>
                <Text color={"white"}>▶</Text>
              </HStack>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </ProtectedPage>
  );
}
