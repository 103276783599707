import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  VStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  updateStep2,
  updateStep7,
} from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";
import { useRef, useState } from "react";

export default function CompanyAnalysisRegStep7() {
  const { companyPk } = useParams();
  const step7Data = useSelector((state: RootState) => state.step7);
  const { control, handleSubmit, register } = useForm<ICompanyAnalsys>({
    defaultValues: step7Data,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "compressors_companys", // 폼 필드 배열의 이름
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(); // 현재 선택된 항목의 인덱스를 저장

  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleDelete = (index: any) => {
    setCurrentIndex(index); // 삭제할 항목의 인덱스를 저장
    onOpen(); // 대화 상자를 엽니다
  };

  const confirmDelete = () => {
    remove(currentIndex); // 실제로 항목을 삭제합니다
    onClose(); // 대화 상자를 닫습니다
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step7"]) => {
    dispatch(updateStep7(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step6`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step8`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #2 설비 보유현황 등록
        </Button>
        {/* //
        ..######...#######..##.....##.########..########..########..######...######...#######..########.
        .##....##.##.....##.###...###.##.....##.##.....##.##.......##....##.##....##.##.....##.##.....##
        .##.......##.....##.####.####.##.....##.##.....##.##.......##.......##.......##.....##.##.....##
        .##.......##.....##.##.###.##.########..########..######....######...######..##.....##.########.
        .##.......##.....##.##.....##.##........##...##...##.............##.......##.##.....##.##...##..
        .##....##.##.....##.##.....##.##........##....##..##.......##....##.##....##.##.....##.##....##.
        ..######...#######..##.....##.##........##.....##.########..######...######...#######..##.....##
        // */}
        <Button
          variant={"solid"}
          shadow={"dark-lg"}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
        >
          <Text color={"white"} fontSize={"xl"} fontWeight={"bold"}>
            Compressor 등록
          </Text>
        </Button>
        <Box py={5} />
        <Button
          colorScheme="green"
          w={"100%"}
          onClick={() =>
            append({
              maker: "",
              temp_model_name: "",
              nick_name: "",
              model_year: 0,
              hp: 0,
              voltage: 0,
              serial_number: "",
              overhaul_time: "",
              overhaul_period: "",
              load_factor: 0,
            })
          }
        >
          + Add Compressor
        </Button>

        <Grid
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
        >
          {fields.map((field, index) => (
            <GridItem key={field.id}>
              <Controller
                control={control}
                name={`compressors_companys.${index}`}
                render={({ field }) => (
                  <>
                    <Container justifyContent={"center"} alignItems={"center"}>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                          <AccordionButton boxShadow="dark-lg">
                            <Box
                              as="b"
                              flex="1"
                              textAlign="center"
                              color={"white"}
                            >
                              <Text>COMPRESSOR #{index + 1}</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="MAKER" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.maker` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="모델명" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.temp_model_name` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="호기" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.nick_name` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="년식" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `compressors_companys.${index}.model_year` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="용량" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `compressors_companys.${index}.hp` as const
                                  )}
                                />
                                <InputRightAddon children="HP" />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="전압" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `compressors_companys.${index}.voltage` as const
                                  )}
                                />
                                <InputRightAddon children="V" />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="S/N" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.serial_number` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="O/H 시점" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.overhaul_time` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="O/H 주기" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `compressors_companys.${index}.overhaul_period` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="부하율" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `compressors_companys.${index}.load_factor` as const
                                  )}
                                />
                                <InputRightAddon children="%" />
                              </InputGroup>
                            </FormControl>
                            <Button
                              colorScheme="red"
                              w={"100%"}
                              onClick={() => handleDelete(index)}
                            >
                              삭 제
                            </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Container>
                  </>
                )}
              />
            </GridItem>
          ))}
        </Grid>

        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              항목 삭제
            </AlertDialogHeader>

            <AlertDialogBody>
              이 항목을 정말로 삭제하시겠습니까?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                삭제
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ProtectedPage>
  );
}
