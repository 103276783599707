import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import {
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { IGArea, IGItem, IGrantDetail } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { getGItems } from "../../api";
import { useEffect } from "react";
import GrantDetailItemList from "./NewBusinessGrantDetailItemList";
import GrantDetailItemListCheck from "./NewBusinessGrantDetailItemListCheck";

export default function GrantItemCheck() {
  const { data: gItemData } = useQuery<IGItem[]>([`gItem`], getGItems);
  const { register, control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "grant_areas",
  });
  const grantAreas = useWatch({
    name: "grant_areas",
    control,
  });

  const handleCheck = () => {};

  return (
    <ProtectedPage>
      <VStack marginTop={-5} h={"100%"} padding={"3rem"}>
        <HStack>
          <Button
            onClick={() =>
              append({
                support_facilities: "",
                scope_of_support: "",
                grants_gitem: [],
              })
            }
          >
            입력칸 추가
          </Button>
          <Button
            backgroundColor={"tomato"}
            onClick={() =>
              fields.length > 1 ? remove(fields.length - 1) : undefined
            }
          >
            입력칸 삭제
          </Button>
        </HStack>
        <Grid templateColumns="repeat(1, 1fr)" gap={"2rem"}>
          {fields.map((item, index) => (
            <GridItem
              key={item.id}
              borderRadius={"2xl"}
              shadow={"dark-lg"}
              px={"2rem"}
            >
              <HStack spacing={3}>
                <Text color={"white"} fontWeight="bold" fontSize="6xl">
                  {index + 1}.
                </Text>
                <Stack p={"2rem"} gap={"2rem"} w={"full"}>
                  <VStack align={"start"}>
                    <HStack>
                      <Text fontSize={"lg"} color={"white"}>
                        1) 지원설비
                      </Text>
                      <Text fontSize={"sm"} color={"tomato"}>
                        ※ 화면에 보여지는 내용을 입력하세요
                      </Text>
                    </HStack>
                    <Textarea
                      placeholder="예)인버터 제어형 공기압축기"
                      backgroundColor={"white"}
                      {...register(
                        `grant_areas.${index}.support_facilities` as const
                      )}
                    />
                  </VStack>
                  <Stack>
                    <HStack>
                      <Text fontSize={"lg"} color={"white"}>
                        2) 지원범위
                      </Text>
                      <Text fontSize={"sm"} color={"tomato"}>
                        ※ 화면에 보여지는 내용을 입력하세요
                      </Text>
                    </HStack>
                    <Textarea
                      placeholder="예)인버터 제어형 공기압축기"
                      backgroundColor={"white"}
                      {...register(
                        `grant_areas.${index}.scope_of_support` as const
                      )}
                    />
                  </Stack>
                  <Stack spacing={5}>
                    <Text fontSize={"lg"} color={"red"}>
                      3) 세부 아이템을 체크하세요!
                    </Text>
                    <GrantDetailItemListCheck index={index} />
                  </Stack>
                </Stack>
              </HStack>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </ProtectedPage>
  );
}
