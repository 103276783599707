import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep4 } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep4() {
  const { companyPk } = useParams();
  const step4Data = useSelector((state: RootState) => state.step4);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICompanyAnalsys>({
    defaultValues: step4Data,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customer_employees", // 폼 필드 배열의 이름
  });

  const dispatch = useDispatch();

  const onSubmit = (data: StepData["step4"]) => {
    dispatch(updateStep4(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step3`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step5`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      {/* //
              .##....##.########.##....##....##.....##....###....##....##
              .##...##..##........##..##.....###...###...##.##...###...##
              .##..##...##.........####......####.####..##...##..####..##
              .#####....######......##.......##.###.##.##.....##.##.##.##
              .##..##...##..........##.......##.....##.#########.##..####
              .##...##..##..........##.......##.....##.##.....##.##...###
              .##....##.########....##.......##.....##.##.....##.##....##
              // */}
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #1 기업 기본 정보 등록
        </Button>
        <Button
          colorScheme="green"
          w={"100%"}
          onClick={() =>
            append({
              role: "",
              name: "",
              position_title: "",
              phone_number: "",
              email: "",
              etc_info: "",
              e_nara_id: "",
              e_nara_pass: "",
              esg_platform_id: "",
              esg_platform_pass: "",
            })
          }
        >
          + Add Key Man
        </Button>
        <Grid
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
        >
          {fields.map((field, index) => (
            <GridItem key={field.id}>
              <Controller
                control={control}
                name={`customer_employees.${index}`}
                render={({ field }) => (
                  <>
                    <Container justifyContent={"center"} alignItems={"center"}>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                          <AccordionButton
                            color={"black"}
                            // borderRadius="md"
                            boxShadow="dark-lg"
                            backgroundColor={"orange.500"}
                          >
                            <Box
                              as="b"
                              flex="1"
                              textAlign="center"
                              color={"white"}
                            >
                              <Text>Key Man #{index + 1}</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="역활" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.role` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="이름" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.name` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="직책" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.position_title` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="전화번호" />
                                <Input
                                  type="tel"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.phone_number` as const,
                                    {
                                      pattern: {
                                        value: /^\+82\d{9,10}$/,
                                        message:
                                          "휴대폰번호를 +8201012345678 형식으로 입력해 주세요.",
                                      },
                                    }
                                  )}
                                />
                              </InputGroup>
                              {errors.customer_employees?.[index]
                                ?.phone_number && (
                                <Text fontSize={"sm"} color={"tomato"}>
                                  {
                                    errors.customer_employees?.[index]
                                      ?.phone_number?.message
                                  }
                                </Text>
                              )}
                            </FormControl>

                            <Text fontSize={"sm"} color={"tomato"}>
                              ※ 휴대폰번호: +8201012345678 형식으로
                              입력하십시오.
                            </Text>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="e-mail" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.email` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>

                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="기타 참고사항" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.etc_info` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="e나라도움id" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.e_nara_id` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="e나라도움pass" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.e_nara_pass` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="esg플랫폼id" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.esg_platform_id` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="esg플랫폼pass" />
                                <Input
                                  type="text"
                                  color={"white"}
                                  {...register(
                                    `customer_employees.${index}.esg_platform_pass` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Container>
                  </>
                )}
              />
            </GridItem>
          ))}
        </Grid>
        <Button
          colorScheme="red"
          w={"100%"}
          onClick={() => remove(fields.length - 1)}
        >
          - Remove Last Key Man
        </Button>
        <Box py={5} />

        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
