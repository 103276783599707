import Cookie from "js-cookie";
import { MutationFunction, QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import {
  ICompanyAnalsys,
  ICompanyListFilterOptions,
  ICustomerUsers,
  IEditCompanyAnalsys,
  IGArea,
  IGItem,
  IGrantDetail,
  IOrganizer,
  ITiniestCustomer,
  ITinyCompressor,
  ITinyUser,
} from "./types";
import NewBusinessHome from "./components/routes/NewBusinessHome";
import { useSelector } from "react-redux";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.spng.shop/api/v1/",
  withCredentials: true,
});

// export const getGridScrew = ({ pageParam = 1 }) =>
//   instance
//     .get("compressors/partgrid", {
//       params: {
//         page: pageParam,
//       },
//     })
//     .then((response) => response.data);

//
//
//
//
// 수정전 데이터 : 1page 잘 불러와짐. 2page 불러 올때 아래와 같은 문제발생
// http://127.0.0.1:8000/api/v1/compressors/partgridhttp://127.0.0.1:8000/api/v1/compressors/partgrid?page=2
// 즉, 아래 get 코드처럼 기존이 주소&pageParam이 합쳐져버림
// 가장 현실적인 방법 getNextPageParam를 설정할때 "?page=2"를 추출해야함.
//
export const getGridSearchComp = (searchQuery: any) =>
  instance
    .get(`compressors/searchcomp?search=${searchQuery}`)
    .then((response) => response.data);
//
//
//
// 아래 코드는 성공한 코드이므로 건드리지 말것
export const getGridPart = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridpart?page=${pageParam}`)
    .then((response) => response.data);
//
export const getGridScrew = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridscrewoverhaul?page=${pageParam}`)
    .then((response) => response.data);
//
export const getGridMainMotor = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridmainmotorrepair?page=${pageParam}`)
    .then((response) => response.data);
//
export const getGridFanMotor = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridfanmotorrepair?page=${pageParam}`)
    .then((response) => response.data);
//
export const getGridInvertor = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridinvertoroverhaul?page=${pageParam}`)
    .then((response) => response.data);
//
export const getGridCooler = ({ pageParam = 1 }) =>
  instance
    .get(`compressors/gridcoolercleaning?page=${pageParam}`)
    .then((response) => response.data);
//

//
//
//
//

// export const getParts = () =>
//   instance.get(`compressors/parts?cursor=`).then((response) => response.data);

/// 아래는 원래 있던코드... 2023.06.05
// export const getParts = (page: number) =>
//   instance.get(`compressors/?page=` + page).then((response) => response.data);

// export const getParts = () =>
//   instance.get(`compressors/`).then((response) => response.data);
// export const getParts = (page = 1) =>
//   instance.get(`compressors?page=` + page).then((response) => response.data);

export const getCompressor = ({ queryKey }: QueryFunctionContext) => {
  const [_, compressorPk] = queryKey;
  return instance
    .get(`compressors/${compressorPk}`)
    .then((response) => response.data);
};

export const getCompressorInspections = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, compressorPk] = queryKey;
  return instance
    .get(`compressors/${compressorPk}/inspections`)
    .then((response) => response.data);
};

//// Success ========================================================
export const getCustomerUser = ({ queryKey }: QueryFunctionContext) => {
  const [_, compressorPk] = queryKey;
  return instance
    .get(`compressors/${compressorPk}/customeruser`)
    .then((response) => response.data);
};

export const getSpngUser = () =>
  instance.get(`users/spng`).then((response) => response.data);

export const getInspectionCustomerUser = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, inspectionPk] = queryKey;
  return instance
    .get(`inspections/${inspectionPk}/customeruser`)
    .then((response) => response.data);
};
//// Success ========================================================

//// Success ========================================================
export const getAutoSelectCompressorUploadInspection = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, compressorPk] = queryKey;
  return instance
    .get(`compressors/${compressorPk}/inspectionupload`)
    .then((response) => response.data);
};
//// Success ========================================================
//// Success ========================================================
// export const getInspectionCheck = (something: any) => {
//   console.log(something);
//   instance.get(`inspections/20/check`).then((response) => response.data);
// };

export const getInspectionCheck = ({ queryKey }: QueryFunctionContext) => {
  const [_, inspectionPk] = queryKey;
  return instance
    .get(`inspections/${inspectionPk}/check`)
    .then((response) => response.data);
};

//// Success ========================================================

// export const getInspection = () =>
//   instance.get(`inspections/1`).then((response) => response.data);

export const getInspection = ({ queryKey }: QueryFunctionContext) => {
  const [_, inspectionPk] = queryKey;
  return instance
    .get(`inspections/${inspectionPk}`)
    .then((response) => response.data);
};

export const getInspectionReport = ({ queryKey }: QueryFunctionContext) => {
  const [_, inspectionPk, uuid] = queryKey;
  return instance
    .get(`inspections/${inspectionPk}/${uuid}`)
    .then((response) => response.data);
};

// export const postInpection = ()

export const getMe = () =>
  instance.get(`users/me`).then((response) => response.data);

export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const kakaoLogin = (code: string) =>
  instance
    .post(
      `users/kakao`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.status);

export interface IUsernameLoginVariables {
  username: string;
  password: string;
}
export interface IUsernameLoginSuccess {
  ok: string;
}
export interface IUsernameLoginError {
  error: string;
}

export const usernameLogIn = ({
  username,
  password,
}: IUsernameLoginVariables) =>
  instance.post(
    `users/log-in`,
    { username, password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );

export const getUploadURL = () =>
  instance
    .post(`medias/photos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUploadImageVarialbes {
  file: FileList;
  uploadURL: string;
}

export const uploadImage = ({ file, uploadURL }: IUploadImageVarialbes) => {
  const form = new FormData();
  form.append("file", file[0]);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export interface IUploadsignVarialbes {
  file: File;
  uploadURL: string;
}

export const uploadSign = ({ file, uploadURL }: IUploadsignVarialbes) => {
  const form = new FormData();
  form.append("file", file);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export interface ICreateCompanySignVariables {
  description: string;
  file: string;
  inspectionPk: string;
}
export const createCompanySign = ({
  description,
  file,
  inspectionPk,
}: ICreateCompanySignVariables) =>
  instance
    .post(
      `inspections/${inspectionPk}/companysign`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface ICreateCustomerSignVariables {
  description: string;
  file: string;
  inspectionPk: string;
}

export const createCustomerSign = ({
  description,
  file,
  inspectionPk,
}: ICreateCustomerSignVariables) =>
  instance
    .post(
      `inspections/${inspectionPk}/customersign`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
// export const uploadSigntest = (file: File, uploadURL: string) => {
//   const form = new FormData();
//   form.append("file", file);
//   return axios
//     .post(uploadURL, form, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then((response) => response.data);
// };

export interface ICreatePhotoVariables {
  description: string;
  file: string;
  inspectionPk: string;
}

export const createPhoto = ({
  description,
  file,
  inspectionPk,
}: ICreatePhotoVariables) =>
  instance
    .post(
      `inspections/${inspectionPk}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

export const createOrganizer = ({
  organizer_name,
  organizer_cis,
}: IOrganizer) =>
  instance
    .post(
      `grants/organizer`,
      { organizer_name, organizer_cis },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

export interface IEmailVariables {
  compressorPk: string;
}

export const emailScrew = (variables: IEmailVariables) =>
  instance
    .post(`compressors/email-screw`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const emailPart = (variables: IEmailVariables) =>
  instance
    .post(`compressors/email-part`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface ISendEmailVariables {
  inspectionPk: string;
}

export interface ISendEmailVariables {
  inspectionPk: string;
}

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

export const sendEmail = ({ inspectionPk }: ISendEmailVariables) =>
  instance
    .post(`inspections/${inspectionPk}/sendemail`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUploadInspectionVariables {
  insp_dates: string;
  run_times: number;
  preventive_maintenance: boolean;
  equipment_inspection: boolean;
  etc: boolean;
  c_part_exchange: boolean;
  screw_overhaul: boolean;
  main_motor_repair: boolean;
  main_motor_grease: boolean;
  fan_motor_repair: boolean;
  invertor_overhaul: boolean;
  cooler_cleaning: boolean;
  other_repair: string;
  insp_load_conditon_1: null | string;
  insp_load_conditon_2: null | string;
  insp_load_conditon_3: null | string;
  insp_load_conditon_4: null | string;
  insp_load_conditon_5: null | string;
  insp_load_conditon_6: null | string;
  insp_unload_condition_1: null | string;
  insp_unload_condition_2: null | string;
  insp_unload_condition_3: null | string;
  insp_unload_condition_4: null | string;
  insp_unload_condition_5: null | string;
  insp_unload_condition_6: null | string;
  insp_counter_2: null | number;
  insp_counter_3: null | string;
  insp_counter_4: null | string;
  insp_counter_5: null | string;
  insp_counter_6: null | string;
  insp_counter_7: null | string;
  insp_inverter_1: null | string;
  insp_inverter_2: null | string;
  insp_inverter_3: null | string;
  insp_inverter_4: null | string;
  insp_inverter_5: null | string;
  insp_inverter_6: null | string;
  insp_main_motor_1: null | string;
  insp_main_motor_2: null | string;
  insp_main_motor_3: null | string;
  insp_main_motor_4: null | string;
  insp_main_motor_5: null | string;
  insp_main_motor_6: null | string;
  insp_fan_motor_1: null | string;
  insp_fan_motor_2: null | string;
  insp_fan_motor_3: null | string;
  insp_fan_motor_4: null | string;
  insp_fan_motor_5: null | string;
  insp_fan_motor_6: null | string;
  engineer_diagnosis_first_page: string;
  improvement_requirements_first_page: string;
  third_page: boolean;
  second_page: boolean;
  insp_oil_type: null | string;
  insp_oil_etc: null | string;
  insp_oil_drain: null | string;
  insp_oil_filter: null | string;
  insp_oil_filter_number: null | string;
  insp_oil_filter_etc: null | string;
  insp_air_filter: null | string;
  insp_air_filter_number: null | string;
  insp_air_filter_etc: null | string;
  insp_oil_separator: null | string;
  insp_oil_separator_number: null | string;
  insp_oil_separator_etc: null | string;
  insp_condensator_traps: null | string;
  insp_grease: null | string;
  insp_grease_liter: null | string;
  insp_inverter_filter: null | string;
  insp_cooler: null | string;
  engineer_diagnosis_second_page: null | string;
  improvement_requirements_second_page: null | string;
  compressor: null | string;
  customer: null | number;
  customer_inspection_employees: ICustomerUsers[];
  company_inspection_employees: ITinyUser[];
  customer_inspection_sign_employees: ICustomerUsers;
  company_inspection_sign_employees: ITinyUser;
  inspection_photo: FileList;
}

export const uploadInspection = (variables: IUploadInspectionVariables) =>
  instance.post(`inspections/`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });

export interface IEditInspectionVariables {
  id: string;
  insp_dates: string;
  run_times: number;
  preventive_maintenance: boolean;
  equipment_inspection: boolean;
  etc: boolean;
  c_part_exchange: boolean;
  screw_overhaul: boolean;
  main_motor_repair: boolean;
  main_motor_grease: boolean;
  fan_motor_repair: boolean;
  invertor_overhaul: boolean;
  cooler_cleaning: boolean;
  other_repair: string;
  insp_load_conditon_1: null | string;
  insp_load_conditon_2: null | string;
  insp_load_conditon_3: null | string;
  insp_load_conditon_4: null | string;
  insp_load_conditon_5: null | string;
  insp_load_conditon_6: null | string;
  insp_unload_condition_1: null | string;
  insp_unload_condition_2: null | string;
  insp_unload_condition_3: null | string;
  insp_unload_condition_4: null | string;
  insp_unload_condition_5: null | string;
  insp_unload_condition_6: null | string;
  insp_counter_2: null | number;
  insp_counter_3: null | string;
  insp_counter_4: null | string;
  insp_counter_5: null | string;
  insp_counter_6: null | string;
  insp_counter_7: null | string;
  insp_inverter_1: null | string;
  insp_inverter_2: null | string;
  insp_inverter_3: null | string;
  insp_inverter_4: null | string;
  insp_inverter_5: null | string;
  insp_inverter_6: null | string;
  insp_main_motor_1: null | string;
  insp_main_motor_2: null | string;
  insp_main_motor_3: null | string;
  insp_main_motor_4: null | string;
  insp_main_motor_5: null | string;
  insp_main_motor_6: null | string;
  insp_fan_motor_1: null | string;
  insp_fan_motor_2: null | string;
  insp_fan_motor_3: null | string;
  insp_fan_motor_4: null | string;
  insp_fan_motor_5: null | string;
  insp_fan_motor_6: null | string;
  engineer_diagnosis_first_page: string;
  improvement_requirements_first_page: string;
  insp_oil_type: null | string;
  insp_oil_etc: null | string;
  insp_oil_drain: null | string;
  insp_oil_filter: null | string;
  insp_oil_filter_number: null | string;
  insp_oil_filter_etc: null | string;
  insp_air_filter: null | string;
  insp_air_filter_number: null | string;
  insp_air_filter_etc: null | string;
  insp_oil_separator: null | string;
  insp_oil_separator_number: null | string;
  insp_oil_separator_etc: null | string;
  insp_condensator_traps: null | string;
  insp_grease: null | string;
  insp_grease_liter: null | string;
  insp_inverter_filter: null | string;
  insp_cooler: null | string;
  engineer_diagnosis_second_page: null | string;
  improvement_requirements_second_page: null | string;
  compressor: ITinyCompressor;
  customer: ITiniestCustomer;
  customer_inspection_employees: ICustomerUsers[];
  company_inspection_employees: ITinyUser[];
  customer_inspection_sign_employees: ICustomerUsers;
  company_inspection_sign_employees: ITinyUser;
}

export const editInspection = ({
  id,
  insp_dates,
  run_times,
  preventive_maintenance,
  equipment_inspection,
  etc,
  c_part_exchange,
  screw_overhaul,
  main_motor_repair,
  main_motor_grease,
  fan_motor_repair,
  invertor_overhaul,
  cooler_cleaning,
  other_repair,
  insp_load_conditon_1,
  insp_load_conditon_2,
  insp_load_conditon_3,
  insp_load_conditon_4,
  insp_load_conditon_5,
  insp_load_conditon_6,
  insp_unload_condition_1,
  insp_unload_condition_2,
  insp_unload_condition_3,
  insp_unload_condition_4,
  insp_unload_condition_5,
  insp_unload_condition_6,
  insp_counter_2,
  insp_counter_3,
  insp_counter_4,
  insp_counter_5,
  insp_counter_6,
  insp_counter_7,
  insp_inverter_1,
  insp_inverter_2,
  insp_inverter_3,
  insp_inverter_4,
  insp_inverter_5,
  insp_inverter_6,
  insp_main_motor_1,
  insp_main_motor_2,
  insp_main_motor_3,
  insp_main_motor_4,
  insp_main_motor_5,
  insp_main_motor_6,
  insp_fan_motor_1,
  insp_fan_motor_2,
  insp_fan_motor_3,
  insp_fan_motor_4,
  insp_fan_motor_5,
  insp_fan_motor_6,
  engineer_diagnosis_first_page,
  improvement_requirements_first_page,
  insp_oil_type,
  insp_oil_etc,
  insp_oil_drain,
  insp_oil_filter,
  insp_oil_filter_number,
  insp_oil_filter_etc,
  insp_air_filter,
  insp_air_filter_number,
  insp_air_filter_etc,
  insp_oil_separator,
  insp_oil_separator_number,
  insp_oil_separator_etc,
  insp_condensator_traps,
  insp_grease,
  insp_grease_liter,
  insp_inverter_filter,
  insp_cooler,
  engineer_diagnosis_second_page,
  improvement_requirements_second_page,
  customer_inspection_employees,
  company_inspection_employees,
  customer_inspection_sign_employees,
  company_inspection_sign_employees,
}: IEditInspectionVariables) =>
  instance
    .put(
      `inspections/${id}/check`,
      {
        id,
        insp_dates,
        run_times,
        preventive_maintenance,
        equipment_inspection,
        etc,
        c_part_exchange,
        screw_overhaul,
        main_motor_repair,
        main_motor_grease,
        fan_motor_repair,
        invertor_overhaul,
        cooler_cleaning,
        other_repair,
        insp_load_conditon_1,
        insp_load_conditon_2,
        insp_load_conditon_3,
        insp_load_conditon_4,
        insp_load_conditon_5,
        insp_load_conditon_6,
        insp_unload_condition_1,
        insp_unload_condition_2,
        insp_unload_condition_3,
        insp_unload_condition_4,
        insp_unload_condition_5,
        insp_unload_condition_6,
        insp_counter_2,
        insp_counter_3,
        insp_counter_4,
        insp_counter_5,
        insp_counter_6,
        insp_counter_7,
        insp_inverter_1,
        insp_inverter_2,
        insp_inverter_3,
        insp_inverter_4,
        insp_inverter_5,
        insp_inverter_6,
        insp_main_motor_1,
        insp_main_motor_2,
        insp_main_motor_3,
        insp_main_motor_4,
        insp_main_motor_5,
        insp_main_motor_6,
        insp_fan_motor_1,
        insp_fan_motor_2,
        insp_fan_motor_3,
        insp_fan_motor_4,
        insp_fan_motor_5,
        insp_fan_motor_6,
        engineer_diagnosis_first_page,
        improvement_requirements_first_page,
        insp_oil_type,
        insp_oil_etc,
        insp_oil_drain,
        insp_oil_filter,
        insp_oil_filter_number,
        insp_oil_filter_etc,
        insp_air_filter,
        insp_air_filter_number,
        insp_air_filter_etc,
        insp_oil_separator,
        insp_oil_separator_number,
        insp_oil_separator_etc,
        insp_condensator_traps,
        insp_grease,
        insp_grease_liter,
        insp_inverter_filter,
        insp_cooler,
        engineer_diagnosis_second_page,
        improvement_requirements_second_page,
        customer_inspection_employees,
        company_inspection_employees,
        customer_inspection_sign_employees,
        company_inspection_sign_employees,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
export interface IUpdateInspectionPrintUrlVariables {
  inspectionPk: string;
  uuid: string;
}

export const updateInspectionPrintUrl = ({
  inspectionPk,
  uuid,
}: IUpdateInspectionPrintUrlVariables) =>
  instance
    .put(
      `inspections/${inspectionPk}/printreportuuid`,
      {
        uuid,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

//////////////////여기서 부터는 영업쪽 개발 api 들////////////////

export const getCustomersSearch = (searchQuery: any) =>
  instance
    .get(`customers/searchcustomer?search=${searchQuery}`)
    .then((response) => response.data);

export const getCustomer = ({ queryKey }: QueryFunctionContext) => {
  const [_, customerPk] = queryKey;
  return instance
    .get(`customers/${customerPk}`)
    .then((response) => response.data);
};

export const getCompanyStatus = ({ queryKey }: QueryFunctionContext) => {
  const [_, customerPk] = queryKey;
  return instance
    .get(`customers/${customerPk}/company_status/`)
    .then((response) => response.data);
};

export const getExecutiveStatus = ({ queryKey }: QueryFunctionContext) => {
  const [_, customerPk] = queryKey;
  return instance
    .get(`customers/${customerPk}/executive_status/`)
    .then((response) => response.data);
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////// 아래는 지원사업 조회시 필요한 사항들 ///////////
//
// .##....##.########.##......##.########..##.....##..######..####.##....##.########..######...######.
// .###...##.##.......##..##..##.##.....##.##.....##.##....##..##..###...##.##.......##....##.##....##
// .####..##.##.......##..##..##.##.....##.##.....##.##........##..####..##.##.......##.......##......
// .##.##.##.######...##..##..##.########..##.....##..######...##..##.##.##.######....######...######.
// .##..####.##.......##..##..##.##.....##.##.....##.......##..##..##..####.##.............##.......##
// .##...###.##.......##..##..##.##.....##.##.....##.##....##..##..##...###.##.......##....##.##....##
// .##....##.########..###..###..########...#######...######..####.##....##.########..######...######.
//
// ..######...########.....###....##....##.########..######.
// .##....##..##.....##...##.##...###...##....##....##....##
// .##........##.....##..##...##..####..##....##....##......
// .##...####.########..##.....##.##.##.##....##.....######.
// .##....##..##...##...#########.##..####....##..........##
// .##....##..##....##..##.....##.##...###....##....##....##
// ..######...##.....##.##.....##.##....##....##.....######.

export interface IFindGrantsVariables {
  company_size_all: boolean;
  company_size_small: boolean;
  company_size_mid: boolean;
  company_size_major: boolean;
  energy_size_all: boolean;
  energy_size_high: boolean;
  energy_size_goal: boolean;
  energy_size_emission: boolean;
}

export const getSearchGrants = (searchQuery: IFindGrantsVariables) => {
  const params = new URLSearchParams();

  for (const key in searchQuery) {
    if (searchQuery[key as keyof IFindGrantsVariables] === true) {
      // 오직 True 값만 URL에 추가합니다.
      params.append(key, "True");
    }
  }
  return instance
    .get(`grants/?${params.toString()}`)
    .then((response) => response.data);
};

export const uploadGItem = (variables: IGItem) =>
  instance.post(`grants/g_item`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
export const uploadGArea = (variables: IGArea) =>
  instance.post(`grants/g_area`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });

export const getGItems = () =>
  instance.get(`grants/g_item`).then((response) => response.data);

export const getOrganizers = () =>
  instance.get(`grants/organizer`).then((response) => response.data);

export const uploadGrantDetail = (variables: IGrantDetail) =>
  instance.post(`grants/grant_detail`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });

export const getGrantList = () =>
  instance.get(`grants/`).then((response) => response.data);

export const getGrantCheck = ({ queryKey }: QueryFunctionContext) => {
  const [_, grantPk] = queryKey;
  return instance.get(`grants/${grantPk}`).then((response) => response.data);
};

export const editGrant = ({
  id,
  grant_name,
  organizer,
  base_year,
  announcement_date,
  announcement,
  business_overview,
  submit_start_date,
  submit_end_date,
  business_start_date,
  business_end_date,
  company_size_all,
  company_size_small,
  company_size_mid,
  company_size_major,
  energy_size_all,
  energy_size_high,
  energy_size_goal,
  energy_size_emission,
  budget,
  support_details,
  support_target,
  support_ratio_amount,
  grant_applications,
  grant_areas,
}: IGrantDetail) =>
  instance
    .put(
      `grants/${id}`,
      {
        id,
        grant_name,
        organizer,
        base_year,
        announcement_date,
        announcement,
        business_overview,
        submit_start_date,
        submit_end_date,
        business_start_date,
        business_end_date,
        company_size_all,
        company_size_small,
        company_size_mid,
        company_size_major,
        energy_size_all,
        energy_size_high,
        energy_size_goal,
        energy_size_emission,
        budget,
        support_details,
        support_target,
        support_ratio_amount,
        grant_applications,
        grant_areas,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

//
//
// ..######.....###....##.......########..######.
// .##....##...##.##...##.......##.......##....##
// .##........##...##..##.......##.......##......
// ..######..##.....##.##.......######....######.
// .......##.#########.##.......##.............##
// .##....##.##.....##.##.......##.......##....##
// ..######..##.....##.########.########..######.
//
//

// export const uploadCompanyAnalsys = () => {
//   // Redux 스토어에서 필요한 데이터 가져오기
//   const companyAnalysisData = useSelector((state) => state);
//   console.log(companyAnalysisData);
// };

export const uploadCompanyAnalsys = (variables: ICompanyAnalsys) =>
  instance.post(`customers/company-analysis`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });

export const getCompanyAnalsysList = (
  filterOptions: ICompanyListFilterOptions
) => {
  const params = new URLSearchParams();
  Object.entries(filterOptions).forEach(([key, value]) => {
    if (value) {
      params.append(key, value.toString());
    }
  });
  const queryString = params.toString();
  return instance
    .get(`customers/company-analysis-list?${queryString}`)
    .then((response) => response.data);
};

export const getCompanyAnalsysDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, companyPk] = queryKey;
  return instance
    .get(`customers/company-analysis/${companyPk}`)
    .then((response) => response.data);
};

export const editCompanyAnalsys = ({
  id,
  company_employees,
  sales_man,
  sales_manager,
  servie_sales_man,
  service_engineer_man,
  servie_manager,
  developer,
  name,
  ceo,
  address,
  sales_amount,
  credit_rating,
  company_size,
  working_hours,
  working_days,
  tel,
  tax_id,
  sector,
  main_products,
  used_air,
  total_annual_power_cost,
  energy_size,
  kw_cost,
  kepco_number,
  group_company,
  factory,
  major_sales,
  major_purchases,
  factories,
  group_companies,
  sales_to,
  purchases_from,
  customer_employees,
  applicable_item,
  proposed_grant,
  compressors_companys,
  air_tanks,
  refrigerated_dryers,
  desiccant_dryers,
  after_cooler,
  oil_water_separator,
  auto_trap,
  representative_opinion,
  current_service_provider,
  is_genuine,
  exchange_filters,
  exchange_oils,
  hot_water,
  heating,
  process_use,
  use_electricity,
  use_lpg,
  use_oil,
  other_load_machines,
  environments,
  price_sensitivity,
  genuine_preference,
  importance_technical,
  importance_facility_management,
  relationship_preferences,
  importance_relationship,
  direct_export,
  indirect_export,
  export_country,
  export_customer,
  large_company_primary_vendor,
  large_company_secondary_vendor,
  large_company_name,
  large_company_annual_sales_amount,
  required_sustainability_report,
  required_iso50001_certification,
  required_company_name,
  company_analysis_date,
  facility,
  energy,
}: IEditCompanyAnalsys) =>
  instance
    .put(
      `customers/company-analysis/${id}`,
      {
        id,
        company_employees,
        sales_man,
        sales_manager,
        servie_sales_man,
        service_engineer_man,
        servie_manager,
        developer,
        name,
        ceo,
        address,
        sales_amount,
        credit_rating,
        company_size,
        working_hours,
        working_days,
        tel,
        tax_id,
        sector,
        main_products,
        used_air,
        total_annual_power_cost,
        energy_size,
        kw_cost,
        kepco_number,
        group_company,
        factory,
        major_sales,
        major_purchases,
        factories,
        group_companies,
        sales_to,
        purchases_from,
        customer_employees,
        applicable_item,
        proposed_grant,
        compressors_companys,
        air_tanks,
        refrigerated_dryers,
        desiccant_dryers,
        after_cooler,
        oil_water_separator,
        auto_trap,
        representative_opinion,
        current_service_provider,
        is_genuine,
        exchange_filters,
        exchange_oils,
        hot_water,
        heating,
        process_use,
        use_electricity,
        use_lpg,
        use_oil,
        other_load_machines,
        environments,
        price_sensitivity,
        genuine_preference,
        importance_technical,
        importance_facility_management,
        relationship_preferences,
        importance_relationship,
        direct_export,
        indirect_export,
        export_country,
        export_customer,
        large_company_primary_vendor,
        large_company_secondary_vendor,
        large_company_name,
        large_company_annual_sales_amount,
        required_sustainability_report,
        required_iso50001_certification,
        required_company_name,
        company_analysis_date,
        facility,
        energy,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
