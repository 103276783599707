import {
  Box,
  Button,
  Checkbox,
  HStack,
  Image,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { IPrintCompanyAnalsys } from "../../types";
import { getCompanyAnalsysDetail } from "../../api";
import ProtectedPage from "../ProtectedPage";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

export default function SalesCompanyAnalysisPrint() {
  const { companyPk } = useParams();
  const { isLoading, data, refetch } = useQuery<IPrintCompanyAnalsys>(
    [`company`, companyPk],
    getCompanyAnalsysDetail
  );

  const sectionW = 16;
  const costW = 20;
  const totalCostW = 32;
  const usageW = 40;
  const toeW = 24;
  const tco2W = 24;
  const resultsW = 32;

  const facility_typeW = 28;
  const facility_nameW = 28;
  const facility_capacityW = 28;
  const facility_capacity_unitW = 28;
  const facility_energy_sourceW = 28;
  const total_annual_energy_usageW = 28;

  const translateFacilityType = (type: string | undefined) => {
    switch (type) {
      case "production":
        return "생산설비";
      case "utility":
        return "유틸리티";
    }
  };

  const translateCompanySize = (size: string | undefined) => {
    switch (size) {
      case "small":
        return "중소기업";
      case "mid":
        return "중견기업";
      case "major":
        return "대기업";
      default:
        return size;
    }
  };
  const translateEnergySize = (size: string | undefined) => {
    switch (size) {
      case "all":
        return "일반";
      case "high":
        return "에너지다소비";
      case "goal":
        return "목표관리제";
      case "emission":
        return "배출권할당대상";
      default:
        return size;
    }
  };

  const items = [
    { text: "배출권할당", value: "emission" },
    { text: "목표관리", value: "goal" },
    { text: "에너지다소비", value: "high" },
    { text: "일반", value: "all" },
  ];

  // function formatPowerCost(cost:any) {
  //   if (cost == null) return "-";
  //   return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  // }
  const componentRef = useRef(null);
  const FloatingButtonPrint = () => {
    return (
      <Box position="fixed" bottom="10px" right="10px">
        <ReactToPrint
          trigger={() => <Button colorScheme="red">인쇄</Button>}
          content={() => componentRef.current}
        />
      </Box>
    );
  };

  return (
    <div>
      <FloatingButtonPrint />
      <ProtectedPage>
        <Box m={5} maxW={752} ref={componentRef}>
          {/* //
      .##.....##.########....###....########..########.########.
      .##.....##.##.........##.##...##.....##.##.......##.....##
      .##.....##.##........##...##..##.....##.##.......##.....##
      .#########.######...##.....##.##.....##.######...########.
      .##.....##.##.......#########.##.....##.##.......##...##..
      .##.....##.##.......##.....##.##.....##.##.......##....##.
      .##.....##.########.##.....##.########..########.##.....##
      // */}
          <Text
            textAlign={"center"}
            fontWeight={"black"}
            fontSize={"2xl"}
            bgClip="text"
            bgGradient="linear(to-r, #2A2B6E, #085C94)"
          >
            ONE STOP 에너지절감 분석표
          </Text>
          <HStack justifyContent={"space-between"}>
            <Box maxW={"20vh"}>
              <Image src="https://imagedelivery.net/LStsWRQowienWhMtIwNrAQ/625e403e-b710-4ac9-affc-433b6296d700/public" />
            </Box>
            <Box />
            <VStack spacing={0}>
              <HStack>
                <Text fontSize={"xs"}>작성일자:</Text>
                <Text fontSize={"xs"}>{data?.company_analysis_date}</Text>
              </HStack>
              <HStack>
                <Text fontSize={"xs"}>작성자:</Text>
                <Text fontSize={"xs"}>{data?.sales_man[0].name}</Text>
              </HStack>
            </VStack>
          </HStack>
          {/* //
          .########.....###.....######..####..######.
          .##.....##...##.##...##....##..##..##....##
          .##.....##..##...##..##........##..##......
          .########..##.....##..######...##..##......
          .##.....##.#########.......##..##..##......
          .##.....##.##.....##.##....##..##..##....##
          .########..##.....##..######..####..######.
          // */}
          <Box py={1} bgGradient="linear(to-r, #2A2B6E, #085C94)">
            <Text textAlign={"center"} color={"white"}>
              기본정보
            </Text>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={
                  "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0.5px 0 0"
                }
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  업체명
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={28}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0.5px 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.name}
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={20}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0.5px 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  주소
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={72}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0.5px 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.address}
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={24}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0.5px 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  대표이사
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={20}
                boxShadow={
                  "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                }
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.ceo}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  매출액(억원)
                </Text>
              </Box>
              <Box
                minH={"26px"}
                minW={20}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.sales_amount}
                </Text>
              </Box>
              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  기업규모
                </Text>
              </Box>
              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {translateCompanySize(data?.company_size)}
                </Text>
              </Box>

              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  사업자번호
                </Text>
              </Box>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.tax_id}
                </Text>
              </Box>
              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  전화번호
                </Text>
              </Box>
              <Box
                minW={32}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.tel}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  업종
                </Text>
              </Box>
              <Box
                minW={28}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  {data?.sector}
                </Text>
              </Box>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  주요 생산품
                </Text>
              </Box>
              <Box
                minW={28}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.main_products}
                </Text>
              </Box>
              <Box
                minW={28}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  근무 시간/일수
                </Text>
              </Box>

              <Box
                minW={16}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.working_hours}/{data?.working_days}
                </Text>
              </Box>
              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  신용등급
                </Text>
              </Box>
              <Box
                minW={16}
                minH={"26px"}
                boxShadow={"0 0 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.credit_rating}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  KEY MAN
                </Text>
              </Box>
              <Box
                minW={60}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.customer_employees[0]?.name}_
                  {data?.customer_employees[0]?.position_title} /
                  {data?.customer_employees[0]?.phone_number}
                </Text>
              </Box>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  관리 책임자
                </Text>
              </Box>
              <Box
                minW={60}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.customer_employees[1]?.name}_
                  {data?.customer_employees[1]?.position_title} /
                  {data?.customer_employees[1]?.phone_number}
                </Text>
              </Box>
            </HStack>
          </Box>

          <Box>
            <HStack spacing={0}></HStack>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  계열 회사
                </Text>
              </Box>
              <Box
                minW={72}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.group_company}
                </Text>
              </Box>

              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  종사업장(공장)
                </Text>
              </Box>
              <Box
                minW={72}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0 0 0, -0.5px 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.factory}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  주요 구매처
                </Text>
              </Box>
              <Box
                minW={72}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.major_purchases}
                </Text>
              </Box>

              <Box
                minW={20}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"xs"} textAlign={"center"}>
                  주요 판매처
                </Text>
              </Box>
              <Box
                minW={72}
                minH={"26px"}
                boxShadow={
                  "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                }
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.major_sales}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={1} />

          {/* //
          .########.##....##.########.########...######...##....##
          .##.......###...##.##.......##.....##.##....##...##..##.
          .##.......####..##.##.......##.....##.##..........####..
          .######...##.##.##.######...########..##...####....##...
          .##.......##..####.##.......##...##...##....##.....##...
          .##.......##...###.##.......##....##..##....##.....##...
          .########.##....##.########.##.....##..######......##...
          // */}
          <Box py={1} bgGradient="linear(to-r, #2A2B6E, #085C94)">
            <Text textAlign={"center"} color={"white"}>
              에너지 정보
            </Text>
          </Box>
          <HStack spacing={0}>
            <SimpleGrid>
              {[
                ...(data?.energy ?? []),
                ...Array.from({
                  length: 1 - (data?.energy?.length ?? 0),
                }).map(() => null),
              ].map((energy, index) =>
                energy ? (
                  // 실제 데이터가 있는 칸을 렌더링
                  <HStack spacing={0}>
                    <VStack spacing={0}>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {energy.information_year}
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          전기
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          가스
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          기타
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          합계
                        </Text>
                      </Box>
                    </VStack>
                    <VStack spacing={0}>
                      <HStack spacing={0}>
                        <Box
                          minW={costW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            단가(원)
                          </Text>
                        </Box>
                        <Box
                          minW={totalCostW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            년간 비용(원)
                          </Text>
                        </Box>
                        <Box
                          minW={usageW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            사용량(/year)
                          </Text>
                        </Box>
                        <Box
                          minW={toeW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            toe
                          </Text>
                        </Box>
                        <Box
                          minW={tco2W}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            tCO<sub>2-eq</sub>
                          </Text>
                        </Box>
                      </HStack>
                      <VStack spacing={0}>
                        {/* //
                        .########.##.......########..######..########
                        .##.......##.......##.......##....##....##...
                        .##.......##.......##.......##..........##...
                        .######...##.......######...##..........##...
                        .##.......##.......##.......##..........##...
                        .##.......##.......##.......##....##....##...
                        .########.########.########..######.....##...
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              {energy?.electricity_cost}
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.total_annual_electricity_cost != null
                                ? energy?.total_annual_electricity_cost
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_electricity_usage != null
                                ? energy?.annual_electricity_usage
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                              &nbsp;&nbsp;&nbsp;kWh&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_electricity_toe}
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_electricity_tco2}
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        ..######......###.....######.
                        .##....##....##.##...##....##
                        .##.........##...##..##......
                        .##...####.##.....##..######.
                        .##....##..#########.......##
                        .##....##..##.....##.##....##
                        ..######...##.....##..######.
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              {energy?.gas_cost}
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.total_annual_gas_cost != null
                                ? energy?.total_annual_gas_cost
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_gas_usage != null
                                ? energy?.annual_gas_usage
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                              &nbsp;&nbsp;m<sup>3</sup>/min
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_gas_toe}
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_gas_tco2}
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        .########.########..######.
                        .##..........##....##....##
                        .##..........##....##......
                        .######......##....##......
                        .##..........##....##......
                        .##..........##....##....##
                        .########....##.....######.
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              {energy?.etc_energy_cost}
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.total_annual_etc_energy_cost != null
                                ? energy?.total_annual_etc_energy_cost
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_etc_usage}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              -
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        .########..#######..########....###....##......
                        ....##....##.....##....##......##.##...##......
                        ....##....##.....##....##.....##...##..##......
                        ....##....##.....##....##....##.....##.##......
                        ....##....##.....##....##....#########.##......
                        ....##....##.....##....##....##.....##.##......
                        ....##.....#######.....##....##.....##.########
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.total_anuual_all_cost != null
                                ? energy?.total_anuual_all_cost
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_total_toe}
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              {energy?.annual_total_tco2}
                            </Text>
                          </Box>
                        </HStack>
                      </VStack>
                    </VStack>
                  </HStack>
                ) : (
                  //
                  // //
                  // .########..##..........###....##....##.##....##
                  // .##.....##.##.........##.##...###...##.##...##.
                  // .##.....##.##........##...##..####..##.##..##..
                  // .########..##.......##.....##.##.##.##.#####...
                  // .##.....##.##.......#########.##..####.##..##..
                  // .##.....##.##.......##.....##.##...###.##...##.
                  // .########..########.##.....##.##....##.##....##
                  // //
                  <HStack spacing={0}>
                    <VStack spacing={0}>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          ?
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          전기
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          가스
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          기타
                        </Text>
                      </Box>
                      <Box
                        minW={sectionW}
                        minH={"26px"}
                        boxShadow={
                          "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                        }
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          합계
                        </Text>
                      </Box>
                    </VStack>
                    <VStack spacing={0}>
                      <HStack spacing={0}>
                        <Box
                          minW={costW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            단가(원)
                          </Text>
                        </Box>
                        <Box
                          minW={totalCostW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            년간 비용(원)
                          </Text>
                        </Box>
                        <Box
                          minW={usageW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            사용량(/year)
                          </Text>
                        </Box>
                        <Box
                          minW={toeW}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            toe
                          </Text>
                        </Box>
                        <Box
                          minW={tco2W}
                          minH={"26px"}
                          boxShadow={
                            "0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            tCO<sub>2-eq</sub>
                          </Text>
                        </Box>
                      </HStack>
                      <VStack spacing={0}>
                        {/* //
                        .########.##.......########..######..########
                        .##.......##.......##.......##....##....##...
                        .##.......##.......##.......##..........##...
                        .######...##.......######...##..........##...
                        .##.......##.......##.......##..........##...
                        .##.......##.......##.......##....##....##...
                        .########.########.########..######.....##...
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        ..######......###.....######.
                        .##....##....##.##...##....##
                        .##.........##...##..##......
                        .##...####.##.....##..######.
                        .##....##..#########.......##
                        .##....##..##.....##.##....##
                        ..######...##.....##..######.
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        .########.########..######.
                        .##..........##....##....##
                        .##..........##....##......
                        .######......##....##......
                        .##..........##....##......
                        .##..........##....##....##
                        .########....##.....######.
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              -
                            </Text>
                          </Box>
                        </HStack>
                        {/* //
                        .########..#######..########....###....##......
                        ....##....##.....##....##......##.##...##......
                        ....##....##.....##....##.....##...##..##......
                        ....##....##.....##....##....##.....##.##......
                        ....##....##.....##....##....#########.##......
                        ....##....##.....##....##....##.....##.##......
                        ....##.....#######.....##....##.....##.########
                        // */}
                        <HStack key={index} spacing={0}>
                          <Box
                            minW={costW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={totalCostW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={usageW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"}>
                              -
                            </Text>
                          </Box>
                          <Box
                            minW={toeW}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                          <Box
                            minW={tco2W}
                            minH={"26px"}
                            boxShadow={
                              "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                            }
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"end"}
                          >
                            <Text textAlign={"center"} fontSize={"xs"} pr={3}>
                              ?
                            </Text>
                          </Box>
                        </HStack>
                      </VStack>
                    </VStack>
                  </HStack>
                )
              )}
            </SimpleGrid>

            {/* //
            .########..########..######..##.....##.##.......########
            .##.....##.##.......##....##.##.....##.##..........##...
            .##.....##.##.......##.......##.....##.##..........##...
            .########..######....######..##.....##.##..........##...
            .##...##...##.............##.##.....##.##..........##...
            .##....##..##.......##....##.##.....##.##..........##...
            .##.....##.########..######...#######..########....##...
            // */}
            <VStack spacing={0}>
              <Box
                minW={resultsW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  에너지등급
                </Text>
              </Box>
              <HStack spacing={0}>
                <Box
                  maxH="104px"
                  boxShadow="0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    // borderRadius={12}
                    // px={3}
                    // mx={0}
                    minW={8}
                    minH="104px"
                    bgGradient="linear(to-b, red.800, orange.500, yellow.300, green.600 )"
                  />
                </Box>
                <VStack spacing={0}>
                  {items.map((item, index) => (
                    <Box
                      key={index}
                      minW={24}
                      minH="26px"
                      boxShadow="0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {data?.energy_size === item.value && <MdLocationOn />}
                      <Text
                        textAlign="center"
                        fontSize={"xs"}
                        fontWeight={
                          data?.energy_size === item.value ? "black" : "normal"
                        }
                        // color={
                        //   data?.energy_size === item.value ? "White" : "black"
                        // }
                      >
                        {item.text}
                      </Text>
                    </Box>
                  ))}
                </VStack>
              </HStack>
            </VStack>
          </HStack>
          {/* //
          ..######...#######..##.....##.########.
          .##....##.##.....##.###...###.##.....##
          .##.......##.....##.####.####.##.....##
          .##.......##.....##.##.###.##.########.
          .##.......##.....##.##.....##.##.......
          .##....##.##.....##.##.....##.##.......
          ..######...#######..##.....##.##.......
          // */}
          <Box py={1} />
          <Box py={1} bgGradient="linear(to-r, #2A2B6E, #085C94)">
            <Text textAlign={"center"} color={"white"}>
              공기압축기 보유현황
            </Text>
          </Box>
          <HStack spacing={0}>
            <VStack spacing={0}>
              <Box
                minW={24}
                minH={"26px"}
                boxShadow={
                  "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                }
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  AIR 주사용처
                </Text>
              </Box>
              <Box
                minW={24}
                minH={"156px"}
                boxShadow={
                  "0 -0.5px 0 0, 0 0.5px 0 0, -0.5px 0 0 0, 0.5px 0 0 0"
                }
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  {data?.used_air}
                </Text>
              </Box>
            </VStack>

            <VStack spacing={0}>
              <HStack spacing={0}>
                <Box
                  minW={24}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    MAKER
                  </Text>
                </Box>
                <Box
                  minW={10}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    호기
                  </Text>
                </Box>
                <Box
                  minW={16}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    년식
                  </Text>
                </Box>
                <Box
                  minW={24}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    용량(HP)
                  </Text>
                </Box>
                <Box
                  minW={16}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    전압
                  </Text>
                </Box>
                <Box
                  minW={24}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    S/N
                  </Text>
                </Box>
                <Box
                  minW={16}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    O/H 시점
                  </Text>
                </Box>
                <Box
                  minW={20}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    O/H 주기
                  </Text>
                </Box>
                <Box
                  minW={14}
                  minH={"26px"}
                  boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text textAlign={"center"} fontSize={"xs"}>
                    부하율
                  </Text>
                </Box>
              </HStack>
              <SimpleGrid>
                {[
                  ...(data?.compressors_companys ?? []),
                  ...Array.from({
                    length: 6 - (data?.compressors_companys?.length ?? 0),
                  }).map(() => null),
                ].map((comp, index) =>
                  comp ? (
                    // 실제 데이터가 있는 칸을 렌더링
                    <HStack key={index} spacing={0}>
                      <Box
                        minW={24}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.maker}
                        </Text>
                      </Box>
                      <Box
                        minW={10}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.nick_name}
                        </Text>
                      </Box>
                      <Box
                        minW={16}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.model_year}
                        </Text>
                      </Box>
                      <Box
                        minW={24}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.temp_model_name}
                        </Text>
                      </Box>
                      <Box
                        minW={16}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.voltage}
                        </Text>
                      </Box>
                      <Box
                        minW={24}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.serial_number}
                        </Text>
                      </Box>
                      <Box
                        minW={16}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.overhaul_time}
                        </Text>
                      </Box>
                      <Box
                        minW={20}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.overhaul_period}
                        </Text>
                      </Box>
                      <Box
                        minW={14}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {comp?.load_factor}
                        </Text>
                      </Box>
                    </HStack>
                  ) : (
                    // 빈 칸을 렌더링
                    <div key={`empty-${index}`}>
                      <HStack spacing={0}>
                        <Box
                          minW={24}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={10}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={16}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={24}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={16}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={24}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={16}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={20}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                        <Box
                          minW={14}
                          minH={"26px"}
                          boxShadow={
                            "0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"
                          }
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}></Text>
                        </Box>
                      </HStack>
                    </div>
                  )
                )}
              </SimpleGrid>
            </VStack>
          </HStack>
          <Box py={1} />
          <Box py={1} bgGradient="linear(to-r, #2A2B6E, #085C94)">
            <Text textAlign={"center"} color={"white"}>
              주요 부하기기 현황
            </Text>
          </Box>
          <VStack spacing={0}>
            <HStack spacing={0}>
              <Box
                minW={facility_typeW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  설비구분
                </Text>
              </Box>
              <Box
                minW={facility_nameW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  설비명
                </Text>
              </Box>
              <Box
                minW={facility_capacityW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  용량
                </Text>
              </Box>
              <Box
                minW={facility_capacity_unitW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  용량단위
                </Text>
              </Box>
              <Box
                minW={facility_energy_sourceW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  사용에너지
                </Text>
              </Box>
              <Box
                minW={total_annual_energy_usageW}
                minH={"26px"}
                boxShadow={"0 -0.5px 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text textAlign={"center"} fontSize={"xs"}>
                  연간에너지사용량
                </Text>
              </Box>
            </HStack>
            <SimpleGrid>
              {[
                ...(data?.facility ?? []),
                ...Array.from({
                  length: 5 - (data?.facility?.length ?? 0),
                }).map(() => null),
              ].map((facility, index) =>
                facility ? (
                  // 실제 데이터가 있는 칸을 렌더링
                  <HStack key={index} spacing={0}>
                    <Box
                      minW={facility_typeW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {translateFacilityType(facility?.facility_type)}
                      </Text>
                    </Box>
                    <Box
                      minW={facility_nameW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {facility?.facility_name}
                      </Text>
                    </Box>
                    <Box
                      minW={facility_capacityW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {facility?.facility_capacity}
                      </Text>
                    </Box>
                    <Box
                      minW={facility_capacity_unitW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {facility?.facility_capacity_unit}
                      </Text>
                    </Box>
                    <Box
                      minW={facility_energy_sourceW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {facility?.facility_energy_source}
                      </Text>
                    </Box>
                    <Box
                      minW={total_annual_energy_usageW}
                      minH={"26px"}
                      boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text textAlign={"center"} fontSize={"xs"}>
                        {facility?.total_annual_energy_usage}
                      </Text>
                    </Box>
                  </HStack>
                ) : (
                  // 빈 칸을 렌더링
                  <div key={`empty-${index}`}>
                    <HStack spacing={0}>
                      <Box
                        minW={facility_typeW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                      <Box
                        minW={facility_nameW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                      <Box
                        minW={facility_capacityW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                      <Box
                        minW={facility_capacity_unitW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                      <Box
                        minW={facility_energy_sourceW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                      <Box
                        minW={total_annual_energy_usageW}
                        minH={"26px"}
                        boxShadow={"0 0 0 0, 0 0.5px 0 0, 0 0 0 0, 0.5px 0 0 0"}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}></Text>
                      </Box>
                    </HStack>
                  </div>
                )
              )}
            </SimpleGrid>
          </VStack>

          <Box py={1} />
        </Box>
      </ProtectedPage>
    </div>
  );
}
