import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Grid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ICompressorInspections } from "../../types";
import { getCompressorInspections } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaCamera, FaEdit } from "react-icons/fa";
import { MdBuild, MdCall } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";

export default function InspectionList({
  pk,
  insp_dates,
  run_times,
  company_inspection_employees,
  c_part_exchange,
  screw_overhaul,
  main_motor_repair,
  main_motor_grease,
  fan_motor_repair,
  invertor_overhaul,
  cooler_cleaning,
  uuid,
}: ICompressorInspections) {
  const navigate = useNavigate();
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/inspections/${pk}/photos`);
  };
  const onEditClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/inspections/${pk}/check`);
  };
  const onSendEmailClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/inspections/${pk}/sendemail`);
  };
  return (
    <Link to={`/inspections/${pk}/${uuid}`}>
      <Box p={3}>
        <Stack
          direction={"row"}
          bgGradient="linear(to-r, teal.500, green.500)"
          boxShadow={"md"}
          rounded={"2xl"}
          justify={"space-between"}
          align={"center"}
          wrap={"wrap"}
          p={3}
          px={10}
        >
          <Button
            leftIcon={<FaCamera />}
            border={"1px"}
            borderColor={"twitter.600"}
            onClick={onCameraClick}
            colorScheme="twitter"
            shadow={"md"}
          >
            사진 등록
          </Button>
          <Button
            border={"1px"}
            borderColor={"pink.600"}
            onClick={onEditClick}
            leftIcon={<FaEdit />}
            colorScheme="pink"
            shadow={"md"}
          >
            <Text>수정 및 제출</Text>
          </Button>
          <Button
            leftIcon={<TfiEmail />}
            colorScheme="linkedin"
            variant="solid"
            onClick={onSendEmailClick}
            shadow={"md"}
          >
            Send Email
          </Button>
          <Text color={"white"} as="b" fontSize={"xl"}>
            {insp_dates}
          </Text>
          <Text color={"white"} as="b" fontSize={"xl"}>
            {run_times}
          </Text>
          <Stack direction="row" spacing={4}>
            <AvatarGroup max={2}>
              {company_inspection_employees.map(
                (company_inspection_employees) => (
                  <Box position={"relative"}>
                    <Avatar
                      size="md"
                      name={company_inspection_employees.name}
                      src={company_inspection_employees.avatar}
                    />
                    <Box position={"absolute"} bottom={-1} right={-3}>
                      <Badge
                        borderRadius="full"
                        variant="subtle"
                        colorScheme="blue"
                      >
                        {company_inspection_employees.position_title}
                      </Badge>
                    </Box>
                  </Box>
                )
              )}
            </AvatarGroup>
          </Stack>
          <Box>
            <CheckboxGroup
              colorScheme="red"
              defaultValue={[
                "Parts",
                "Overhaul",
                "MainMotor",
                "FanMotor",
                "Invertor",
                "Cooler",
              ]}
            >
              <Stack wrap={"wrap"} spacing={[1, 5]} direction="row">
                <Checkbox isReadOnly isChecked={c_part_exchange}>
                  Parts
                </Checkbox>
                <Checkbox isReadOnly isChecked={screw_overhaul}>
                  Overhaul
                </Checkbox>
                <Checkbox isReadOnly isChecked={main_motor_repair}>
                  MainMotor
                </Checkbox>
                <Checkbox isReadOnly isChecked={main_motor_grease}>
                  MainMotor Grease
                </Checkbox>
                <Checkbox isReadOnly isChecked={fan_motor_repair}>
                  FanMotor
                </Checkbox>
                <Checkbox isReadOnly isChecked={invertor_overhaul}>
                  Invertor
                </Checkbox>
                <Checkbox isReadOnly isChecked={cooler_cleaning}>
                  Cooler
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </Box>
        </Stack>
      </Box>
    </Link>
  );
}
