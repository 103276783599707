import { useNavigate, useParams } from "react-router-dom";
import ProtectedPage from "../ProtectedPage";
import { Provider, useDispatch } from "react-redux";
import SalesCompanyAnalysisRegStore, {
  updateAllSteps,
} from "../SalesCompanyAnalysisRegStore";
import { Box, Button, Heading, SimpleGrid } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ICompanyAnalsys, IEditCompanyAnalsys, StepData } from "../../types";
import { getCompanyAnalsysDetail } from "../../api";
import { useEffect } from "react";
//
//
function transformToStepData(companyAnalysis: IEditCompanyAnalsys): StepData {
  return {
    step1: {
      id: companyAnalysis.id,
      company_employees: companyAnalysis.company_employees,
      sales_man: companyAnalysis.sales_man,
      sales_manager: companyAnalysis.sales_manager,
      servie_sales_man: companyAnalysis.servie_sales_man,
      service_engineer_man: companyAnalysis.service_engineer_man,
      servie_manager: companyAnalysis.servie_manager,
      developer: companyAnalysis.developer,
      name: companyAnalysis.name,
      ceo: companyAnalysis.ceo,
      address: companyAnalysis.address,
      sales_amount: companyAnalysis.sales_amount,
      credit_rating: companyAnalysis.credit_rating,
      company_size: companyAnalysis.company_size,
      working_hours: companyAnalysis.working_hours,
      working_days: companyAnalysis.working_days,
      tel: companyAnalysis.tel,
      tax_id: companyAnalysis.tax_id,
    },
    step2: {
      sector: companyAnalysis.sector,
      main_products: companyAnalysis.main_products,
      used_air: companyAnalysis.used_air,
      total_annual_power_cost: companyAnalysis.total_annual_power_cost,
      energy_size: companyAnalysis.energy_size,
      kw_cost: companyAnalysis.kw_cost,
      kepco_number: companyAnalysis.kepco_number,
      kepco_power_planer_id: companyAnalysis.kepco_power_planer_id,
      kepco_power_planer_pass: companyAnalysis.kepco_power_planer_pass,
    },
    step3: {
      group_company: companyAnalysis.group_company,
      factory: companyAnalysis.factory,
      major_sales: companyAnalysis.major_sales,
      major_purchases: companyAnalysis.major_purchases,
      factories: companyAnalysis.factories,
      group_companies: companyAnalysis.group_companies,
      sales_to: companyAnalysis.sales_to,
      purchases_from: companyAnalysis.purchases_from,
    },
    step4: {
      customer_employees: companyAnalysis.customer_employees,
    },
    step5: {
      applicable_item: companyAnalysis.applicable_item,
    },
    step6: {
      proposed_grant: companyAnalysis.proposed_grant,
    },
    step7: {
      compressors_companys: companyAnalysis.compressors_companys,
    },
    step8: {
      air_tanks: companyAnalysis.air_tanks,
      refrigerated_dryers: companyAnalysis.refrigerated_dryers,
      desiccant_dryers: companyAnalysis.desiccant_dryers,
      after_cooler: companyAnalysis.after_cooler,
      oil_water_separator: companyAnalysis.oil_water_separator,
      auto_trap: companyAnalysis.auto_trap,
      representative_opinion: companyAnalysis.representative_opinion,
    },
    step9: {
      current_service_provider: companyAnalysis.current_service_provider,
      is_genuine: companyAnalysis.is_genuine,
      exchange_filters: companyAnalysis.exchange_filters,
      exchange_oils: companyAnalysis.exchange_oils,
    },
    step10: {
      hot_water: companyAnalysis.hot_water,
      heating: companyAnalysis.heating,
      process_use: companyAnalysis.process_use,
      use_electricity: companyAnalysis.use_electricity,
      use_lpg: companyAnalysis.use_lpg,
      use_oil: companyAnalysis.use_oil,
      other_load_machines: companyAnalysis.other_load_machines,
    },
    step11: {
      environments: companyAnalysis.environments,
    },
    step12: {
      price_sensitivity: companyAnalysis.price_sensitivity,
      genuine_preference: companyAnalysis.genuine_preference,
      importance_technical: companyAnalysis.importance_technical,
      importance_facility_management:
        companyAnalysis.importance_facility_management,
      relationship_preferences: companyAnalysis.relationship_preferences,
      importance_relationship: companyAnalysis.importance_relationship,
    },
    step13: {
      direct_export: companyAnalysis.direct_export,
      indirect_export: companyAnalysis.indirect_export,
      export_country: companyAnalysis.export_country,
      export_customer: companyAnalysis.export_customer,
      large_company_primary_vendor:
        companyAnalysis.large_company_primary_vendor,
      large_company_secondary_vendor:
        companyAnalysis.large_company_secondary_vendor,
      large_company_name: companyAnalysis.large_company_name,
      large_company_annual_sales_amount:
        companyAnalysis.large_company_annual_sales_amount,
      required_sustainability_report:
        companyAnalysis.required_sustainability_report,
      required_iso50001_certification:
        companyAnalysis.required_iso50001_certification,
      required_company_name: companyAnalysis.required_company_name,
      company_analysis_date: companyAnalysis.company_analysis_date,
    },
    step14: {
      facility: companyAnalysis.facility,
    },
    step15: {
      energy: companyAnalysis.energy,
    },
  };
}
//
//
export default function CompanyAnalysisEditAllSteps() {
  const { companyPk } = useParams();
  const { isLoading, data, refetch } = useQuery<IEditCompanyAnalsys>(
    [`company`, companyPk],
    getCompanyAnalsysDetail
  );
  // console.log(data);

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      const stepData = transformToStepData(data);
      dispatch(updateAllSteps(stepData));
    }
  });

  const steps = [
    { step: 1, path: "step1", name: "기본정보", colorScheme: "red" },
    { step: 2, path: "step2", name: "상세정보", colorScheme: "red" },
    { step: 3, path: "step3", name: "관계회사", colorScheme: "orange" },
    { step: 4, path: "step4", name: "Key Mans", colorScheme: "orange" },
    { step: 5, path: "step5", name: "적용Item", colorScheme: "yellow" },
    { step: 6, path: "step6", name: "제안사업", colorScheme: "yellow" },
    { step: 7, path: "step7", name: "Compressor등록", colorScheme: "teal" },
    { step: 8, path: "step8", name: "Accessory기록", colorScheme: "teal" },
    { step: 9, path: "step9", name: "유지보수정보", colorScheme: "green" },
    { step: 10, path: "step10", name: "기타정보", colorScheme: "green" },
    { step: 11, path: "step11", name: "룸환경", colorScheme: "blue" },
    { step: 12, path: "step12", name: "업체평가", colorScheme: "blue" },
    { step: 13, path: "step13", name: "ESG조사", colorScheme: "blue" },
    { step: 14, path: "step14", name: "부하기기조사", colorScheme: "blue" },
    { step: 15, path: "step15", name: "에너지정보조사", colorScheme: "blue" },
  ];
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <ProtectedPage>
      <Box p={10} bgGradient="linear(to-r, #2A2B6E, #085C94)" h={"100vh"}>
        <Heading color={"white"}>관리업체 분석표 단계별 수정하기</Heading>
        <Box py={3} />
        <Heading color={"whiteAlpha.600"}>{data?.name}</Heading>
        <Box py={3} />
        <SimpleGrid
          spacing={5}
          templateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
        >
          {steps.map(({ step, path, name, colorScheme }) => (
            <Button
              key={step}
              onClick={() => handleNavigate(path)}
              colorScheme={colorScheme}
              shadow={"dark-lg"}
            >
              #{step}. {name}
            </Button>
          ))}
        </SimpleGrid>
        <Box py={5} />
        <Button
          fontSize={"2xl"}
          w={"full"}
          colorScheme="blackAlpha"
          shadow={"2xl"}
        >
          S A V E
        </Button>
      </Box>
      <Box p={5} w={"full"} bgGradient="linear(to-r, #2A2B6E, #085C94)" />
    </ProtectedPage>
  );
}
