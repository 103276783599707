import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, updateStep13 } from "../SalesCompanyAnalysisRegStore";
import { useForm } from "react-hook-form";
import { ICompanyAnalsys, StepData } from "../../types";
import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";

export default function CompanyAnalysisRegStep13() {
  const { companyPk } = useParams();
  const step13Data = useSelector((state: RootState) => state.step13);
  const { control, handleSubmit, register, watch } = useForm<ICompanyAnalsys>({
    defaultValues: step13Data,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step13"]) => {
    dispatch(updateStep13(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step12`); // 다음 페이지로 이동
    // navigate(-1); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step14`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #13 ESG 관계 여부 조사
        </Button>
        <Box py={3} />

        <FormControl my={5}>
          <InputGroup>
            <HStack
              w={"100%"}
              p={3}
              border={"1px"}
              borderColor={"white"}
              borderRadius={"md"}
            >
              <input type="checkbox" {...register("direct_export")} />
              <Text color={"white"} marginRight={3}>
                직접수출
              </Text>
              <input type="checkbox" {...register("indirect_export")} />
              <Text color={"white"} marginRight={3}>
                간접수출
              </Text>
            </HStack>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="수출국가" />
            <Input
              color={"white"}
              type="text"
              {...register("export_country")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="수출업체" />
            <Input
              color={"white"}
              type="text"
              {...register("export_customer")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <HStack
              w={"100%"}
              p={3}
              border={"1px"}
              borderColor={"white"}
              borderRadius={"md"}
            >
              <input
                type="checkbox"
                {...register("large_company_primary_vendor")}
              />
              <Text color={"white"} marginRight={3}>
                대기업1차벤더
              </Text>
              <input
                type="checkbox"
                {...register("large_company_secondary_vendor")}
              />
              <Text color={"white"} marginRight={3}>
                대기업2차벤더
              </Text>
            </HStack>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="대기업명" />
            <Input
              color={"white"}
              type="text"
              {...register("large_company_name")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="대기업 년간매출" />
            <Input
              color={"white"}
              type="number"
              {...register("large_company_annual_sales_amount", {
                // required: true,
              })}
            />
            <InputRightAddon children="원" />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <HStack
              w={"100%"}
              p={3}
              border={"1px"}
              borderColor={"white"}
              borderRadius={"md"}
            >
              <input
                type="checkbox"
                {...register("required_sustainability_report")}
              />
              <Text color={"white"} marginRight={3}>
                지속가능경영보고서요구
              </Text>
              <input
                type="checkbox"
                {...register("required_iso50001_certification")}
              />
              <Text color={"white"} marginRight={3}>
                ISO50001요구
              </Text>
            </HStack>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="요구업체명" />
            <Input
              color={"white"}
              type="text"
              {...register("required_company_name")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="작성일자" />
            <Input
              color={"white"}
              type="date"
              {...register("company_analysis_date")}
            />
          </InputGroup>
        </FormControl>

        <Box py={3} />
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
