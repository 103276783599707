import { Button, Container, Text, VStack, useToast } from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  IUpdateInspectionPrintUrlVariables,
  updateInspectionPrintUrl,
  sendEmail,
} from "../../api";
import { v4 as uuidv4 } from "uuid";
import { TfiEmail } from "react-icons/tfi";
import { useEffect } from "react";

export default function SubmitInspectionReport() {
  const { register, handleSubmit, watch, setValue } =
    useForm<IUpdateInspectionPrintUrlVariables>();
  const { inspectionPk } = useParams();
  const navigate = useNavigate();
  // navigate(-1); // 이전 페이지로 돌아가는 navigate 함수 호출

  const createPrintURL = () => {
    const printReportUUID = uuidv4();
    setValue("uuid", printReportUUID);
  };

  const printurltoast = useToast();

  const sendEmailmutation = useMutation(sendEmail, {
    onSuccess: () => {
      printurltoast({
        status: "success",
        title: "Email sent successfully",
        position: "bottom-right",
      });
      navigate(-1);
    },
    onError: (error: any) => {
      printurltoast({
        status: "error",
        title: "Error",
        description: error.response.data.detail,
        position: "bottom",
      });
    },
  });

  const putPrintUrlmutation = useMutation(
    (variables: IUpdateInspectionPrintUrlVariables) =>
      updateInspectionPrintUrl(variables),
    {
      onSuccess: ({ result }: any) => {
        if (inspectionPk) {
          sendEmailmutation.mutate({
            inspectionPk,
          });
        }
      },
    }
  );

  const onSubmit = () => {
    if (inspectionPk) {
      createPrintURL();
      putPrintUrlmutation.mutate({
        uuid: watch("uuid"),
        inspectionPk,
      });
    }
  };
  console.log(watch());

  useEffect(() => {
    // 컴포넌트가 마운트되면 Send Email 버튼 자동 클릭
    const sendEmailButton = document.getElementById("sendEmailButton");
    if (sendEmailButton) {
      sendEmailButton.click();
    }
  }, []);

  return (
    <ProtectedPage>
      <Container>
        <VStack as="form" onSubmit={handleSubmit(onSubmit)}>
          <Text hidden {...register("inspectionPk")}>
            {inspectionPk}
          </Text>
          <Button
            isLoading={
              putPrintUrlmutation.isLoading || sendEmailmutation.isLoading
            }
            id="sendEmailButton"
            leftIcon={<TfiEmail />}
            colorScheme="linkedin"
            variant="solid"
            shadow={"md"}
            type="submit"
          >
            Send Email
          </Button>
        </VStack>
      </Container>
    </ProtectedPage>
  );
}
