import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useQuery } from "@tanstack/react-query";
import { ICompanyAnalsys, IGItem, StepData } from "../../types";
import { getGItems } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep5 } from "../SalesCompanyAnalysisRegStore";

export default function CompanyAnalysisRegStep5() {
  const { companyPk } = useParams();
  const { data: gItemData } = useQuery<IGItem[]>([`gItem`], getGItems);
  const step5Data = useSelector((state: RootState) => state.step5);
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm<ICompanyAnalsys>({
    defaultValues: step5Data,
  });
  const dispatch = useDispatch();

  const onSubmit = (data: StepData["step5"]) => {
    dispatch(updateStep5(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step4`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step6`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      {/* //
        .####.########.########.##.....##
        ..##.....##....##.......###...###
        ..##.....##....##.......####.####
        ..##.....##....######...##.###.##
        ..##.....##....##.......##.....##
        ..##.....##....##.......##.....##
        .####....##....########.##.....##
        // */}
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #1 기업 기본 정보 등록
        </Button>
        <Container justifyContent={"center"} alignItems={"center"}>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <AccordionButton boxShadow="dark-lg" backgroundColor={"red.500"}>
                <Box as="b" flex="1" textAlign="center" color={"white"}>
                  <Text>적용 Item 선택</Text>
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <Grid templateColumns={"repeat(3, 1fr)"} gap={3}>
                  {gItemData?.map((gItem) => (
                    <GridItem key={gItem.id}>
                      <HStack>
                        <input
                          type="checkbox"
                          value={gItem.id}
                          {...register("applicable_item")}
                        />
                        <Tooltip hasArrow label={gItem.item_description}>
                          <Text color={"white"}>{gItem.grant_item_name}</Text>
                        </Tooltip>
                      </HStack>
                    </GridItem>
                  ))}
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
