import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep10 } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep10() {
  const { companyPk } = useParams();
  const step10Data = useSelector((state: RootState) => state.step10);
  const { control, handleSubmit, register } = useForm<ICompanyAnalsys>({
    defaultValues: step10Data,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step10"]) => {
    dispatch(updateStep10(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step9`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step11`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          variant={"solid"}
          shadow={"dark-lg"}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
        >
          <Text color={"white"} fontSize={"xl"} fontWeight={"bold"}>
            #3-3. 기타 정보
          </Text>
        </Button>
        <FormControl my={5}>
          <InputGroup>
            <VStack w={"100%"}>
              <InputLeftAddon
                borderRightRadius={"md"}
                children="보일러 설비현황"
              />
              <HStack
                w={"100%"}
                p={3}
                border={"1px"}
                borderColor={"white"}
                borderRadius={"md"}
              >
                <input type="checkbox" {...register("hot_water")} />
                <Text color={"white"} marginRight={3}>
                  온수
                </Text>
                <input type="checkbox" {...register("heating")} />
                <Text color={"white"} marginRight={3}>
                  난방
                </Text>
                <input type="checkbox" {...register("process_use")} />
                <Text color={"white"} marginRight={3}>
                  공정용
                </Text>
              </HStack>
            </VStack>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <VStack w={"100%"}>
              <InputLeftAddon borderRightRadius={"md"} children="사용 에너지" />
              <HStack
                w={"100%"}
                p={3}
                border={"1px"}
                borderColor={"white"}
                borderRadius={"md"}
              >
                <input type="checkbox" {...register("use_electricity")} />
                <Text color={"white"} marginRight={3}>
                  전기
                </Text>
                <input type="checkbox" {...register("use_lpg")} />
                <Text color={"white"} marginRight={3}>
                  LPG
                </Text>
                <input type="checkbox" {...register("use_oil")} />
                <Text color={"white"} marginRight={3}>
                  석유
                </Text>
              </HStack>
            </VStack>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="기타 부하기기" />
            <Input
              color={"white"}
              type="text"
              placeholder="예시)집진기"
              {...register("other_load_machines")}
            />
          </InputGroup>
        </FormControl>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
