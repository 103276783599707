import {
  AspectRatio,
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Grid,
  HStack,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import SvgAirfilter from "../../assets/Icon/Airfilter";
import SvgScrew from "../../assets/Icon/Screw";
import SvgMotor from "../../assets/Icon/Motor";
import SvgFan from "../../assets/Icon/Fan";
import SvgInvertor from "../../assets/Icon/Invertor";
import SvgCooler from "../../assets/Icon/Cooler";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { FaCrown, FaKey } from "react-icons/fa";
import { Divider } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import InspectionList from "./InspectionList";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCompressor, getCompressorInspections } from "../../api";
import { ICompressorDetail, ICompressorInspections } from "../../types";

export default function Compdetail() {
  const { compressorPk } = useParams();
  const { isLoading, data } = useQuery<ICompressorDetail>(
    [`compressors`, compressorPk],
    getCompressor
  );
  const navigate = useNavigate();
  const onInspectionUploadClick = () => {
    navigate(`/compressors/${compressorPk}/inspectionupload`);
  };
  const { data: inspectionData, isLoading: isInspectionLoading } = useQuery<
    ICompressorInspections[]
  >([`compressors`, compressorPk, `inspections`], getCompressorInspections);
  const boxBgColor = useColorModeValue("gray.100", "whiteAlpha.200");
  const fontSize = useBreakpointValue({
    sm: "xs",
    md: "smaller",
    lg: "sm",
  });
  const fontSizeForBig = useBreakpointValue({
    sm: "md",
    md: "lg",
    lg: "2xl",
  });
  const getCircularProgressProps = (value: number | null) => {
    if (value === null) {
      return {
        value: -1,
        color: "gray.500",
        isIndeterminate: false,
      };
    }

    if (value >= 100) {
      return {
        value: value,
        color: "red.500",
        isIndeterminate: true,
      };
    }

    if (value >= 75) {
      return {
        value: value,
        color: "pink.500",
        isIndeterminate: false,
      };
    }

    if (value >= 50) {
      return {
        value: value,
        color: "orange.500",
        isIndeterminate: false,
      };
    }

    if (value >= 25) {
      return {
        value: value,
        color: "blue.500",
        isIndeterminate: false,
      };
    }

    return {
      value: value,
      color: "green.500",
      isIndeterminate: false,
    };
  };

  return (
    <Box bgGradient="linear(to-r, blue.500, green.500)">
      <Container maxW={1200}>
        <Box p={3}>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton
                  rounded={"md"}
                  bgGradient="linear(to-r,  blue.700, blue.500)"
                >
                  <Box as="span" flex="1" textAlign="center">
                    <Text
                      bgClip="text"
                      fontSize={"lg"}
                      fontWeight="extrabold"
                      color={"white"}
                    >
                      고 객 정 보
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack
                  direction={"row"}
                  align={"center"}
                  wrap={"wrap"}
                  px={3}
                  justify={"space-between"}
                >
                  <Image
                    boxSize={"50px"}
                    h={"100%"}
                    objectFit={"cover"}
                    src={data?.company.customer_ci_photo?.file}
                  />
                  <Heading fontSize={fontSizeForBig}>
                    {data?.company.name}
                  </Heading>
                  <Text fontSize={fontSizeForBig}>{data?.company.address}</Text>
                  <Text>Tel:{data?.company.tel}</Text>
                  <Text>Fax:{data?.company.fax}</Text>
                  <Stack
                    direction={"row"}
                    align={"center"}
                    wrap={"wrap"}
                    px={3}
                    justify={"space-between"}
                  >
                    <AvatarGroup size="md" max={2}>
                      {data?.customer_compressor_employees.map(
                        (customer_compressor_employees) => (
                          <Box position={"relative"}>
                            <Avatar
                              name={customer_compressor_employees.name}
                              src={customer_compressor_employees.avatar}
                            />
                            <Box position={"absolute"} bottom={-1} right={-3}>
                              <Badge
                                borderRadius="full"
                                variant="subtle"
                                colorScheme="blue"
                              >
                                {customer_compressor_employees.position_title}
                              </Badge>
                            </Box>
                          </Box>
                        )
                      )}
                    </AvatarGroup>
                  </Stack>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton
                  rounded={"md"}
                  bgGradient="linear(to-r,  blue.700, blue.500)"
                >
                  <Box as="span" flex="1" textAlign="center">
                    <Text
                      bgClip="text"
                      fontSize={"lg"}
                      fontWeight="extrabold"
                      color={"white"}
                    >
                      장 비 정 보
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack
                  direction={"row"}
                  align={"center"}
                  wrap={"wrap"}
                  px={3}
                  justify={"space-between"}
                >
                  <Box position={"relative"}>
                    <Image
                      borderRadius="2xl"
                      boxSize="100px"
                      src={data?.compressor_photo[0]?.file}
                    />
                  </Box>
                  <Heading fontSize={fontSizeForBig}>
                    {data?.model_name?.part_name}
                  </Heading>
                  <Text fontSize={fontSizeForBig}>{data?.nick_name}</Text>
                  <Text fontSize={fontSizeForBig}>{data?.serial_number}</Text>
                  <Text>{data?.start_date}</Text>
                  <Stack
                    direction="row"
                    spacing={4}
                    justify={"right"}
                    align={"center"}
                    wrap={"wrap"}
                    px={3}
                  >
                    <AvatarGroup size="md" max={2}>
                      {data?.company_compressor_employees.map(
                        (company_compressor_employees) => (
                          <Box position={"relative"}>
                            <Avatar
                              name={company_compressor_employees.name}
                              src={company_compressor_employees.avatar}
                            />
                            <Box position={"absolute"} bottom={-1} right={-3}>
                              <Badge
                                borderRadius="full"
                                variant="subtle"
                                colorScheme="blue"
                              >
                                {company_compressor_employees.position_title}
                              </Badge>
                            </Box>
                          </Box>
                        )
                      )}
                    </AvatarGroup>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  justify={"space-between"}
                  align={"center"}
                  wrap={"wrap"}
                  px={3}
                >
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgAirfilter />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_c_part_exchange ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_c_part_exchange
                          ? `${data?.percentage_c_part_exchange}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgScrew />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_screw_overhaul ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_screw_overhaul
                          ? `${data?.percentage_screw_overhaul}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgMotor />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_main_motor_repair ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_main_motor_repair
                          ? `${data?.percentage_main_motor_repair}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgFan />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_fan_motor_repair ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_fan_motor_repair
                          ? `${data?.percentage_fan_motor_repair}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgInvertor />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_invertor_overhaul ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_invertor_overhaul
                          ? `${data?.percentage_invertor_overhaul}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                    py={1}
                  >
                    <SvgCooler />
                    <Text>=</Text>
                    <CircularProgress
                      {...getCircularProgressProps(
                        data?.percentage_cooler_cleaning ?? null
                      )}
                    >
                      <CircularProgressLabel>
                        {data?.percentage_cooler_cleaning
                          ? `${data?.percentage_cooler_cleaning}%`
                          : "None"}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Stack>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Divider />
          <Button
            mt={3}
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            w="100%"
            onClick={onInspectionUploadClick}
            boxShadow="md"
          >
            <Text
              bgClip="text"
              fontSize={"lg"}
              fontWeight="extrabold"
              color={"white"}
            >
              점검 등록하기
            </Text>
          </Button>
          <Grid gap={1}>
            {isInspectionLoading ? (
              <>
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
                <Skeleton rounded="2xl" h={70} mb={3} />
              </>
            ) : null}
            {inspectionData?.map((inspection) => (
              <InspectionList
                key={inspection.pk}
                pk={inspection.pk}
                insp_dates={inspection.insp_dates}
                run_times={inspection.run_times}
                company_inspection_employees={
                  inspection.company_inspection_employees
                }
                c_part_exchange={inspection.c_part_exchange}
                screw_overhaul={inspection.screw_overhaul}
                main_motor_repair={inspection.main_motor_repair}
                main_motor_grease={inspection.main_motor_grease}
                fan_motor_repair={inspection.fan_motor_repair}
                invertor_overhaul={inspection.invertor_overhaul}
                cooler_cleaning={inspection.cooler_cleaning}
                uuid={inspection.uuid}
              />
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
