import {
  Box,
  Checkbox,
  Input,
  Stack,
  Text,
  VStack,
  Button,
  Container,
  InputGroup,
  InputLeftAddon,
  Textarea,
  Heading,
  FormControl,
  FormLabel,
  InputRightAddon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Select,
  FormHelperText,
  Divider,
  Switch,
  useToast,
  Image,
  GridItem,
  Skeleton,
  Grid,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import ProtectedPage from "../ProtectedPage";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IEditInspectionVariables,
  IUpdateInspectionPrintUrlVariables,
  editInspection,
  getInspectionCheck,
  updateInspectionPrintUrl,
} from "../../api";
import { IInspectionCheckVariables } from "../../types";
import { FormProvider, useForm } from "react-hook-form";
import UploadPhotos from "./UploadPhotos";
import InspectionCompanySign from "./InspectionCompanySign";
import InspectionCustomerSign from "./InspectionCustomerSign";
import SelectCustomerUserCheck from "./SelectCustomerUserCheck";
import SelectSpngUserCheck from "./SelectSpngUserCheck";

export default function InspectionCheck() {
  const { inspectionPk } = useParams();
  const { isLoading, data, refetch } = useQuery<IInspectionCheckVariables>(
    [`inspections`, inspectionPk, `check`],
    getInspectionCheck
  );
  const navigate = useNavigate();

  const methods = useForm<IInspectionCheckVariables>();
  const { handleSubmit, register, watch, setValue } = methods;

  useEffect(() => {
    if (!isLoading && data) {
      setTimeout(() => {
        setValue("insp_dates", data?.insp_dates || "");
        setValue("run_times", data?.run_times || 0);
        setValue(
          "preventive_maintenance",
          data?.preventive_maintenance || false
        );
        setValue("equipment_inspection", data?.equipment_inspection || false);
        setValue("etc", data?.equipment_inspection || false);
        setValue("c_part_exchange", data?.c_part_exchange || false);
        setValue("screw_overhaul", data?.screw_overhaul || false);
        setValue("main_motor_repair", data?.main_motor_repair || false);
        setValue("fan_motor_repair", data?.fan_motor_repair || false);
        setValue("invertor_overhaul", data?.invertor_overhaul || false);
        setValue("cooler_cleaning", data?.cooler_cleaning || false);
        setValue("main_motor_grease", data?.main_motor_grease || false);
        setValue("other_repair", data?.other_repair || "");
        setValue("insp_load_conditon_1", data?.insp_load_conditon_1 || "");
        setValue("insp_load_conditon_2", data?.insp_load_conditon_2 || "");
        setValue("insp_load_conditon_3", data?.insp_load_conditon_3 || "");
        setValue("insp_load_conditon_4", data?.insp_load_conditon_4 || "");
        setValue("insp_load_conditon_5", data?.insp_load_conditon_5 || "");
        setValue("insp_load_conditon_6", data?.insp_load_conditon_6 || "");
        setValue(
          "insp_unload_condition_1",
          data?.insp_unload_condition_1 || ""
        );
        setValue(
          "insp_unload_condition_2",
          data?.insp_unload_condition_2 || ""
        );
        setValue(
          "insp_unload_condition_3",
          data?.insp_unload_condition_3 || ""
        );
        setValue(
          "insp_unload_condition_4",
          data?.insp_unload_condition_4 || ""
        );
        setValue(
          "insp_unload_condition_5",
          data?.insp_unload_condition_5 || ""
        );
        setValue(
          "insp_unload_condition_6",
          data?.insp_unload_condition_6 || ""
        );
        setValue("insp_counter_2", data?.insp_counter_2 || 0);
        setValue("insp_counter_3", data?.insp_counter_3 || "");
        setValue("insp_counter_4", data?.insp_counter_4 || "");
        setValue("insp_counter_5", data?.insp_counter_5 || "");
        setValue("insp_counter_6", data?.insp_counter_6 || "");
        setValue("insp_counter_7", data?.insp_counter_7 || "");
        setValue("insp_inverter_1", data?.insp_inverter_1 || "");
        setValue("insp_inverter_2", data?.insp_inverter_2 || "");
        setValue("insp_inverter_3", data?.insp_inverter_3 || "");
        setValue("insp_inverter_4", data?.insp_inverter_4 || "");
        setValue("insp_inverter_5", data?.insp_inverter_5 || "");
        setValue("insp_inverter_6", data?.insp_inverter_6 || "");
        setValue("insp_main_motor_1", data?.insp_main_motor_1 || "");
        setValue("insp_main_motor_2", data?.insp_main_motor_2 || "");
        setValue("insp_main_motor_3", data?.insp_main_motor_3 || "");
        setValue("insp_main_motor_4", data?.insp_main_motor_4 || "");
        setValue("insp_main_motor_5", data?.insp_main_motor_5 || "");
        setValue("insp_main_motor_6", data?.insp_main_motor_6 || "");
        setValue("insp_fan_motor_1", data?.insp_fan_motor_1 || "");
        setValue("insp_fan_motor_2", data?.insp_fan_motor_2 || "");
        setValue("insp_fan_motor_3", data?.insp_fan_motor_3 || "");
        setValue("insp_fan_motor_4", data?.insp_fan_motor_4 || "");
        setValue("insp_fan_motor_5", data?.insp_fan_motor_5 || "");
        setValue("insp_fan_motor_6", data?.insp_fan_motor_6 || "");
        setValue(
          "engineer_diagnosis_first_page",
          data?.engineer_diagnosis_first_page || ""
        );
        setValue("insp_oil_etc", data?.insp_oil_etc || "");
        setValue("insp_oil_drain", data?.insp_oil_drain || "");
        setValue("insp_oil_filter", data?.insp_oil_filter || "");
        setValue("insp_oil_filter_number", data?.insp_oil_filter_number || "");
        setValue("insp_oil_filter_etc", data?.insp_oil_filter_etc || "");
        setValue("insp_air_filter", data?.insp_air_filter || "");
        setValue("insp_air_filter_number", data?.insp_air_filter_number || "");
        setValue("insp_air_filter_etc", data?.insp_air_filter_etc || "");
        setValue("insp_oil_separator", data?.insp_oil_separator || "");
        setValue(
          "insp_oil_separator_number",
          data?.insp_oil_separator_number || ""
        );
        setValue("insp_oil_separator_etc", data?.insp_oil_separator_etc || "");
        setValue("insp_condensator_traps", data?.insp_condensator_traps || "");
        setValue("insp_grease", data?.insp_grease || "");
        setValue("insp_grease_liter", data?.insp_grease_liter || "");
        setValue("insp_inverter_filter", data?.insp_inverter_filter || "");
        setValue("insp_cooler", data?.insp_cooler || "");
        setValue(
          "engineer_diagnosis_second_page",
          data?.engineer_diagnosis_second_page || ""
        );
        setValue(
          "improvement_requirements_second_page",
          data?.improvement_requirements_second_page || ""
        );
        setValue("compressor", data?.compressor || "");
        setValue(
          "customer_inspection_employees",
          data?.customer_inspection_employees || ""
        );
        setValue(
          "company_inspection_employees",
          data?.company_inspection_employees || ""
        );
        setValue(
          "customer_inspection_sign_employees",
          data?.customer_inspection_sign_employees || ""
        );
        setValue(
          "company_inspection_sign_employees",
          data?.company_inspection_sign_employees || ""
        );
      });
    }
  }, [data, setValue]);

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };
  const handleReload = () => {
    refetch();
  };
  const {
    isOpen: isCustomerSignOpen,
    onClose: onCustomerSignClose,
    onOpen: onCustomerSignOpen,
  } = useDisclosure();
  const {
    isOpen: isCompanySignOpen,
    onClose: onCompanySignClose,
    onOpen: onCompanySignOpen,
  } = useDisclosure();

  const toast = useToast();
  const mutation = useMutation(
    (variables: IEditInspectionVariables) => editInspection(variables),
    {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Report saved",
          position: "bottom-right",
        });
        navigate(-1);
      },
      onError: (error: any) => {
        toast({
          status: "error",
          title: "Error",
          description: error.response.data.detail,
          position: "bottom",
        });
      },
    }
  );

  const onSubmit = (data: IEditInspectionVariables) => {
    mutation.mutate(data);
  };

  // console.log(watch());

  return (
    <ProtectedPage>
      <div>
        <div>
          <FormProvider {...methods}>
            <VStack as="form" onSubmit={handleSubmit(onSubmit)}>
              <Container maxW={900} px={3} paddingBottom="50px">
                {/*==============================================================*/}
                {/*===============공기압축기 서비스 완료 보고서=== =================*/}
                {/*==============================================================*/}
                <Text
                  py={3}
                  bgGradient="linear(to-r, blue.700, blue.500, blue.700)"
                  bgClip="text"
                  fontSize={"2xl"}
                  fontWeight="extrabold"
                  align={"center"}
                >
                  공기압축기 서비스 완료 보고서
                </Text>
                <Divider />
                {/*==============================================================*/}
                {/*==========================담당자 선택==========================*/}
                {/*==============================================================*/}
                <Container>
                  <FormControl paddingBottom={2}>
                    <Stack>
                      <InputGroup overflow={"hidden"} boxShadow="md">
                        <Input
                          hidden
                          type="text"
                          value={data?.id}
                          {...register("id")}
                        />
                        <InputLeftAddon>점검 일자</InputLeftAddon>
                        <Input type="date" {...register("insp_dates")} />
                      </InputGroup>
                      <Box py={0.3} />
                      <Box py={0.3} />
                      <SelectCustomerUserCheck /> {/* 하위폼 1 */}
                      <Box py={0.3} />
                      <SelectSpngUserCheck /> {/* 하위폼 2 */}
                    </Stack>
                  </FormControl>
                </Container>
                <Box py={1} />
                <Divider />
                <Box py={3} />
                <Container>
                  <FormControl paddingBottom={2}>
                    <InputGroup boxShadow="base">
                      <Checkbox
                        defaultChecked
                        size="lg"
                        required
                        value={data?.customer.pk}
                        {...register("customer")}
                      />
                      <Box py={1} />
                      <InputLeftAddon>고객사</InputLeftAddon>
                      <Input isReadOnly value={data?.customer.name}></Input>
                    </InputGroup>
                  </FormControl>
                  <Box py={1} />
                  <FormControl paddingBottom={2}>
                    <InputGroup boxShadow="base">
                      <Checkbox
                        defaultChecked
                        required
                        size="lg"
                        {...register("compressor")}
                      />
                      <InputLeftAddon>모델명</InputLeftAddon>
                      <Input
                        value={data?.compressor.model_name.part_name}
                      ></Input>
                    </InputGroup>
                  </FormControl>
                  <Box py={1} />
                  <FormControl paddingBottom={2}>
                    <InputGroup boxShadow="base">
                      <InputLeftAddon>Serial Number</InputLeftAddon>
                      <Input value={data?.compressor?.serial_number}></Input>
                    </InputGroup>
                  </FormControl>
                </Container>
                <Box py={3} />
                <Divider />
                <Box py={3} />
                {/*==============================================================*/}
                {/*========================서비스 종류============================*/}
                {/*==============================================================*/}
                <Container>
                  <FormControl>
                    <FormLabel fontWeight={"bold"}>서비스 종류</FormLabel>
                    <Stack justifyContent={"space-between"} direction="row">
                      <Checkbox
                        {...register("preventive_maintenance")}
                        colorScheme="red"
                        defaultChecked={data?.preventive_maintenance}
                      >
                        예방 정비
                      </Checkbox>
                      <Checkbox
                        {...register("equipment_inspection")}
                        defaultChecked={data?.equipment_inspection}
                      >
                        장비 점검
                      </Checkbox>
                      <Checkbox
                        {...register("etc")}
                        defaultChecked={data?.etc}
                        colorScheme="green"
                      >
                        기타
                      </Checkbox>
                    </Stack>
                  </FormControl>
                </Container>
                <Box py={3} />
                <Divider />
                {/*==============================================================*/}
                {/*=======================교체 및 수리내역========================*/}
                {/*==============================================================*/}
                <Box py={3} />
                <Container>
                  <FormControl>
                    <FormLabel fontWeight={"bold"}>교체 및 수리 내역</FormLabel>
                    <Stack
                      paddingBottom={5}
                      justifyContent={"space-between"}
                      direction="row"
                    >
                      <Checkbox
                        {...register("c_part_exchange")}
                        colorScheme="green"
                        defaultChecked={data?.c_part_exchange}
                      >
                        Parts
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        {...register("screw_overhaul")}
                        defaultChecked={data?.screw_overhaul}
                      >
                        Overhaul
                      </Checkbox>
                      <Checkbox
                        {...register("main_motor_repair")}
                        colorScheme="red"
                        defaultChecked={data?.main_motor_repair}
                      >
                        Main Motor
                      </Checkbox>
                    </Stack>
                    <Stack
                      paddingBottom={5}
                      justifyContent={"space-between"}
                      direction="row"
                    >
                      <Checkbox
                        {...register("fan_motor_repair")}
                        defaultChecked={data?.fan_motor_repair}
                      >
                        Fan Motor
                      </Checkbox>
                      <Checkbox
                        {...register("invertor_overhaul")}
                        colorScheme="red"
                        defaultChecked={data?.invertor_overhaul}
                      >
                        Inverter
                      </Checkbox>
                      <Checkbox
                        {...register("cooler_cleaning")}
                        defaultChecked={data?.cooler_cleaning}
                      >
                        Cooler
                      </Checkbox>
                    </Stack>
                    <Stack justifyContent={"space-between"} direction="row">
                      <Checkbox
                        {...register("main_motor_grease")}
                        colorScheme="yellow"
                        defaultChecked={data?.main_motor_grease}
                      >
                        Main Motor Grease
                      </Checkbox>
                      <InputGroup>
                        <InputLeftAddon children="기타" />
                        <Input
                          {...register("other_repair")}
                          type="text"
                        ></Input>
                      </InputGroup>
                    </Stack>
                  </FormControl>
                </Container>
                <Box py={3} />
                <Divider />
                <Box py={3} />
                {/*==============================================================*/}
                {/*========================Load Condition========================*/}
                {/*==============================================================*/}
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          borderRadius="md"
                          boxShadow="md"
                          bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text fontSize={"2xl"}>Load Condition</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>Ambient Temp</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_1")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="℃" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Compressor Outlet</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_2")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="℃" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>DP Air filter</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_3")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Oil injection</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_4")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Receiver presssure</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_5")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Compressor Outlet</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_load_conditon_6")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*=======================UnLoad Condition=======================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          border={"2px"}
                          borderColor={"blue.700"}
                          boxShadow="md"
                          borderRadius="md"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text
                              bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                              bgClip="text"
                              fontSize={"2xl"}
                              fontWeight="extrabold"
                            >
                              Unload Condition
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>Ambient Temp</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_1")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="℃" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Compressor Outlet</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_2")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="℃" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>DP Air filter</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_3")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Oil injection</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_4")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Receiver presssure</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_5")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Compressor Outlet</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_unload_condition_6")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="bar" />
                          </InputGroup>
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*===========================Counter============================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion defaultIndex={[0]} allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          borderRadius="md"
                          bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                          boxShadow="md"
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text fontSize={"2xl"}>Counter</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl isRequired py={5}>
                          <FormLabel>Running Hours</FormLabel>
                          <InputGroup>
                            <Input
                              type="number"
                              {...register("run_times", { required: true })}
                              min={0}
                            ></Input>
                            <InputRightAddon children="h" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Loading Hours</FormLabel>
                          <InputGroup>
                            <Input
                              type="number"
                              {...register("insp_counter_2")}
                            ></Input>
                            <InputRightAddon children="h" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Motor Starts</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              {...register("insp_counter_3")}
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Load Relay</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              {...register("insp_counter_4")}
                            ></Input>
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Fan Starts</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              {...register("insp_counter_5")}
                            ></Input>
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Emergency Stops</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_counter_6")}
                              type="text"
                            ></Input>
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>Direct Stops</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_counter_7")}
                              type="text"
                            ></Input>
                          </InputGroup>
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*===========================Inverter===========================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          border={"2px"}
                          borderColor={"blue.700"}
                          borderRadius="md"
                          boxShadow="md"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text
                              bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                              bgClip="text"
                              fontSize={"2xl"}
                              fontWeight="extrabold"
                            >
                              Inverter
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>VSD 1-20% RPM</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_inverter_1")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>VSD 20-40% RPM</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_inverter_2")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>VSD 40-60% RPM</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_inverter_3")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>VSD 60-80% RPM</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_inverter_4")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>VSD 80-100% RPM</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_inverter_5")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="%" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>기 타</FormLabel>
                          <Textarea {...register("insp_inverter_6")} />
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*===========================Main Motor=========================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          borderRadius="md"
                          boxShadow="md"
                          bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text fontSize={"2xl"}>Main Motor</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>R - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_1")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>S - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_2")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>T - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_3")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>R-S Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_4")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>R-T Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_5")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>T-S Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_main_motor_6")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*===========================Fan Motor==========================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          border={"2px"}
                          boxShadow="md"
                          borderColor={"blue.700"}
                          borderRadius="md"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text
                              bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                              bgClip="text"
                              fontSize={"2xl"}
                              fontWeight="extrabold"
                            >
                              Fan Motor
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>R - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_1")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>S - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_2")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>T - Current</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_3")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="AM" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>R-S Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_4")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>R-T Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_5")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>T-S Voltage</FormLabel>
                          <InputGroup>
                            <Input
                              {...register("insp_fan_motor_6")}
                              type="text"
                            ></Input>
                            <InputRightAddon children="Vac" />
                          </InputGroup>
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*===========================ENG 진단==========================*/}
                {/*==============================================================*/}
                <Box py={2} />
                <Container
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingBottom={3}
                >
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          color="white"
                          borderRadius="md"
                          boxShadow="md"
                          bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
                          _hover={{
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                        >
                          <Box
                            as="b"
                            flex="1"
                            textAlign="center"
                            color={"white"}
                          >
                            <Text fontSize={"2xl"}>Remarks</Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        <FormControl py={5}>
                          <FormLabel>Engineer 진단</FormLabel>
                          <Textarea
                            {...register("engineer_diagnosis_first_page")}
                          />
                        </FormControl>
                        <FormControl py={5}>
                          <FormLabel>개선 요구사항</FormLabel>
                          <Textarea
                            {...register("improvement_requirements_first_page")}
                          />
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Container>
                {/*==============================================================*/}
                {/*========================사용 부품내역==========================*/}
                {/*==============================================================*/}
                <Box py={5} />
                <Container>
                  <FormControl display="flex" alignItems="center">
                    <Checkbox
                      size={"lg"}
                      mr={3}
                      // {...register("second_page")}
                      isChecked={isChecked}
                      onChange={handleSwitchChange}
                    />
                    <FormLabel mb="0">부품 교체를 등록하시겠습니까?</FormLabel>
                  </FormControl>
                </Container>
                <Box py={2} />
                {isChecked ? (
                  <Container
                    justifyContent={"center"}
                    alignItems={"center"}
                    paddingBottom={3}
                  >
                    <Accordion defaultIndex={[0]} allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton color="white" borderRadius="md">
                            <Box
                              as="b"
                              flex="1"
                              textAlign="center"
                              color={"white"}
                            >
                              <Text
                                bgGradient="linear(to-l, #7928CA, #FF0080)"
                                bgClip="text"
                                fontWeight="extrabold"
                                fontSize={"2xl"}
                              >
                                사용 부품 내역 (Parts)
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <FormControl py={5}>
                            <FormLabel>오일 (Oil)</FormLabel>
                            <Select
                              {...register("insp_oil_type")}
                              placeholder="Choose a kind"
                            >
                              <option value="rif">RIF</option>
                              <option value="rs_ultra">RS Ultra</option>
                              <option value="rs_xd">RS XD</option>
                            </Select>
                            <InputGroup>
                              <InputLeftAddon children="제조사" />
                              <Input
                                {...register("insp_oil_etc")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <FormHelperText>
                              기타 선택 시 제조사 입력.
                            </FormHelperText>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>오일 드레인</FormLabel>
                            <Select
                              {...register("insp_oil_drain")}
                              placeholder="Choose a kind"
                            >
                              <option value="all">기존 오일 완전 제거</option>
                              <option value="just_drain">드레인만 실시</option>
                              <option value="just_add">보충만 실시</option>
                            </Select>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>오일 필터 (Oil filters)</FormLabel>
                            <Select
                              {...register("insp_oil_filter")}
                              placeholder="Choose a kind"
                            >
                              <option value="atlascopco">Atlas Copco</option>
                              <option value="other">기타</option>
                            </Select>
                            <InputGroup>
                              <InputLeftAddon children="P/N" />
                              <Input
                                {...register("insp_oil_filter_number")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <InputGroup>
                              <InputLeftAddon children="제조사" />
                              <Input
                                {...register("insp_oil_filter_etc")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <FormHelperText>
                              Atlas=P/N, 기타=제조사 입력.
                            </FormHelperText>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>에어 필터 (Air filters)</FormLabel>
                            <Select
                              {...register("insp_air_filter")}
                              placeholder="Choose a kind"
                            >
                              <option value="atlascopco">Atlas Copco</option>
                              <option value="other">기타</option>
                            </Select>
                            <InputGroup>
                              <InputLeftAddon children="P/N" />
                              <Input
                                {...register("insp_air_filter_number")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <InputGroup>
                              <InputLeftAddon children="제조사" />
                              <Input
                                {...register("insp_air_filter_etc")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <FormHelperText>
                              Atlas=P/N, 기타=제조사 입력.
                            </FormHelperText>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>
                              오일 세퍼레이터 (Oil separator)
                            </FormLabel>
                            <Select
                              {...register("insp_oil_separator")}
                              placeholder="Choose a kind"
                            >
                              <option value="atlascopco">Atlas Copco</option>
                              <option value="other">기타</option>
                            </Select>
                            <InputGroup>
                              <InputLeftAddon children="P/N" />
                              <Input
                                {...register("insp_oil_separator_number")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <InputGroup>
                              <InputLeftAddon children="제조사" />
                              <Input
                                {...register("insp_oil_separator_etc")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <FormHelperText>
                              Atlas=P/N, 기타=제조사 입력.
                            </FormHelperText>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>
                              응측수 트랩 (Condensator Traps)
                            </FormLabel>
                            <Select
                              {...register("insp_condensator_traps")}
                              placeholder="Choose a kind"
                            >
                              <option value="check_opreations_status">
                                동작 상태 확인
                              </option>
                              <option value="disassembly_and_cleaning">
                                분해 점검 및 청소
                              </option>
                              <option value="overhaul_and_cleaning">
                                오바홀, 분해 점검 및 청소
                              </option>
                            </Select>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>
                              모터 구리스 주입 (Grease: 2000hrs)
                            </FormLabel>
                            <Select
                              {...register("insp_grease")}
                              placeholder="Choose a kind"
                            >
                              <option value="complete">주입 완료</option>
                              <option value="cleaning">청소</option>
                              <option value="other">NONE</option>
                            </Select>
                            <InputGroup>
                              <InputLeftAddon children="주입량" />
                              <Input
                                {...register("insp_grease_liter")}
                                type="text"
                              ></Input>
                            </InputGroup>
                            <FormHelperText>
                              주입 완료 시 주입량 입력.
                            </FormHelperText>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>인버터 필터 (Inverter Filter)</FormLabel>
                            <Select
                              {...register("insp_inverter_filter")}
                              placeholder="Choose a kind"
                            >
                              <option value="exchange">교환</option>
                              <option value="cleaning">청소</option>
                              <option value="other">NONE</option>
                            </Select>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>쿨러 (Coolers)</FormLabel>
                            <Select
                              {...register("insp_cooler")}
                              placeholder="Choose a kind"
                            >
                              <option value="good">양호</option>
                              <option value="nomal">청소</option>
                              <option value="need_clean">청소 필요</option>
                              <option value="air_clean">에어 청소</option>
                              <option value="disassembly_and_cleaning">
                                분해 청소
                              </option>
                            </Select>
                          </FormControl>
                          <FormControl py={5}>
                            <FormLabel>Engineer 진단</FormLabel>
                            <Textarea
                              {...register("engineer_diagnosis_second_page")}
                            />
                            <FormLabel>개선 요구사항</FormLabel>
                            <Textarea
                              {...register(
                                "improvement_requirements_second_page"
                              )}
                            />
                          </FormControl>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Container>
                ) : null}
                <Button
                  type="submit"
                  w="full"
                  colorScheme="red"
                  isLoading={mutation.isLoading}
                >
                  Save
                </Button>

                {/*==============================================================*/}
                {/*=======================메일 발송 및 저장=======================*/}
                {/*==============================================================*/}
              </Container>
              <Container>
                <Stack>
                  <Grid
                    gap={3}
                    rowGap={90}
                    templateRows={"repeat(3, 1fr)"}
                    templateColumns={"repeat(2, 1fr)"}
                  >
                    {data?.inspection_photo.map((photo: any, index: number) => (
                      <VStack key={photo.pk}>
                        <Heading fontSize={"lg"}>{photo.description}</Heading>
                        <Image
                          objectFit={"cover"}
                          overflow={"hidden"}
                          rounded={"md"}
                          w="100%"
                          h="100%"
                          src={photo.file}
                        />
                      </VStack>
                    ))}
                  </Grid>
                </Stack>
                <Box py={3} />
                <Button
                  position={"absolute"}
                  right={0}
                  left={0}
                  onClick={handleReload}
                >
                  Reload
                </Button>
              </Container>
            </VStack>
          </FormProvider>
        </div>
        <div>
          <UploadPhotos />
        </div>
        <Divider />
        <Box py={3} />
        <Container>
          <Button
            w="100%"
            maxW={485}
            variant="ghost"
            onClick={onCompanySignOpen}
          >
            <Text
              bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              bgClip="text"
              fontSize="5xl"
              fontWeight="extrabold"
            >
              Engineer Sign
            </Text>
          </Button>
        </Container>
        <Box py={3} />
        <Divider />
        <Box py={3} />
        <Container>
          <Button
            w="100%"
            maxW={485}
            variant="ghost"
            onClick={onCustomerSignOpen}
          >
            <Text
              bgGradient="linear(to-l, #7928CA, #FF0080)"
              bgClip="text"
              fontSize="5xl"
              fontWeight="extrabold"
            >
              Customer Sign
            </Text>
          </Button>
        </Container>
        <Box py={10} />
        <InspectionCompanySign
          isOpen={isCompanySignOpen}
          onClose={onCompanySignClose}
        />
        <InspectionCustomerSign
          isOpen={isCustomerSignOpen}
          onClose={onCustomerSignClose}
        />
      </div>
    </ProtectedPage>
  );
}
