import { Box, Button, Divider, HStack, Text } from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { Link } from "react-router-dom";

export default function NewBusinessHome() {
  const abc = 1;

  return (
    <ProtectedPage>
      <Box
        marginTop={-5}
        h={"100vh"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
      >
        <Text
          py={3}
          color={"white"}
          fontSize={"2xl"}
          fontWeight="extrabold"
          align={"center"}
        >
          지원사업 및 아이템 관리 시스템
        </Text>
        <Divider />
        <HStack>
          <Text fontSize={"2xl"} color={"white"}>
            1.
          </Text>
          <Link to={`/newBusiness/newBusinessGrantReg/`}>
            <Button margin={"1rem"} shadow={"dark-lg"}>
              사업등록
            </Button>
          </Link>
          <Text fontSize={"2xl"} color={"white"}>
            : 지원사업의 기본 내용을 등록 하십시오.
          </Text>
        </HStack>
        <Box my={"3rem"} />
        <HStack>
          <Text fontSize={"2xl"} color={"white"}>
            2.
          </Text>
          <Link to={`/newBusiness/newBusinessGrantDetailItemReg/`}>
            <Button margin={"1rem"} shadow={"dark-lg"}>
              세부 아이템등록
            </Button>
          </Link>
          <Text fontSize={"2xl"} color={"white"}>
            : 지원설비에 해당하는 실제 아이템을 등록 하십시오.
          </Text>
        </HStack>
        <Box my={"3rem"} />
        <HStack>
          <Text fontSize={"2xl"} color={"white"}>
            3.
          </Text>
          <Link to={`/newBusiness/newBusinessGrantOrgReg/`}>
            <Button margin={"1rem"} shadow={"dark-lg"}>
              주관기관 등록하기
            </Button>
          </Link>
          <Text fontSize={"2xl"} color={"white"}>
            : 지원사업의 주관기관을 등록 하십시오.
          </Text>
        </HStack>
        <Box my={"3rem"} />
        <HStack>
          <Text fontSize={"2xl"} color={"white"}>
            4.
          </Text>
          <Link to={`/newBusiness/newBusinessGrantList`}>
            <Button margin={"1rem"} shadow={"dark-lg"}>
              등록사업 조회하기
            </Button>
          </Link>
        </HStack>
        <Box my={"3rem"} />
      </Box>
    </ProtectedPage>
  );
}
