import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import NotFound from "./components/routes/NotFound";
import Compdetail from "./components/routes/Compdetail";
import KakaoConfirm from "./components/routes/KakaoConfirm";
import InspectionReport from "./components/routes/InspectionReport";
import InspectionUpload from "./components/routes/InspectionUpload";
import MakeEmail from "./components/routes/MakeEmail";
import SelectCustomerUser from "./components/routes/SelectCustomerUser";
import SelectSpngUser from "./components/routes/SelectSpngUser";
import UploadPhotos from "./components/routes/UploadPhotos";
import InspectionCheck from "./components/routes/InspectionCheck";
import SubmitInspectionReport from "./components/routes/SubmitInspectionReport";
import GridSearchComp from "./components/routes/GridSearchComp";
import CustomerDetail from "./components/routes/CustomerDetail";
import MainHome from "./components/routes/MainHome";
import ServiceDashBoard from "./components/routes/ServiceDashBoard";
import Presentation from "./components/routes/SalesPresentation";
import GrantReg from "./components/routes/NewBusinessGrantReg";
import GrantDetailItemReg from "./components/routes/NewBusinessGrantDetailItemReg";
import OrgReg from "./components/routes/NewBusinessGrantOrgReg";
import GrantList from "./components/routes/NewBusinessGrantList";
import GrantCheck from "./components/routes/NewBusinessGrantCheck";
import ServiceHome from "./components/routes/ServiceHome";
import SalesHome from "./components/routes/SalsesHome";
import NewBusinessHome from "./components/routes/NewBusinessHome";
import DeveloperHome from "./components/routes/developerHome";
import CompanyAnalysisReport from "./components/routes/SalesCompanyAnalysisReport";
import CompanyAnalysisRegRouter from "./components/routes/SalesCompanyAnalysisRegRouter";
import CompanyAnalysisRegStep1 from "./components/routes/SalesCompanyAnalysisRegStep1";
import CompanyAnalysisRegStep2 from "./components/routes/SalesCompanyAnalysisRegStep2";
import CompanyAnalysisRegStep3 from "./components/routes/SalesCompanyAnalysisRegStep3";
import CompanyAnalysisRegStep4 from "./components/routes/SalesCompanyAnalysisRegStep4";
import CompanyAnalysisRegStep5 from "./components/routes/SalesCompanyAnalysisRegStep5";
import CompanyAnalysisRegStep6 from "./components/routes/SalesCompanyAnalysisRegStep6";
import CompanyAnalysisRegStep7 from "./components/routes/SalesCompanyAnalysisRegStep7";
import CompanyAnalysisRegStep8 from "./components/routes/SalesCompanyAnalysisRegStep8";
import CompanyAnalysisRegStep9 from "./components/routes/SalesCompanyAnalysisRegStep9";
import CompanyAnalysisRegStep10 from "./components/routes/SalesCompanyAnalysisRegStep10";
import CompanyAnalysisRegStep11 from "./components/routes/SalesCompanyAnalysisRegStep11";
import CompanyAnalysisRegStep12 from "./components/routes/SalesCompanyAnalysisRegStep12";
import CompanyAnalysisRegStepFinal from "./components/routes/SalesCompanyAnalysisRegStepFinal";
import CompanyAnalysisEditRouter from "./components/routes/SalesCompanyAnalysisEditRouter";
import SalesCustomerSearch from "./components/routes/SalesCustomerSearch";
import SalesCompanyAnalysisList from "./components/routes/SalesCompanyAnalysisList";
import CompanyAnalysisEditAllSteps from "./components/routes/SalesCompanyAnalysisEditAllSteps";
import CompanyAnalysisRegAllSteps from "./components/routes/SalesCompanyAnalysisRegAllSteps";
import SalesCompanyAnalysisEditStepFinal from "./components/routes/SalesCompanyAnalysisEditStepFinal";
import SalesCompanyAnalysisPrint from "./components/routes/SalesCompanyAnalysisPrint";
import CompanyAnalysisRegStep13 from "./components/routes/SalesCompanyAnalysisRegStep13";
import CompanyAnalysisRegStep14 from "./components/routes/SalesCompanyAnalysisRegStep14";
import CompanyAnalysisRegStep15 from "./components/routes/SalesCompanyAnalysisRegStep15";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <MainHome />,
        children: [],
      },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
        ],
      },
      // //
      // .########..########.##.....##.########.##........#######..########..########.########.
      // .##.....##.##.......##.....##.##.......##.......##.....##.##.....##.##.......##.....##
      // .##.....##.##.......##.....##.##.......##.......##.....##.##.....##.##.......##.....##
      // .##.....##.######...##.....##.######...##.......##.....##.########..######...########.
      // .##.....##.##........##...##..##.......##.......##.....##.##........##.......##...##..
      // .##.....##.##.........##.##...##.......##.......##.....##.##........##.......##....##.
      // .########..########....###....########.########..#######..##........########.##.....##
      // //
      {
        path: "developer",
        element: <DeveloperHome />,
      },
      // //
      // ..######.....###....##.......########..######.
      // .##....##...##.##...##.......##.......##....##
      // .##........##...##..##.......##.......##......
      // ..######..##.....##.##.......######....######.
      // .......##.#########.##.......##.............##
      // .##....##.##.....##.##.......##.......##....##
      // ..######..##.....##.########.########..######.
      // //
      {
        path: "sales",
        element: <SalesHome />,
      },
      {
        path: "sales/company-analysis/:companyPk/detail",
        element: <SalesCompanyAnalysisPrint />,
      },
      {
        path: "sales/company-analysis/:companyPk",
        element: <CompanyAnalysisEditRouter />,
        children: [
          {
            path: "",
            element: <CompanyAnalysisEditAllSteps />,
          },
          {
            path: "step1",
            element: <CompanyAnalysisRegStep1 />,
          },
          {
            path: "step2",
            element: <CompanyAnalysisRegStep2 />,
          },
          {
            path: "step3",
            element: <CompanyAnalysisRegStep3 />,
          },
          {
            path: "step4",
            element: <CompanyAnalysisRegStep4 />,
          },
          {
            path: "step5",
            element: <CompanyAnalysisRegStep5 />,
          },
          {
            path: "step6",
            element: <CompanyAnalysisRegStep6 />,
          },
          {
            path: "step7",
            element: <CompanyAnalysisRegStep7 />,
          },
          {
            path: "step8",
            element: <CompanyAnalysisRegStep8 />,
          },
          {
            path: "step9",
            element: <CompanyAnalysisRegStep9 />,
          },
          {
            path: "step10",
            element: <CompanyAnalysisRegStep10 />,
          },
          {
            path: "step11",
            element: <CompanyAnalysisRegStep11 />,
          },
          {
            path: "step12",
            element: <CompanyAnalysisRegStep12 />,
          },
          {
            path: "step13",
            element: <CompanyAnalysisRegStep13 />,
          },
          {
            path: "step14",
            element: <CompanyAnalysisRegStep14 />,
          },
          {
            path: "step15",
            element: <CompanyAnalysisRegStep15 />,
          },
          {
            path: "edit-stepfinal",
            element: <SalesCompanyAnalysisEditStepFinal />,
          },
        ],
      },
      {
        path: "sales/company-analysis-list",
        element: <SalesCompanyAnalysisList />,
      },

      {
        path: "sales/company-analysis-router",
        element: <CompanyAnalysisRegRouter />,
        children: [
          {
            path: "",
            element: <CompanyAnalysisRegAllSteps />,
          },
          {
            path: "step1",
            element: <CompanyAnalysisRegStep1 />,
          },
          {
            path: "step2",
            element: <CompanyAnalysisRegStep2 />,
          },
          {
            path: "step3",
            element: <CompanyAnalysisRegStep3 />,
          },
          {
            path: "step4",
            element: <CompanyAnalysisRegStep4 />,
          },
          {
            path: "step5",
            element: <CompanyAnalysisRegStep5 />,
          },
          {
            path: "step6",
            element: <CompanyAnalysisRegStep6 />,
          },
          {
            path: "step7",
            element: <CompanyAnalysisRegStep7 />,
          },
          {
            path: "step8",
            element: <CompanyAnalysisRegStep8 />,
          },
          {
            path: "step9",
            element: <CompanyAnalysisRegStep9 />,
          },
          {
            path: "step10",
            element: <CompanyAnalysisRegStep10 />,
          },
          {
            path: "step11",
            element: <CompanyAnalysisRegStep11 />,
          },
          {
            path: "step12",
            element: <CompanyAnalysisRegStep12 />,
          },
          {
            path: "step13",
            element: <CompanyAnalysisRegStep13 />,
          },
          {
            path: "step14",
            element: <CompanyAnalysisRegStep14 />,
          },
          {
            path: "step15",
            element: <CompanyAnalysisRegStep15 />,
          },
          {
            path: "stepfinal",
            element: <CompanyAnalysisRegStepFinal />,
          },
        ],
      },

      {
        path: "sales/presentation",
        element: <Presentation />,
      },

      {
        path: "sales/company-analysis-report",
        element: <CompanyAnalysisReport />,
      },
      // {
      //   path: "sales/presentation",
      //   element: <Presentation />,
      // },

      // //
      // .##....##.########.##......##.########..##.....##..######..####.##....##.########..######...######.
      // .###...##.##.......##..##..##.##.....##.##.....##.##....##..##..###...##.##.......##....##.##....##
      // .####..##.##.......##..##..##.##.....##.##.....##.##........##..####..##.##.......##.......##......
      // .##.##.##.######...##..##..##.########..##.....##..######...##..##.##.##.######....######...######.
      // .##..####.##.......##..##..##.##.....##.##.....##.......##..##..##..####.##.............##.......##
      // .##...###.##.......##..##..##.##.....##.##.....##.##....##..##..##...###.##.......##....##.##....##
      // .##....##.########..###..###..########...#######...######..####.##....##.########..######...######.
      // //
      {
        path: "newBusiness",
        element: <NewBusinessHome />,
      },
      {
        path: "newBusiness/newBusinessGrantList/",
        element: <GrantList />,
      },
      {
        path: "newBusiness/newBusinessGrantReg/",
        element: <GrantReg />,
      },
      {
        path: "newBusiness/newBusinessGrantDetailItemReg/",
        element: <GrantDetailItemReg />,
      },
      {
        path: "newBusiness/newBusinessGrantOrgReg/",
        element: <OrgReg />,
      },
      {
        path: "grants/:grantPk",
        element: <GrantCheck />,
      },
      // //
      // ..######..########.##.....##.####..######..########
      // .##....##.##.......##.....##..##..##....##.##......
      // .##.......##.......##.....##..##..##.......##......
      // ..######..######...##.....##..##..##.......######..
      // .......##.##........##...##...##..##.......##......
      // .##....##.##.........##.##....##..##....##.##......
      // ..######..########....###....####..######..########
      // //
      {
        path: "service",
        element: <ServiceHome />,
      },
      {
        path: "compressors/searchcomp",
        element: <GridSearchComp />,
        children: [],
      },
      {
        path: "compressors/:compressorPk",
        element: <Compdetail />,
        children: [],
      },
      {
        path: "inspections/:inspectionPk",
        children: [
          {
            path: "check",
            element: <InspectionCheck />,
          },
          {
            path: "sendemail",
            element: <SubmitInspectionReport />,
          },
          {
            path: "photos",
            element: <UploadPhotos />,
          },
        ],
      },
      {
        path: "inspections/:inspectionPk/:uuid",
        element: <InspectionReport />,
      },
      {
        path: "compressors/:compressorPk/inspectionupload",
        element: <InspectionUpload />,
      },
      {
        path: "compressors/:compressorPk/inspections",
        element: <InspectionUpload />,
      },
      {
        path: "compressors/:compressorPk/customeruser",
        element: <SelectCustomerUser />,
      },
      {
        path: "users/spng",
        element: <SelectSpngUser />,
      },
      {
        path: "users/servicedashboard",
        element: <ServiceDashBoard />,
      },
      {
        path: "makeemail/",
        element: <MakeEmail />,
      },
      {
        path: "customers/searchcustomer",
        element: <SalesCustomerSearch />,
      },
      {
        path: "customers/:customerPk",
        children: [
          {
            path: "",
            element: <CustomerDetail />,
          },
        ],
      },
    ],
  },
]);

export default router;
