import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useQuery } from "@tanstack/react-query";
import { ICompanyList, ICompanyListFilterOptions } from "../../types";
import { getCompanyAnalsysList } from "../../api";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function SalesCompanyAnalysisList() {
  const [filterOptions, setFilterOptions] = useState<ICompanyListFilterOptions>(
    {
      id: "",
      name: "",
      company_size: "",
      energy_size: "",
      kw_cost: "",
      sales_amount: "",
      working_days: "",
      working_hours: "",
      hot_water: "",
      heating: "",
      process_use: "",
      use_electricity: "",
      use_lpg: "",
      use_oil: "",
    }
  );

  const { data } = useQuery<ICompanyList[]>(
    [`company`, filterOptions],
    () => getCompanyAnalsysList(filterOptions),
    { keepPreviousData: true }
  );

  const tapOptions = [
    { value: "", label: "전체" },
    { value: "emission", label: "배출권할당대상" },
    { value: "goal", label: "목표관리제" },
    { value: "high", label: "에너지다소비" },
    { value: "all", label: "일반" },
  ];

  const translateCompanySize = (size: string) => {
    switch (size) {
      case "small":
        return "중소기업";
      case "mid":
        return "중견기업";
      case "major":
        return "대기업";
      default:
        return size;
    }
  };

  const translateEnergySize = (size: string) => {
    switch (size) {
      case "all":
        return "일반";
      case "high":
        return "에너지다소비";
      case "goal":
        return "목표관리제";
      case "emission":
        return "배출권할당대상";
      default:
        return size;
    }
  };

  const handleTabClick = (value: string) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      energy_size: value,
    }));
  };
  const handleFilterChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setFilterOptions((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ProtectedPage>
      <Tabs size={"md"} bgGradient="linear(to-r, #2A2B6E, #085C94)">
        <TabList>
          {tapOptions.map((option) => (
            <Tab
              key={option.value}
              fontSize={"md"}
              color={"white"}
              onClick={() => handleTabClick(option.value)}
            >
              {option.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tapOptions.map((option) => (
            <TabPanel>
              <Button
                color="white"
                fontWeight="bold"
                bgGradient="linear(to-r, teal.500, green.500)"
                _hover={{
                  bgGradient: "linear(to-r, red.500, yellow.500)",
                }}
                boxShadow="dark-lg"
                onClick={onOpen}
                w={"full"}
              >
                세부검색
              </Button>
              <Modal
                isOpen={isOpen}
                onClose={onClose}
                scrollBehavior={"inside"}
              >
                <ModalOverlay
                  bg="none"
                  backdropFilter="auto"
                  backdropInvert="80%"
                  backdropBlur="2px"
                />
                <ModalContent>
                  <ModalHeader>조건 검색</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <InputGroup>
                      <InputLeftAddon>이름검색</InputLeftAddon>
                      <Input
                        name="name"
                        value={filterOptions.name}
                        onChange={handleFilterChange}
                      />
                    </InputGroup>
                    <Box py={2} />
                    <InputGroup>
                      <InputLeftAddon children="기업규모" />
                      <Select
                        name="company_size"
                        color={"tomato"}
                        placeholder="Choose a kind"
                        value={filterOptions.company_size}
                        onChange={handleFilterChange}
                      >
                        <option value="small">중소기업</option>
                        <option value="mid">중견기업</option>
                        <option value="major">대기업</option>
                      </Select>
                    </InputGroup>
                    <Box py={2} />
                    <Box
                      w={"100%"}
                      p={3}
                      border={"1px"}
                      borderColor={"blackAlpha.300"}
                      borderRadius={"md"}
                    >
                      <Text>보일러 설비현황</Text>
                      <Box py={1} />
                      <HStack>
                        <Checkbox
                          name="hot_water"
                          checked={filterOptions.hot_water === "true"}
                          onChange={handleFilterChange}
                        >
                          온수
                        </Checkbox>
                        <Checkbox
                          name="heating"
                          checked={filterOptions.heating === "true"}
                          onChange={handleFilterChange}
                        >
                          난방
                        </Checkbox>
                        <Checkbox
                          name="process_use"
                          checked={filterOptions.process_use === "true"}
                          onChange={handleFilterChange}
                        >
                          공정용
                        </Checkbox>
                      </HStack>
                    </Box>
                    <Box py={2} />
                    <Box
                      w={"100%"}
                      p={3}
                      border={"1px"}
                      borderColor={"blackAlpha.300"}
                      borderRadius={"md"}
                    >
                      <Text>사용에너지</Text>
                      <Box py={1} />
                      <HStack>
                        <Checkbox
                          name="use_electricity"
                          checked={filterOptions.use_electricity === "true"}
                          onChange={handleFilterChange}
                        >
                          전기
                        </Checkbox>
                        <Checkbox
                          name="use_lpg"
                          checked={filterOptions.use_lpg === "true"}
                          onChange={handleFilterChange}
                        >
                          LPG
                        </Checkbox>
                        <Checkbox
                          name="use_oil"
                          checked={filterOptions.use_oil === "true"}
                          onChange={handleFilterChange}
                        >
                          석유
                        </Checkbox>
                      </HStack>
                    </Box>
                    <Box py={2} />
                    <Text fontSize={"sm"} color={"red"}>
                      ※ 아래 항목 입력시 해당 숫자보다 큰 대상을 검색합니다.
                    </Text>
                    <InputGroup py={2}>
                      <InputLeftAddon>kW 단가</InputLeftAddon>
                      <Input />
                    </InputGroup>
                    <InputGroup py={2}>
                      <InputLeftAddon>매출액</InputLeftAddon>
                      <Input />
                      <InputRightAddon>억원</InputRightAddon>
                    </InputGroup>
                    <InputGroup py={2}>
                      <InputLeftAddon>근무일수</InputLeftAddon>
                      <Input />
                    </InputGroup>
                    <InputGroup py={2}>
                      <InputLeftAddon>근무시간</InputLeftAddon>
                      <Input />
                    </InputGroup>

                    <input
                      type="number"
                      name="kw_cost"
                      value={filterOptions.kw_cost}
                      onChange={handleFilterChange}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button w={"full"} colorScheme="red" onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Box py={2} />
              <SimpleGrid
                spacing={3}
                templateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
              >
                {data?.map((company) => (
                  <Card maxW={"md"} backgroundColor={"none"} shadow={"dark-lg"}>
                    <CardHeader pb={-1}>
                      <Text
                        fontSize={"3xl"}
                        fontWeight={"bold"}
                        color={"white"}
                      >
                        {company.id}
                      </Text>
                      <Text
                        fontSize={"3xl"}
                        fontWeight={"bold"}
                        color={"white"}
                      >
                        {company.name}
                      </Text>
                      <Text fontSize={"md"} fontWeight={"bold"} color={"white"}>
                        | {translateCompanySize(company.company_size)}
                      </Text>
                      <Text fontSize={"md"} fontWeight={"bold"} color={"white"}>
                        | {translateEnergySize(company.energy_size)}
                      </Text>
                    </CardHeader>
                    <CardBody h={"fit-content"}>
                      <HStack pb={1}>
                        <Text fontSize={"md"} color={"white"}>
                          | 대표자 {company.ceo}
                        </Text>
                        <Text fontSize={"md"} color={"yellow"}>
                          | 신용 {company.credit_rating}
                        </Text>
                      </HStack>
                      <Text py={1} fontSize={"small"} color={"white"}>
                        | {company.address}
                      </Text>
                      <HStack py={1}>
                        <Text fontSize={"sm"} color={"white"}>
                          | {company.sector}
                        </Text>
                        <Text fontSize={"sm"} color={"white"}>
                          | {company.main_products}
                        </Text>
                        <Text fontSize={"sm"} color={"white"}>
                          | 일{company.working_hours}시간
                        </Text>
                      </HStack>
                      <Box py={1} />
                      <Text fontSize={"sm"} color={"white"}>
                        | 영업담당자:{company.sales_man[0]?.name}
                      </Text>
                      <Text fontSize={"sm"} color={"white"}>
                        | 서비스담당자:{company.servie_sales_man[0]?.name}
                      </Text>
                    </CardBody>
                    <Divider color={"white"} />
                    <CardFooter>
                      <ButtonGroup spacing="2">
                        <Link
                          to={`/sales/company-analysis/${company.id}/detail`}
                        >
                          <Button variant="solid" colorScheme="blue">
                            상세보기
                          </Button>
                        </Link>
                        <Link to={`/sales/company-analysis/${company.id}`}>
                          <Button variant="ghost" colorScheme="red">
                            수정하기
                          </Button>
                        </Link>
                      </ButtonGroup>
                    </CardFooter>
                  </Card>
                ))}
              </SimpleGrid>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ProtectedPage>
  );
}
