import { useState } from "react";
import useUser from "../../lib/useUser";
import { ICustomer } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { getCustomersSearch } from "../../api";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  Container,
  FormControl,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import Parts from "./Parts";
import Customers from "../Customers";

interface SearchValue {
  search: string;
}

export default function SalesCustomerSearch() {
  const { user } = useUser();

  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading, data } = useQuery<ICustomer[]>(
    ["customers/", searchQuery],
    () => getCustomersSearch(searchQuery)
  );
  //
  const handleSearch = (event: any) => {
    event.preventDefault();
    getCustomersSearch(searchQuery);
  };
  //
  const methods = useForm<SearchValue>();
  const { handleSubmit, register, watch } = methods;
  const onSubmit: SubmitHandler<SearchValue> = (data) => {
    const searchQuery = data.search;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <FormControl py={5}>
            <InputGroup>
              <InputLeftAddon children="고객명" />
              <Input
                type="text"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </InputGroup>
          </FormControl>
          <Text fontSize="sm">
            고객명 이외 다른 조회를 원하시면 강석원팀장에게 연락 바랍니다.
          </Text>
        </Container>

        <Grid
          mt={3}
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
            "2xl": "repeat(1, 1fr)",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
            </>
          ) : null}
          {data?.map((customer) => (
            <Customers
              key={customer.pk}
              pk={customer.pk}
              name={customer.name}
              ceo={customer.ceo}
              address={customer.address}
              tax_id={customer.tax_id}
              out_of_business={customer.out_of_business}
              tel={customer.tel}
              fax={customer.fax}
              business={customer.business}
              sector={customer.sector}
              representative_email={customer.representative_email}
              tax_email={customer.tax_email}
              dart_api_corp_code={customer.dart_api_corp_code}
            />
          ))}
        </Grid>
      </form>
    </FormProvider>
  );
}
