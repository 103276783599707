import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useDispatch, useSelector } from "react-redux";
import { updateStep1, RootState } from "../SalesCompanyAnalysisRegStore"; // Redux 액션 import
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ICompanyAnalsys, StepData } from "../../types";
import useUser from "../../lib/useUser";
import { useState } from "react";

export default function CompanyAnalysisRegStep1() {
  const { companyPk } = useParams();
  const { isLoggedIn, user } = useUser();
  const navigate = useNavigate();

  // Redux 스토어에서 step1의 상태를 가져옵니다.
  const step1Data = useSelector((state: RootState) => state.step1);
  //
  //
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICompanyAnalsys>({
    defaultValues: step1Data,
  });

  const dispatch = useDispatch();

  const onSubmit = (data: StepData["step1"]) => {
    if (isLoggedIn && user) {
      data.sales_man = [user.id];
    }
    // Redux 액션 디스패치
    dispatch(updateStep1(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step2`);
  };
  const goRegStep15 = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step15`);
  };

  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          py={3}
          color={"white"}
          fontSize={"3xl"}
          fontWeight="extrabold"
          align={"center"}
        >
          관리업체 분석표 등록
        </Text>
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #1 기업 기본 정보 등록
        </Button>
        {/* <input type="text" value={user?.id} {...register("sales_man")} hidden /> */}

        {/* //
          .##....##..#######..##.....##....###....##......
          .###...##.##.....##.###...###...##.##...##......
          .####..##.##.....##.####.####..##...##..##......
          .##.##.##.##.....##.##.###.##.##.....##.##......
          .##..####.##.....##.##.....##.#########.##......
          .##...###.##.....##.##.....##.##.....##.##......
          .##....##..#######..##.....##.##.....##.########
          // */}
        <Container justifyContent={"center"} alignItems={"center"}>
          <Accordion defaultIndex={[0, 1]} allowMultiple>
            <AccordionItem>
              <AccordionButton
                // borderRadius="md"
                boxShadow="dark-lg"
              >
                <Box as="b" flex="1" textAlign="center" color={"white"}>
                  <Text>기 본 정 보</Text>
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="업체명" />
                    <Input color={"white"} type="text" {...register("name")} />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="대표이사" />
                    <Input color={"white"} type="text" {...register("ceo")} />
                  </InputGroup>
                </FormControl>
                <Text color={"tomato"}>
                  ※ 이름 입력! 대표이사의 세부 내용은 Key Man 입력에서 추가!
                </Text>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="주소" />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("address")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="매출" />
                    <Input
                      color={"white"}
                      type="number"
                      {...register("sales_amount", {
                        // required: true,
                      })}
                    />
                    <InputRightAddon children="억원" />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon
                      backgroundColor={"tomato"}
                      color={"white"}
                      children="신용등급"
                    />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("credit_rating", {
                        // required: true,
                      })}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="기업규모" />
                    <Select
                      color={"tomato"}
                      placeholder="Choose a kind"
                      {...register("company_size", {
                        required: true,
                      })}
                    >
                      <option value="small">중소기업</option>
                      <option value="mid">중견기업</option>
                      <option value="major">대기업</option>
                    </Select>
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="근무 시간" />
                    <Input
                      color={"white"}
                      type="number"
                      {...register("working_hours", {
                        // required: true,
                      })}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="근무 일수" />
                    <Input
                      color={"white"}
                      type="number"
                      {...register("working_days", {
                        // required: true,
                      })}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="전화번호" />
                    <Input color={"white"} type="text" {...register("tel")} />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="사업자등록번호" />
                    <Input
                      color={"white"}
                      type="text"
                      {...register("tax_id", {
                        pattern: /^\d{3}-\d{2}-\d{5}$/,
                      })}
                    />
                  </InputGroup>
                </FormControl>
                <Text color={"tomato"}>
                  ※ 사업자등록번호: 123-45-67890 형식으로 입력하십시오.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>

        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
            <Box p={3} />
            <Button
              colorScheme="orange"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goRegStep15}
            >
              마지막으로 넘어가기!
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
