import { useFieldArray, useForm } from "react-hook-form";
import ProtectedPage from "../ProtectedPage";
import { RootState, updateStep12 } from "../SalesCompanyAnalysisRegStore";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Select,
  VStack,
} from "@chakra-ui/react";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep12() {
  const { companyPk } = useParams();
  const step12Data = useSelector((state: RootState) => state.step12);
  const { control, handleSubmit, register, watch } = useForm<ICompanyAnalsys>({
    defaultValues: step12Data,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step12"]) => {
    dispatch(updateStep12(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step11`); // 다음 페이지로 이동
    // navigate(-1); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step13`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #5 업체 평가조사
        </Button>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="가격 민감도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("price_sensitivity")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="정품 선호도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("genuine_preference")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="기술력 중시도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("importance_technical")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="설비관리 중시도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("importance_facility_management")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="관계 선호도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("relationship_preferences")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={3} />
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="관계 중시도" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              borderLeftRadius={0}
              {...register("importance_relationship")}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
