import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
  IEmailVariables,
  emailPart,
  emailScrew,
  getGridPart,
  getGridScrew,
  getGridSearchComp,
} from "../../api";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Parts from "./Parts";
import { ICompressors } from "../../types";
import useUser from "../../lib/useUser";

interface SearchValue {
  search: string;
}

export default function GridSearchComp() {
  const { user } = useUser();
  //
  // 서치쿼리 변수 선언
  const [searchQuery, setSearchQuery] = useState("");
  //
  //
  // 통신시 서치쿼리 데이터가 계속 get 요청을 보내도록 설정
  const { isLoading, data } = useQuery<ICompressors[]>(
    ["gridsearch", searchQuery],
    () => getGridSearchComp(searchQuery)
  );
  //
  //
  // const { handleSubmit, register, watch } = methods;
  // const onSubmit: SubmitHandler<SearchValue> = (data) => {
  //   const searchQuery = data.search;
  // };
  //
  //
  //
  //
  // 이메일 발송기능 추가
  //
  //
  const toast = useToast();
  //
  const emailpartMutation = useMutation(emailPart, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Email sent successfully",
        position: "bottom-right",
      });
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        description: error.response.data.detail,
        position: "bottom",
      });
    },
  });
  // //
  const emailScrewMutation = useMutation(emailScrew, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Email sent successfully",
        position: "bottom-right",
      });
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        description: error.response.data.detail,
        position: "bottom",
      });
    },
  });
  // //
  // //
  const methods = useForm<IEmailVariables>();
  const { handleSubmit, register, watch } = methods;
  const onSubmitPart = (data: IEmailVariables) =>
    emailpartMutation.mutate(data);
  const onSubmitScrew = (data: IEmailVariables) =>
    emailScrewMutation.mutate(data);
  //
  //
  //
  return (
    <FormProvider {...methods}>
      <form>
        <Container>
          <FormControl py={5}>
            <InputGroup>
              <InputLeftAddon children="고객명" />
              <Input
                type="text"
                value={searchQuery}
                //아래 onChange 를 통해 위 value로 잡혀있는 searchQuery 가 변할때 마다 get요청이 계속됨.
                //즉, 고객검색할때는 useform을 전혀 사용하지 않음..
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </InputGroup>
          </FormControl>
          <Text fontSize="sm">
            고객명 이외 다른 조회를 원하시면 강석원팀장에게 연락 바랍니다.
          </Text>
        </Container>

        <Grid
          mt={3}
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
            "2xl": "repeat(1, 1fr)",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
            </>
          ) : null}
          {data?.map((compressor) => (
            <Parts
              key={compressor.pk}
              pk={compressor.pk}
              imageUrl={compressor.company.customer_ci_photo?.file}
              name={compressor.company.name}
              model_name={compressor.model_name?.part_name || "없음"}
              serial_number={compressor.serial_number}
              nick_name={compressor.nick_name}
              percentage_c_part_exchange={compressor.percentage_c_part_exchange}
              percentage_screw_overhaul={compressor.percentage_screw_overhaul}
              percentage_main_motor_repair={
                compressor.percentage_main_motor_repair
              }
              percentage_fan_motor_repair={
                compressor.percentage_fan_motor_repair
              }
              percentage_invertor_overhaul={
                compressor.percentage_invertor_overhaul
              }
              percentage_cooler_cleaning={compressor.percentage_cooler_cleaning}
              check_email={compressor.check_email}
            />
          ))}
        </Grid>
        {user?.is_staff ? (
          <>
            <Box position="fixed" bottom="10px" left="10px">
              <Button
                type="button" // <-- 수정된 부분
                colorScheme="blue"
                isLoading={emailpartMutation.isLoading}
                onClick={() => methods.handleSubmit(onSubmitPart)()}
              >
                Send Part Email
              </Button>
            </Box>
            <Box position="fixed" bottom="10px" right="10px">
              <Button
                type="button" // <-- 수정된 부분
                colorScheme="red"
                isLoading={emailScrewMutation.isLoading}
                onClick={() => methods.handleSubmit(onSubmitScrew)()}
              >
                Send Screw Overhaul Email
              </Button>
            </Box>
          </>
        ) : null}
      </form>
    </FormProvider>
  );
}
