import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  VStack,
  AvatarGroup,
  Avatar,
  AvatarBadge,
  Button,
  Container,
  InputGroup,
  InputLeftAddon,
  Textarea,
  Image,
  Img,
  Grid,
  GridItem,
  Skeleton,
  Heading,
} from "@chakra-ui/react";
import SvgAtlaslogo from "../../assets/Icon/Atlaslogo";
import { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import SpngLogo from "../Customicons";
import SpngLogoFull from "../spnglogofull";
import { useLocation, useParams } from "react-router-dom";
import { IInspectionCheckVariables } from "../../types";
import { getInspection, getInspectionReport } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import SpngLogoWhite from "../Customicons";

export default function InspectionReport() {
  const { inspectionPk } = useParams();
  const location = useLocation();
  const uuid = location.pathname.split("/")[3];
  const { isLoading, data } = useQuery<IInspectionCheckVariables>(
    [`inspections`, inspectionPk, uuid],
    getInspectionReport
  );
  const componentRef = useRef(null);
  const basicwithe = 290;
  const lowbodersize = 130;

  const FloatingButtonPrint = () => {
    return (
      <Box position="fixed" bottom="10px" left="10px">
        <ReactToPrint
          trigger={() => <Button colorScheme="red">인쇄</Button>}
          content={() => componentRef.current}
        />
      </Box>
    );
  };

  return (
    <div>
      <FloatingButtonPrint />;
      <Container
        justifyContent={"center"}
        maxW={750}
        ref={componentRef}
        m={5}
        paddingTop={7}
      >
        {/*==============================================================*/}
        {/*===============공기압축기 서비스 완료 보고서=== =================*/}
        {/*==============================================================*/}
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          borderBottom={"1px"}
          paddingBottom={1}
          // gap={20}
        >
          <Text
            fontStyle={"italic"}
            color={"blue.700"}
            fontSize={"2xl"}
            fontWeight="extrabold"
            align={"center"}
          >
            공기압축기 서비스 완료 보고서
          </Text>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text hidden>Print URL: {data?.print_url}</Text>
          </Flex>
        </Flex>
        <Flex
          direction={"row"}
          justifyContent="space-between"
          alignItems="flex-start"
          paddingTop={2}
        >
          {/*==============================================================*/}
          {/*==========================Main Motor==========================*/}
          {/*==============================================================*/}
          <Container
            minW={basicwithe + 45}
            maxW={basicwithe + 45}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={0}
            border={"1px"}
          >
            <VStack spacing={0}>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text px={2} fontSize={"sm"}>
                  고객사
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"sm"}>
                    {data?.customer.name}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text px={2} fontSize={"sm"}>
                  주소
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"xs"}>
                    {data?.customer.address}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
              >
                <Text px={2} fontSize={"sm"}>
                  담당자
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"sm"}>
                    {data?.customer_inspection_sign_employees?.name}
                    {data?.customer_inspection_sign_employees?.position_title}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
          {/*==============================================================*/}
          {/*==========================Fan Motor===========================*/}
          {/*==============================================================*/}
          <Box borderBottom={"1px"} borderColor={"gray.300"} py={3} />
          <Box py={3} />
          <Container
            minW={basicwithe + 45}
            maxW={basicwithe + 45}
            justifyContent={"center"}
            alignItems={"center"}
            marginRight={0}
            border={"1px"}
          >
            <VStack spacing={0}>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text px={2} fontSize={"sm"}>
                  모델명
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"sm"}>
                    {data?.compressor.model_name.part_name}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text px={2} fontSize={"sm"}>
                  S / N
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"sm"}>
                    {data?.compressor.serial_number}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe + 45}
                maxW={basicwithe + 45}
                justifyContent={"space-between"}
              >
                <Text px={2} fontSize={"sm"}>
                  서비스 날짜
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 100}
                  maxW={lowbodersize + 100}
                  borderLeft={"1px"}
                >
                  <Text ml={1} fontSize={"sm"}>
                    {data?.insp_dates}
                    {/* {data?.company_inspection_sign_employees?.name}
                    {data?.company_inspection_sign_employees?.position_title} */}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
        </Flex>
        {/*==============================================================*/}
        {/*========================서비스 종류============================*/}
        {/*==============================================================*/}
        <Flex gap={6} py={1}>
          <Text
            paddingBottom={1}
            fontWeight={"bold"}
            fontSize={"sm"}
            align={"center"}
          >
            ◎ 서비스 종류 :
          </Text>
          <Checkbox
            colorScheme="red"
            size={"sm"}
            isReadOnly
            isChecked={data?.preventive_maintenance}
          >
            예방정비
          </Checkbox>
          <Checkbox
            size={"sm"}
            isReadOnly
            isChecked={data?.equipment_inspection}
          >
            장비점검
          </Checkbox>
          <Checkbox
            colorScheme="green"
            size={"sm"}
            isReadOnly
            isChecked={data?.etc}
          >
            기타
          </Checkbox>
        </Flex>
        <Flex
          direction={"row"}
          justifyContent="center"
          alignItems="flex-start"
          flexWrap={"wrap"}
        >
          {/*==============================================================*/}
          {/*========================Load Condition========================*/}
          {/*==============================================================*/}
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
            paddingBottom={2}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Load Condition
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Ambient Temp
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_1}</Text>
                  <Text fontSize={"sm"}>℃</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={3}>
                  Compressor Outlet
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_2}</Text>
                  <Text fontSize={"sm"}>℃</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  DP Air filter
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_3}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Oil injection
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_4}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"xs"} paddingRight={8}>
                  Receiver presssure
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_5}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"xs"} paddingRight={8}>
                  Compressor Outlet
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_load_conditon_6}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
          {/*==============================================================*/}
          {/*=======================UnLoad Condition=======================*/}
          {/*==============================================================*/}
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Unload Condition
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Ambient Temp
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_1}</Text>
                  <Text fontSize={"sm"}>℃</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"xs"} paddingRight={8}>
                  Compressor Outlet
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_2}</Text>
                  <Text fontSize={"sm"}>℃</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  DP Air filter
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_3}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Oil injection
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_4}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"xs"} paddingRight={8}>
                  Receiver presssure
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_5}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"xs"} paddingRight={8}>
                  Compressor Outlet
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_unload_condition_6}</Text>
                  <Text fontSize={"sm"}>bar</Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
        </Flex>
        <Flex direction={"row"} justifyContent="center" alignItems="flex-start">
          {/*==============================================================*/}
          {/*===========================Counter============================*/}
          {/*==============================================================*/}
          <Box borderBottom={"1px"} borderColor={"gray.300"} py={3} />
          <Box py={3} />
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Counter
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Running Hours
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.run_times}</Text>
                  <Text fontSize={"sm"}>h</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Loading Hours
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_2}</Text>
                  <Text fontSize={"sm"}>h</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Motor Starts
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_3}</Text>
                  <Text color={"white"} fontSize={"sm"}>
                    ?
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Load Relay
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_4}</Text>
                  <Text color={"white"} fontSize={"sm"}>
                    ?
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Fan Starts
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_5}</Text>
                  <Text color={"white"} fontSize={"sm"}>
                    ?
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Emergency Stops
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_6}</Text>
                  <Text color={"white"} fontSize={"sm"}>
                    ?
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  Direct Stops
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_counter_7}</Text>
                  <Text color={"white"} fontSize={"sm"}>
                    ?
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
          {/*==============================================================*/}
          {/*===========================Inverter===========================*/}
          {/*==============================================================*/}
          <Box borderBottom={"1px"} borderColor={"gray.300"} py={3} />
          <Box py={3} />
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Inverter
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  VSD 1-20% RPM
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_inverter_1}</Text>
                  <Text fontSize={"sm"}>%</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  VSD 20-40% RPM
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_inverter_2}</Text>
                  <Text fontSize={"sm"}>%</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  VSD 40-60% RPM
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_inverter_3}</Text>
                  <Text fontSize={"sm"}>%</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  VSD 60-80% RPM
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_inverter_4}</Text>
                  <Text fontSize={"sm"}>%</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  VSD 80-100% RPM
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_inverter_5}</Text>
                  <Text fontSize={"sm"}>%</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text minInlineSize={10} fontSize={"sm"} paddingRight={1}>
                  기타:
                </Text>
                <Text fontSize={"sm"} p={0} h="43px" noOfLines={2}>
                  {data?.insp_inverter_6}
                </Text>
              </HStack>
            </VStack>
          </Container>
        </Flex>
        <Flex
          direction={"row"}
          justifyContent="center"
          alignItems="flex-start"
          paddingTop={2}
        >
          {/*==============================================================*/}
          {/*==========================Main Motor==========================*/}
          {/*==============================================================*/}
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Main Motor
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_1}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  S - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_2}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  T - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_3}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R-S Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_4}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R-T Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_5}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  T-S Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_main_motor_6}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
          {/*==============================================================*/}
          {/*==========================Fan Motor===========================*/}
          {/*==============================================================*/}
          <Box borderBottom={"1px"} borderColor={"gray.300"} py={3} />
          <Box py={3} />
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <Box
                display={"flex"}
                minW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
                color="white"
                borderRadius="md"
                boxShadow="md"
                bgGradient="linear(to-t, blue.700, blue.500, blue.700)"
              >
                <Text color={"White"} fontWeight="bold" align={"center"}>
                  Fan Motor
                </Text>
              </Box>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_1}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  S - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_2}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  T - Current
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_3}</Text>
                  <Text fontSize={"sm"}>AM</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R-S Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_4}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  R-T Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_5}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} paddingRight={8}>
                  T-S Voltage
                </Text>
                <HStack
                  justifyContent={"right"}
                  gap={3}
                  maxW={lowbodersize}
                  minW={lowbodersize}
                  borderBottom={"1px"}
                >
                  <Text fontSize={"sm"}>{data?.insp_fan_motor_6}</Text>
                  <Text fontSize={"sm"}>Vac</Text>
                </HStack>
              </HStack>
            </VStack>
          </Container>
        </Flex>

        {/*==============================================================*/}
        {/*===========================ENG 진단==========================*/}
        {/*==============================================================*/}
        <Box paddingTop={1} />
        <Text fontWeight={"bold"} fontSize={"sm"} align={"left"}>
          Remarks:
        </Text>
        <Flex direction={"row"} justifyContent="center" alignItems="flex-start">
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            maxH={basicwithe - 120}
            minH={basicwithe - 120}
            alignItems={"center"}
          >
            <HStack justifyContent={"space-between"} py={1}>
              <Text fontSize={"sm"} paddingRight={8}>
                Engineer 진단
              </Text>
            </HStack>
            <Textarea
              maxH={basicwithe - 160}
              minH={basicwithe - 160}
              minW={basicwithe - 10}
              maxW={basicwithe - 10}
              isReadOnly
              value={data?.engineer_diagnosis_first_page}
              noOfLines={6}
              fontSize={"sm"}
            ></Textarea>
          </Container>
          {/*==============================================================*/}
          {/*========================개선 요구사항==========================*/}
          {/*==============================================================*/}
          <Box />
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            maxH={basicwithe - 120}
            minH={basicwithe - 120}
            alignItems={"center"}
          >
            <HStack justifyContent={"space-between"} py={1}>
              <Text fontSize={"sm"} paddingRight={8}>
                개선 요구사항
              </Text>
            </HStack>
            <Textarea
              maxH={basicwithe - 160}
              minH={basicwithe - 160}
              minW={basicwithe - 10}
              maxW={basicwithe - 10}
              isReadOnly
              value={data?.improvement_requirements_first_page}
              noOfLines={6}
              fontSize={"sm"}
            ></Textarea>
          </Container>
        </Flex>
        <Flex
          direction={"row"}
          justifyContent="center"
          alignItems="flex-start"
          paddingTop={2}
        >
          {/*==============================================================*/}
          {/*==========================Main Motor==========================*/}
          {/*==============================================================*/}
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>고객사</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>{data?.customer.name}</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>Date</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>{data?.insp_dates}</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>Name</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>
                    {data?.customer_inspection_sign_employees?.name}
                    {data?.customer_inspection_sign_employees?.position_title}
                  </Text>
                </HStack>
              </HStack>

              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text borderBottom={"1px"} fontSize={"sm"}>
                  Signature
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Box w={75} h={50}>
                    <Image
                      objectFit="cover"
                      w="100%"
                      h="100%"
                      src={
                        data?.inspections_customer_signs[
                          data?.inspections_customer_signs?.length - 1
                        ]?.file
                      }
                    />
                  </Box>
                </HStack>
              </HStack>
            </VStack>
          </Container>
          {/*==============================================================*/}
          {/*==========================Fan Motor===========================*/}
          {/*==============================================================*/}
          <Box borderBottom={"1px"} borderColor={"gray.300"} py={3} />
          <Box py={3} />
          <Container
            minW={basicwithe}
            maxW={basicwithe}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VStack spacing={0}>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>장비 공급사</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>에스피앤지(주)</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>Date</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>{data?.insp_dates}</Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                borderBottom={"1px"}
              >
                <Text fontSize={"sm"}>Name</Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Text fontSize={"sm"}>
                    {data?.company_inspection_sign_employees?.name}
                    {data?.company_inspection_sign_employees?.position_title}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text borderBottom={"1px"} fontSize={"sm"}>
                  Signature
                </Text>
                <HStack
                  justifyContent={"left"}
                  gap={3}
                  minW={lowbodersize + 70}
                  maxW={lowbodersize + 70}
                >
                  <Box w={75} h={50}>
                    <Image
                      objectFit="cover"
                      w="100%"
                      h="100%"
                      src={
                        data?.inspections_company_signs[
                          data?.inspections_company_signs?.length - 1
                        ]?.file
                      }
                    />
                  </Box>
                </HStack>
              </HStack>
            </VStack>
          </Container>
        </Flex>
        <Flex paddingTop={3} paddingRight={8} justifyContent={"right"}>
          {/* <SpngLogoFull /> */}
          <Box maxW={"22vh"}>
            <Image src="https://imagedelivery.net/LStsWRQowienWhMtIwNrAQ/625e403e-b710-4ac9-affc-433b6296d700/public" />
          </Box>
        </Flex>

        {data?.inspection_photo && data.inspection_photo.length > 0 ? (
          <>
            <Stack>
              <Box py={7} />
              <Grid
                rowGap={90}
                templateRows={"repeat(3, 1fr)"}
                templateColumns={"repeat(2, 1fr)"}
              >
                {data?.inspection_photo?.map((photo: any, index: number) => (
                  <VStack key={photo?.pk}>
                    <Heading fontSize={"lg"}>{photo.description}</Heading>
                    <Box h={250} w={350}>
                      <Image
                        objectFit={"cover"}
                        overflow={"hidden"}
                        rounded={"md"}
                        w="100%"
                        h="100%"
                        src={photo?.file}
                      />
                    </Box>
                  </VStack>
                ))}
              </Grid>
            </Stack>
          </>
        ) : null}
      </Container>
    </div>
  );
}
