import ProtectedPage from "../ProtectedPage";

export default function CompanyAnalysisReport() {
  const abc = 1;

  return (
    <ProtectedPage>
      <div>
        <p>hello</p>
      </div>
    </ProtectedPage>
  );
}
