import { useColorMode } from "@chakra-ui/react";

const SvgCooler = (props) => {
  const { colorMode } = useColorMode();
  const iconColor = colorMode === "light" ? "blakc" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      viewBox="0 0 100 100"
      fill={iconColor}
      {...props}
    >
      <path d="M21.875 43.75h56.25v3.125h-56.25zM21.875 75h56.25v3.125h-56.25zM6.293 25v53.125H18.75V25zm7.77 50h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zM85.938 87.5a1.564 1.564 0 0 1-1.563 1.563H73.437v3.125h12.5c.829 0 1.625-.329 2.211-.915a3.128 3.128 0 0 0 .914-2.21V81.25h-3.125zM7.813 12.5h9.375v3.125H7.813zM10.938 18.75h3.125v3.125h-3.125zM15.625 6.25h-6.25A3.124 3.124 0 0 0 6.25 9.375h12.5c0-.828-.328-1.625-.914-2.21a3.128 3.128 0 0 0-2.211-.915zM21.875 37.5h56.25v3.125h-56.25zM21.875 25h56.25v3.125h-56.25zM81.25 25v53.125h12.5V25zm7.813 50h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zm0-6.25h-3.126v-3.125h3.126zM21.875 56.25h56.25v3.125h-56.25zM21.875 50h56.25v3.125h-56.25zM21.875 62.5h56.25v3.125h-56.25zM21.875 68.75h56.25v3.125h-56.25zM21.875 31.25h56.25v3.125h-56.25z" />
    </svg>
  );
};
export default SvgCooler;
