import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { BsEnvelopePlus } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ITinyUser } from "../../types";
import { getCustomerUser, getInspectionCheck, getSpngUser } from "../../api";
import ProtectedPage from "../ProtectedPage";
import { MdGroupAdd } from "react-icons/md";
import { useFormContext } from "react-hook-form";
import { watch } from "fs";

export default function SelectSpngUserCheck() {
  const { inspectionPk } = useParams();
  const { register, setValue, watch } = useFormContext();
  const { isLoading, data, refetch } = useQuery(
    [`inspections`, inspectionPk, `check`],
    getInspectionCheck
  );

  const { data: spnguser } = useQuery<ITinyUser[]>(["spnguser"], getSpngUser);
  // console.log(spnguser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (data) {
      setValue(
        "company_inspection_employees",
        data?.company_inspection_employees || ""
      );
      setValue(
        "company_inspection_sign_employees",
        data?.company_inspection_sign_employees || ""
      );
    }
  }, [data, setValue]);

  return (
    <ProtectedPage>
      <>
        <Button
          leftIcon={<MdGroupAdd />}
          color="white"
          fontWeight="bold"
          borderRadius="md"
          bgGradient="linear(to-r, teal.500, green.500)"
          _hover={{
            bgGradient: "linear(to-r, red.500, yellow.500)",
          }}
          boxShadow="md"
          onClick={onOpen}
        >
          엔지니어
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          //initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Select Engineer</DrawerHeader>

            <DrawerBody>
              <Box
                borderRadius={"md"}
                bgGradient="linear(to-r, teal.500, green.500)"
              >
                <Container>
                  <Text justifySelf={"center"} color={"white"}>
                    작업 엔지니어 선택
                  </Text>
                </Container>
              </Box>
              <Box py={1} />
              <FormControl>
                <Grid mt={3} rowGap={5}>
                  {spnguser?.map((user) => (
                    <HStack
                      justifyContent={"space-between"}
                      borderBottom={"1px"}
                      borderColor={"gray.200"}
                      pr={3}
                      py={1}
                      key={user.pk}
                    >
                      <Checkbox
                        {...register("company_inspection_employees")}
                        size="lg"
                        ml={3}
                        value={user.pk}
                        // defaultChecked={user.isChecked}
                      ></Checkbox>
                      <AvatarGroup max={2}>
                        <Box position={"relative"}>
                          <Avatar
                            size="md"
                            name={user.name}
                            src={user.avatar}
                          />
                          <Box position={"absolute"} bottom={-1} right={-3}>
                            <Badge
                              borderRadius="full"
                              variant="subtle"
                              colorScheme="blue"
                            >
                              {user.position_title}
                            </Badge>
                          </Box>
                        </Box>
                      </AvatarGroup>
                      <Box>
                        <HStack>
                          <Text as="b"> {user.name}</Text>
                          <Text as="b">{user.position_title}</Text>
                        </HStack>
                        <Text>{user.email}</Text>
                      </Box>
                    </HStack>
                  ))}
                </Grid>
              </FormControl>
              <Box py={10} />
              <Stack spacing="24px">
                <Box>
                  <Box
                    borderRadius={"md"}
                    bgGradient="linear(to-r, teal.500, green.500)"
                  >
                    <Container>
                      <Text justifySelf={"center"} color={"white"}>
                        보고서 서명 담당자
                      </Text>
                    </Container>
                  </Box>
                  <Box py={1} />
                  <Select
                    placeholder="선택하기"
                    {...register("company_inspection_sign_employees")}
                  >
                    {spnguser?.map((user) => (
                      <option key={user.pk} value={user.pk}>
                        {user.name} {user.email}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Stack>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              {/* <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button> */}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    </ProtectedPage>
  );
}
