import { useColorMode } from "@chakra-ui/react";

const SvgFan = (props) => {
  const { colorMode } = useColorMode();
  const iconColor = colorMode === "light" ? "blakc" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5rem"
      height="2.5rem"
      viewBox="0 0 100 100"
      fill={iconColor}
      {...props}
    >
      <path d="M25.023 5.684c-3.477.921-7.086 2.836-10.543 6.043-21.902 20.34 2.977 41.688 24.113 39.89a11.539 11.539 0 0 1 4.305-10.687C36.214 31.336 35.054 17.242 40.136 8c-3.723-2.61-9.227-3.883-15.113-2.317zM67.234 6.602C55.656 9.84 47.226 24.992 48.382 38.594a11.541 11.541 0 0 1 10.684 4.3C68.66 36.211 82.754 35.051 92 40.137c4.144-5.922 4.918-16.348-3.73-25.66-7.247-7.805-14.626-9.668-21.036-7.875zm-7.73 36.887c.039.054.074.113.113.168a4.229 4.229 0 0 0-.113-.168zM7.996 59.863c-4.144 5.922-4.914 16.344 3.734 25.656 20.336 21.902 41.684-2.977 39.888-24.113a11.542 11.542 0 0 1-10.689-4.305c-9.593 6.684-23.688 7.848-32.934 2.762zm32.391-3.52c.035.055.074.114.113.168-.039-.054-.074-.113-.113-.168zM61.406 48.383a11.543 11.543 0 0 1-4.3 10.688c6.683 9.594 7.843 23.688 2.757 32.934 5.922 4.144 16.344 4.914 25.66-3.734 21.898-20.336-2.98-41.684-24.117-39.887z" />
      <path d="M49.91 37.898c-1.57.012-3.168.332-4.703.992-6.137 2.645-8.965 9.766-6.317 15.902 2.645 6.137 9.766 8.965 15.898 6.317 6.137-2.645 8.965-9.766 6.32-15.898-1.984-4.602-6.484-7.344-11.198-7.313zm.012 1.613c4.086-.027 7.988 2.348 9.707 6.336 2.293 5.317-.16 11.488-5.477 13.781-5.316 2.293-11.488-.16-13.78-5.476-2.294-5.317.16-11.488 5.476-13.781a10.396 10.396 0 0 1 4.074-.86z" />
      <path d="M56.664 47.125a7.26 7.26 0 1 1-13.333 5.753 7.26 7.26 0 0 1 13.333-5.753z" />
    </svg>
  );
};
export default SvgFan;
