import {
  Box,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import SvgAirfilter from "../../assets/Icon/Airfilter";
import SvgScrew from "../../assets/Icon/Screw";
import SvgMotor from "../../assets/Icon/Motor";
import SvgFan from "../../assets/Icon/Fan";
import SvgInvertor from "../../assets/Icon/Invertor";
import SvgCooler from "../../assets/Icon/Cooler";
import { Link, redirect } from "react-router-dom";
import { IPartsProps } from "../../types";
import { useFormContext } from "react-hook-form";

export default function Parts({
  pk,
  imageUrl,
  name,
  model_name,
  serial_number,
  nick_name,
  percentage_c_part_exchange,
  percentage_screw_overhaul,
  percentage_main_motor_repair,
  percentage_fan_motor_repair,
  percentage_invertor_overhaul,
  percentage_cooler_cleaning,
  check_email,
}: IPartsProps) {
  const { register } = useFormContext();

  const colorMode = useColorModeValue("white", "black");

  const fontSizeForBig = useBreakpointValue({
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  const getCircularProgressProps = (value: number | null) => {
    if (value === null) {
      return {
        value: -1,
        color: "gray.500",
        isIndeterminate: false,
      };
    }

    if (value >= 100) {
      return {
        value: value,
        color: "red.500",
        isIndeterminate: true,
      };
    }

    if (value >= 75) {
      return {
        value: value,
        color: "pink.500",
        isIndeterminate: false,
      };
    }

    if (value >= 50) {
      return {
        value: value,
        color: "orange.500",
        isIndeterminate: false,
      };
    }

    if (value >= 25) {
      return {
        value: value,
        color: "blue.500",
        isIndeterminate: false,
      };
    }

    return {
      value: value,
      color: "green.500",
      isIndeterminate: false,
    };
  };

  return (
    <div>
      <Stack direction={["column", "row"]} justify={"center"}>
        <Checkbox
          size={"lg"}
          {...register("compressorPk")}
          value={pk}
          defaultChecked
          colorScheme="red"
        />
        <Link to={`/compressors/${pk}`}>
          <Stack
            direction={"row"}
            w={"100%"}
            bgGradient="linear(to-r,  blue.700, blue.500)"
            borderRadius={"lg"}
            boxShadow={"md"}
            // spacing={10}
            justify={"space-between"}
            align={"center"}
            wrap={"wrap"}
            px={3}
          >
            <HStack justify={"space-between"} align={"center"} py={3} px={3}>
              <Image
                boxSize={"50px"}
                h={"100%"}
                objectFit={"cover"}
                src={imageUrl}
              />
              <Box px={2}></Box>
              <Heading color={colorMode} fontSize={fontSizeForBig}>
                {name}
              </Heading>
            </HStack>
            <Heading color={"orange.300"} fontSize={fontSizeForBig}>
              {model_name}
            </Heading>
            <Box px={2}></Box>
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"flex-start"}
              paddingRight={20}
              py={3}
            >
              <Text as="b" color={"red.300"} fontSize={fontSizeForBig}>
                {nick_name}
              </Text>
              <Box px={2}></Box>
              <Text as="b" color={"white"} fontSize={fontSizeForBig}>
                {serial_number}
              </Text>
            </Stack>
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgAirfilter />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_c_part_exchange)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_c_part_exchange
                    ? `${percentage_c_part_exchange}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
            <Box px={2} />
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgScrew />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_screw_overhaul)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_screw_overhaul
                    ? `${percentage_screw_overhaul}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
            <Box px={2} />
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgMotor />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_main_motor_repair)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_main_motor_repair
                    ? `${percentage_main_motor_repair}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
            <Box px={2} />
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgFan />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_fan_motor_repair)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_fan_motor_repair
                    ? `${percentage_fan_motor_repair}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
            <Box px={2} />
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgInvertor />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_invertor_overhaul)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_invertor_overhaul
                    ? `${percentage_invertor_overhaul}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
            <Box px={2} />
            <Stack
              direction={"row"}
              justify={"space-between"}
              align={"center"}
              py={1}
            >
              <SvgCooler />
              <Text>=</Text>
              <CircularProgress
                {...getCircularProgressProps(percentage_cooler_cleaning)}
              >
                <CircularProgressLabel color={"white"}>
                  {percentage_cooler_cleaning
                    ? `${percentage_cooler_cleaning}%`
                    : "None"}
                </CircularProgressLabel>
              </CircularProgress>
            </Stack>
          </Stack>
        </Link>
      </Stack>
    </div>
  );
}
