import ProtectedPage from "../ProtectedPage";
import { Provider, useDispatch } from "react-redux";
import SalesCompanyAnalysisRegStore, {
  resetStore,
} from "../SalesCompanyAnalysisRegStore";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CompanyAnalysisRegStep1 from "./SalesCompanyAnalysisRegStep1";
import CompanyAnalysisRegStep2 from "./SalesCompanyAnalysisRegStep2";
import CompanyAnalysisRegStep3 from "./SalesCompanyAnalysisRegStep3";
import CompanyAnalysisRegStep4 from "./SalesCompanyAnalysisRegStep4";
import CompanyAnalysisRegStep5 from "./SalesCompanyAnalysisRegStep5";
import CompanyAnalysisRegStep6 from "./SalesCompanyAnalysisRegStep6";
import CompanyAnalysisRegStep7 from "./SalesCompanyAnalysisRegStep7";
import CompanyAnalysisRegStep8 from "./SalesCompanyAnalysisRegStep8";
import CompanyAnalysisRegStep9 from "./SalesCompanyAnalysisRegStep9";
import CompanyAnalysisRegStep10 from "./SalesCompanyAnalysisRegStep10";
import CompanyAnalysisRegStep11 from "./SalesCompanyAnalysisRegStep11";
import CompanyAnalysisRegStep12 from "./SalesCompanyAnalysisRegStep12";
import CompanyAnalysisRegStep13 from "./SalesCompanyAnalysisRegStep13";
import CompanyAnalysisRegStepFinal from "./SalesCompanyAnalysisRegStepFinal";
import CompanyAnalysisRegAllSteps from "./SalesCompanyAnalysisRegAllSteps";
import CompanyAnalysisRegStep14 from "./SalesCompanyAnalysisRegStep14";
import CompanyAnalysisRegStep15 from "./SalesCompanyAnalysisRegStep15";

export default function CompanyAnalysisRegRouter() {
  return (
    <ProtectedPage>
      <Provider store={SalesCompanyAnalysisRegStore}>
        <Routes>
          <Route path="" element={<CompanyAnalysisRegAllSteps />} />
          <Route path="/step1" element={<CompanyAnalysisRegStep1 />} />
          <Route path="/step2" element={<CompanyAnalysisRegStep2 />} />
          <Route path="/step3" element={<CompanyAnalysisRegStep3 />} />
          <Route path="/step4" element={<CompanyAnalysisRegStep4 />} />
          <Route path="/step5" element={<CompanyAnalysisRegStep5 />} />
          <Route path="/step6" element={<CompanyAnalysisRegStep6 />} />
          <Route path="/step7" element={<CompanyAnalysisRegStep7 />} />
          <Route path="/step8" element={<CompanyAnalysisRegStep8 />} />
          <Route path="/step9" element={<CompanyAnalysisRegStep9 />} />
          <Route path="/step10" element={<CompanyAnalysisRegStep10 />} />
          <Route path="/step11" element={<CompanyAnalysisRegStep11 />} />
          <Route path="/step12" element={<CompanyAnalysisRegStep12 />} />
          <Route path="/step13" element={<CompanyAnalysisRegStep13 />} />
          <Route path="/step14" element={<CompanyAnalysisRegStep14 />} />
          <Route path="/step15" element={<CompanyAnalysisRegStep15 />} />
          <Route path="/stepfinal" element={<CompanyAnalysisRegStepFinal />} />
        </Routes>
      </Provider>
    </ProtectedPage>
  );
}
