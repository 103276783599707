import { Image, useColorMode } from "@chakra-ui/react";
import spnglogo from "../assets/spnglogo.png";
import spnglogoDark from "../assets/spnglogo_dark.png";

const SpngLogoWhite = () => {
  const { colorMode } = useColorMode();
  const icon = colorMode === "light" ? spnglogoDark : spnglogoDark;
  return <Image h={"5"} objectFit="contain" src={icon} alt="logo" />;
};

export default SpngLogoWhite;
