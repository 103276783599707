import { Box, HStack, Text } from "@chakra-ui/react";
import { ICustomer } from "../types";
import { Link } from "react-router-dom";

export default function Customers({
  pk,
  name,
  ceo,
  address,
  tax_id,
  out_of_business,
  tel,
  fax,
  business,
  sector,
  representative_email,
  tax_email,
  dart_api_corp_code,
}: ICustomer) {
  return (
    <div>
      <Link to={`/customers/${pk}`}>
        <Box border={"1px"}>
          <HStack>
            <Text>{pk}</Text>
            <Text>{name}</Text>
            <Text>{ceo}</Text>
            <Text>{address}</Text>
            <Text>{tax_id}</Text>
            <Text>{tel}</Text>
            <Text>{fax}</Text>
            <Text>{business}</Text>
            <Text>{sector}</Text>
          </HStack>
        </Box>
      </Link>
    </div>
  );
}
