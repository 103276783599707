import { useNavigate, useParams } from "react-router-dom";
import ProtectedPage from "../ProtectedPage";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep15 } from "../SalesCompanyAnalysisRegStore";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ICompanyAnalsys, StepData } from "../../types";
import { useRef, useState } from "react";

export default function CompanyAnalysisRegStep15() {
  const { companyPk } = useParams();
  const step15Data = useSelector((state: RootState) => state.step15);
  const { control, handleSubmit, register, watch, setValue, getValues } =
    useForm<ICompanyAnalsys>({
      defaultValues: step15Data,
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "energy", // 폼 필드 배열의 이름
  });

  const calculateValues = (fieldIndex: number, name: string, value: string) => {
    // 값이 숫자가 아니라면 계산을 실행하지 않음
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    if (name.includes("annual_electricity_usage")) {
      setValue(
        `energy[${fieldIndex}].annual_electricity_toe` as any,
        Number((numericValue * 0.229) / 1000).toFixed(3)
      );
      setValue(
        `energy[${fieldIndex}].annual_electricity_tco2` as any,
        Number((numericValue * 0.4747) / 1000).toFixed(3)
      );
    }

    if (name.includes("annual_gas_usage")) {
      setValue(
        `energy[${fieldIndex}].annual_gas_toe` as any,
        Number((numericValue * 10190) / Math.pow(10, 7)).toFixed(3)
      );
      setValue(
        `energy[${fieldIndex}].annual_gas_tco2` as any,
        Number((((numericValue * 38.5 * 15.236) / 1000000) * 44) / 12).toFixed(
          3
        )
      );
    }
  };

  const calculateSummaryCosts = (fieldIndex: number) => {
    // 필요한 값 가져오기
    const electricityCost =
      parseFloat(
        getValues(`energy.${fieldIndex}.total_annual_electricity_cost` as any)
      ) || 0;
    const gasCost =
      parseFloat(
        getValues(`energy.${fieldIndex}.total_annual_gas_cost` as any)
      ) || 0;
    const etcEnergyCost =
      parseFloat(
        getValues(`energy.${fieldIndex}.total_annual_etc_energy_cost` as any)
      ) || 0;
    const electricityToe =
      parseFloat(
        getValues(`energy.${fieldIndex}.annual_electricity_toe` as any)
      ) || 0;
    const gasToe =
      parseFloat(getValues(`energy.${fieldIndex}.annual_gas_toe` as any)) || 0;
    const electricityTco2 =
      parseFloat(
        getValues(`energy.${fieldIndex}.annual_electricity_tco2` as any)
      ) || 0;
    const gasTco2 =
      parseFloat(getValues(`energy.${fieldIndex}.annual_gas_tco2` as any)) || 0;

    // 계산
    const totalCost = electricityCost + gasCost + etcEnergyCost;
    const totalToe = electricityToe + gasToe;
    const totalTco2 = electricityTco2 + gasTco2;

    // 값을 설정
    setValue(
      `energy[${fieldIndex}].total_anuual_all_cost` as any,
      totalCost.toFixed(0)
    );
    setValue(
      `energy[${fieldIndex}].annual_total_toe` as any,
      totalToe.toFixed(3)
    );
    setValue(
      `energy[${fieldIndex}].annual_total_tco2` as any,
      totalTco2.toFixed(3)
    );
  };

  // onBlur 이벤트를 핸들링하는 함수 수정
  const handleBlur = (fieldIndex: number, name: string, value: string) => {
    calculateValues(fieldIndex, name, value); // 기존 계산 로직 실행
    calculateSummaryCosts(fieldIndex); // 새로운 요약 계산 로직 실행
  };

  // const summaryCosts = (fieldIndex: number, name: string, value: string) => {
  //   // 값이 숫자가 아니라면 계산을 실행하지 않음
  //   const costValue = parseFloat(value);
  //   if (isNaN(costValue)) return;

  //   if (name.includes("total_annual_electricity_cost")) {
  //     setValue(
  //       `energy[${fieldIndex}].total_annual_electricity_cost` as any,

  //     )
  //   }

  // }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(); // 현재 선택된 항목의 인덱스를 저장

  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleDelete = (index: any) => {
    setCurrentIndex(index); // 삭제할 항목의 인덱스를 저장
    onOpen(); // 대화 상자를 엽니다
  };

  const confirmDelete = () => {
    remove(currentIndex); // 실제로 항목을 삭제합니다
    onClose(); // 대화 상자를 닫습니다
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step15"]) => {
    dispatch(updateStep15(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);
  console.log(submitHandler);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step14`); // 다음 페이지로 이동
    // navigate(-1); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/stepfinal`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #14 에너지조사
        </Button>
        <Box py={5} />
        <Button
          colorScheme="green"
          w={"100%"}
          onClick={() =>
            append({
              information_type: "",
              information_year: 0,
              electricity_cost: 0,
              gas_cost: 0,
              etc_energy_cost: 0,
              annual_electricity_usage: 0,
              annual_gas_usage: 0,
              annual_etc_usage: 0,
              total_annual_electricity_cost: 0,
              total_annual_gas_cost: 0,
              total_annual_etc_energy_cost: 0,
              annual_electricity_toe: 0,
              annual_gas_toe: 0,
              annual_electricity_tco2: 0,
              annual_gas_tco2: 0,
              total_anuual_all_cost: 0,
              annual_total_toe: 0,
              annual_total_tco2: 0,
            })
          }
        >
          + 에너지정보 추가
        </Button>
        <Grid
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
        >
          {fields.map((field, index) => (
            <GridItem key={field.id}>
              <Controller
                control={control}
                name={`energy.${index}`}
                render={({ field }) => (
                  <>
                    <Container justifyContent={"center"} alignItems={"center"}>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                          <AccordionButton boxShadow="dark-lg">
                            <Box
                              as="b"
                              flex="1"
                              textAlign="center"
                              color={"white"}
                            >
                              <Text>에너지정보 #{index + 1}</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="정보근거" />
                                <Select
                                  color={"tomato"}
                                  placeholder="Choose a kind"
                                  {...register(
                                    `energy.${index}.information_type` as const
                                  )}
                                >
                                  <option value="report">근거자료</option>
                                  <option value="explanation">설명</option>
                                </Select>
                              </InputGroup>
                            </FormControl>

                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="기준년도" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.information_year` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="전기 단가" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.electricity_cost` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="가스 단가" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.gas_cost` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="기타에너지 단가" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.etc_energy_cost` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <Controller
                              control={control}
                              name={`energy.${index}.annual_electricity_usage`}
                              render={({ field }) => (
                                <FormControl my={5}>
                                  <InputGroup>
                                    <InputLeftAddon children="연간 전기사용량" />
                                    <Input
                                      {...field}
                                      color={"white"}
                                      type="number"
                                      onBlur={(e) => {
                                        field.onBlur(); // 기본 onBlur 핸들러 호출
                                        calculateValues(
                                          index,
                                          field.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormControl>
                              )}
                            />
                            <Controller
                              control={control}
                              name={`energy.${index}.annual_gas_usage`}
                              render={({ field }) => (
                                <FormControl my={5}>
                                  <InputGroup>
                                    <InputLeftAddon children="연간 가스사용량" />
                                    <Input
                                      {...field}
                                      color={"white"}
                                      type="number"
                                      onBlur={(e) => {
                                        field.onBlur(); // 기본 onBlur 핸들러 호출
                                        calculateValues(
                                          index,
                                          field.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormControl>
                              )}
                            />
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="연간 기타사용량" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_etc_usage` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="연간 전력비용" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.total_annual_electricity_cost` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="연간 총 Gas비용" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.total_annual_gas_cost` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="연간 총 기타 에너지비용" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.total_annual_etc_energy_cost` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="연간 에너지비용 합계" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.total_anuual_all_cost` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>

                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="전기toe" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_electricity_toe` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="가스toe" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_gas_toe` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="총toe" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_total_toe` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="전기tCO2" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_electricity_tco2` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="가스tCO2" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_gas_tco2` as const
                                  )}
                                  onBlur={() => calculateSummaryCosts(index)}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="총tCO2" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `energy.${index}.annual_total_tco2` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <Button
                              colorScheme="red"
                              w={"100%"}
                              onClick={() => handleDelete(index)}
                            >
                              삭 제
                            </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Container>
                  </>
                )}
              />
            </GridItem>
          ))}
        </Grid>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
