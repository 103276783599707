import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getGrantCheck, getSearchGrants } from "../../api";
import { IGrantDetail, IGrantList } from "../../types";
import ProtectedPage from "../ProtectedPage";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

export default function GrantProgramList({
  pk,
  grant_name,
  announcement_date,
  budget,
  announcement,
  base_year,
  support_ratio_amount,
  organizer,
}: IGrantList) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const grantPk = pk;
  const { isLoading: grantDataIsLoading, data: grantData } =
    useQuery<IGrantDetail>([`grants`, grantPk], getGrantCheck);

  return (
    <ProtectedPage>
      <>
        <Button
          rounded="2xl"
          h={70}
          mb={3}
          p={3}
          minW={900}
          maxW={900}
          backgroundColor={"unset"}
          shadow={"2xl"}
          // border={"1px"}
          // borderColor={"blue.700"}
          // borderWidth={3}
          onClick={onOpen}
        >
          <HStack>
            <Box maxW={200} marginRight={10}>
              <Image src={organizer.organizer_cis.file} />
            </Box>
            <VStack>
              <Text fontSize={"2xl"} color={"white"}>
                {grant_name}
              </Text>
              <HStack>
                <Text color={"white"} fontWeight={"thin"}>
                  공시일자: {announcement_date}
                </Text>
                <Text color={"white"} fontWeight={"thin"}>
                  예산규모: {budget}억
                </Text>
                <Text color={"white"} fontWeight={"thin"}>
                  지원금액 및 비율 : {support_ratio_amount}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </Button>

        <Drawer onClose={onClose} isOpen={isOpen} size={"xl"}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton size={"lg"} />
            <DrawerHeader>{grant_name}</DrawerHeader>
            <DrawerBody>
              <>
                <div>
                  <Image src={organizer.organizer_cis.file} />
                  <p>{organizer.organizer_name}</p>
                  <p>{grantData?.grant_name}</p>
                  <p>{grantData?.base_year}</p>
                  <p>
                    <span>공고일</span>
                    {grantData?.announcement_date}
                  </p>
                  <p>{grantData?.announcement}</p>
                  <p>{grantData?.business_overview}</p>
                  <p>{grantData?.submit_start_date}</p>
                  <p>{grantData?.budget}</p>
                  <p>{grantData?.support_details}</p>
                  <p>{grantData?.support_target}</p>
                  <p>{grantData?.support_ratio_amount}</p>
                  {/* <p>{grantData?.grant_applications}</p> */}
                  {/* <p>{grantData?.grant_areas}</p> */}

                  {/* {grants_gitem.map((item) => {
                    return (
                      <>
                        <div>
                          <Checkbox
                            rounded="2xl"
                            h={100}
                            mb={3}
                            p={3}
                            minW={"100%"}
                            // maxW={800}
                            border={"1px"}
                            borderColor={"blue.700"}
                            borderWidth={3}
                          >
                            <VStack>
                              <Text>{item.grant_item_name}</Text>
                              <Text>{item.item_description}</Text>
                            </VStack>
                          </Checkbox>
                        </div>
                      </>
                    );
                  })} */}
                </div>

                <Button>상세보기</Button>
              </>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {/* <Grid templateColumns="repeat(1,1fr)" gap={3}>
      </Grid> */}
      </>
    </ProtectedPage>
  );
}
