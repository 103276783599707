import { Heading, Text, VStack } from "@chakra-ui/react";

export default function UnAuthorizedUser() {
  return (
    <VStack justifyContent={"center"} mt={40}>
      <Heading>You have not signed up for this site.</Heading>
      <Heading color={"facebook.500"}>or</Heading>
      <Heading>You are an unauthorized user.</Heading>
      <Text color={"facebook.500"}>
        Please contact the manager of the web service.
      </Text>
    </VStack>
  );
}
