import { useFieldArray, useForm } from "react-hook-form";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  updateStep3,
  updateStep8,
} from "../SalesCompanyAnalysisRegStore";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep8() {
  const { companyPk } = useParams();
  const step8Data = useSelector((state: RootState) => state.step8);
  const { control, handleSubmit, register } = useForm<ICompanyAnalsys>({
    defaultValues: step8Data,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step8"]) => {
    dispatch(updateStep8(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step7`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step9`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #3 기타 설비 및 정보등록
        </Button>
        <Box py={3} />
        {/* //
        .########.########..######..########....###.....######..####.##.......####.########.##....##
        .##..........##....##....##.##.........##.##...##....##..##..##........##.....##.....##..##.
        .##..........##....##.......##........##...##..##........##..##........##.....##......####..
        .######......##....##.......######...##.....##.##........##..##........##.....##.......##...
        .##..........##....##.......##.......#########.##........##..##........##.....##.......##...
        .##..........##....##....##.##.......##.....##.##....##..##..##........##.....##.......##...
        .########....##.....######..##.......##.....##..######..####.########.####....##.......##...
        // */}
        <Button
          variant={"solid"}
          shadow={"dark-lg"}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
        >
          <Text color={"white"} fontSize={"xl"} fontWeight={"bold"}>
            #3-1. Accessory 설비 기록
          </Text>
        </Button>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="에어탱크" />
            <Input
              color={"white"}
              type="text"
              placeholder="예시)3m3, 5m3"
              {...register("air_tanks")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="냉동식 드라이어" />
            <Input
              color={"white"}
              type="text"
              placeholder="예시)제마코 / 500HP"
              {...register("refrigerated_dryers")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="흡착식 드라이어" />
            <Input
              color={"white"}
              type="text"
              placeholder="예시)제마코 / 500HP"
              {...register("desiccant_dryers")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <HStack
              w={"100%"}
              p={3}
              border={"1px"}
              borderColor={"white"}
              borderRadius={"md"}
            >
              <input type="checkbox" {...register("after_cooler")} />
              <Text color={"white"} marginRight={3}>
                에프터쿨러
              </Text>
              <input type="checkbox" {...register("oil_water_separator")} />
              <Text color={"white"} marginRight={3}>
                유수분리기
              </Text>
              <input type="checkbox" {...register("auto_trap")} />
              <Text color={"white"} marginRight={3}>
                오토트랩
              </Text>
            </HStack>
          </InputGroup>
        </FormControl>
        <VStack w={"100%"}>
          <Text color={"white"}>담당자 소견</Text>
          <Textarea
            color={"white"}
            w={"100%"}
            placeholder="예시)- MB분석 후 정밀진단제안서, 견적서 제출
- 경기불황으로 인해 생산량 감소."
            {...register("representative_opinion")}
          />
        </VStack>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
