import { Box, Button } from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { Link } from "react-router-dom";

export default function DeveloperHome() {
  const a = 1;

  return (
    <ProtectedPage>
      <Box
        marginTop={-5}
        h={"100vh"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
      >
        <Link to={`/service/`}>
          <Button margin={"1rem"} bgColor={"cyan"} shadow={"dark-lg"}>
            Service Home
          </Button>
        </Link>
        <Link to={`/newBusiness/`}>
          <Button
            margin={"1rem"}
            shadow={"dark-lg"}
            bgColor={"green"}
            color={"white"}
          >
            NewBusinessHome
          </Button>
        </Link>
        <Link to={`/sales/`}>
          <Button margin={"1rem"} bgColor={"tomato"} shadow={"dark-lg"}>
            SalesHome
          </Button>
        </Link>
      </Box>
    </ProtectedPage>
  );
}
