import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { BsEnvelopePlus } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ICustomerUser, ICustomerUsers, ITinyUser } from "../../types";
import { getCustomerUser, getInspectionCustomerUser } from "../../api";
import ProtectedPage from "../ProtectedPage";
import { RiRegisteredFill } from "react-icons/ri";
import { useFormContext } from "react-hook-form";

export default function SelectCustomerUserCheck() {
  const { inspectionPk } = useParams();
  const { data: customeruser } = useQuery<ICustomerUsers[]>(
    ["inspections", inspectionPk, "customeruser"],
    getInspectionCustomerUser
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const { register, setValue } = useFormContext();

  // useEffect(() => {
  //   if (customeruser) {
  //     setValue("customer_inspection_employees", customeruser || "");
  //     setValue("customer_inspection_sign_employees", customeruser || "");
  //   }
  // }, [customeruser, setValue]);

  return (
    <ProtectedPage>
      <>
        <Button
          leftIcon={<BsEnvelopePlus />}
          color="white"
          fontWeight="bold"
          borderRadius="md"
          bgGradient="linear(to-r, teal.500, green.500)"
          _hover={{
            bgGradient: "linear(to-r, red.500, yellow.500)",
          }}
          boxShadow="md"
          onClick={onOpen}
        >
          고객 담당
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          //initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Select Client</DrawerHeader>

            <DrawerBody>
              <Box
                borderRadius={"md"}
                bgGradient="linear(to-r, teal.500, green.500)"
              >
                <Container>
                  <Text justifySelf={"center"} color={"white"}>
                    이메일 수신 담당자 선택
                  </Text>
                </Container>
              </Box>
              <Box py={1} />
              <FormControl>
                <Grid mt={3} rowGap={5}>
                  {customeruser?.map((user) => (
                    <HStack
                      justifyContent={"space-between"}
                      borderBottom={"1px"}
                      borderColor={"gray.200"}
                      pr={3}
                      py={1}
                      key={user.pk}
                    >
                      <Checkbox
                        size="lg"
                        ml={3}
                        value={user.pk}
                        {...register("customer_inspection_employees")}
                      ></Checkbox>
                      <AvatarGroup max={2}>
                        <Box position={"relative"}>
                          <Avatar
                            size="md"
                            name={user.name}
                            src={user.avatar}
                          />
                          <Box position={"absolute"} bottom={-1} right={-3}>
                            <Badge
                              borderRadius="full"
                              variant="subtle"
                              colorScheme="blue"
                            >
                              {user.position_title}
                            </Badge>
                          </Box>
                        </Box>
                      </AvatarGroup>
                      <Box>
                        <HStack>
                          <Text as="b"> {user.name}</Text>
                          <Text as="b">{user.position_title}</Text>
                        </HStack>
                        <Text>{user.email}</Text>
                      </Box>
                    </HStack>
                  ))}
                </Grid>
              </FormControl>
              <Box py={10} />
              <Stack spacing="24px">
                <Box>
                  <Box
                    borderRadius={"md"}
                    bgGradient="linear(to-r, teal.500, green.500)"
                  >
                    <Container>
                      <Text justifySelf={"center"} color={"white"}>
                        보고서 서명 담당자
                      </Text>
                    </Container>
                  </Box>
                  <Box py={1} />
                  <Select
                    placeholder="선택하기"
                    {...register("customer_inspection_sign_employees")}
                  >
                    {customeruser?.map((user) => (
                      <option key={user.pk} value={user.pk}>
                        {user.name} {user.email}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Stack>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              {/* <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button> */}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    </ProtectedPage>
  );
}
