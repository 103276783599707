import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep3 } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep3() {
  const { companyPk } = useParams();
  const navigate = useNavigate();

  const step3Data = useSelector((state: RootState) => state.step3);

  const { register, handleSubmit, control } = useForm<ICompanyAnalsys>({
    defaultValues: step3Data,
  });

  const dispatch = useDispatch();

  const onSubmit = (data: StepData["step3"]) => {
    dispatch(updateStep3(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step2`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step4`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      {/* //
              .########..########.##..........###....########.####..#######..##....##
              .##.....##.##.......##.........##.##......##.....##..##.....##.###...##
              .##.....##.##.......##........##...##.....##.....##..##.....##.####..##
              .########..######...##.......##.....##....##.....##..##.....##.##.##.##
              .##...##...##.......##.......#########....##.....##..##.....##.##..####
              .##....##..##.......##.......##.....##....##.....##..##.....##.##...###
              .##.....##.########.########.##.....##....##....####..#######..##....##
              // */}
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #1 기업 기본 정보 등록
        </Button>
        <Container justifyContent={"center"} alignItems={"center"}>
          <Accordion defaultIndex={[0, 1]} allowMultiple>
            <AccordionItem>
              <AccordionButton
                color={"black"}
                // borderRadius="md"
                backgroundColor={"green.500"}
                boxShadow="dark-lg"
              >
                <Box as="b" flex="1" textAlign="center" color={"white"}>
                  <Text>관 계 회 사 정 보</Text>
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="계열 회사" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("group_company")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="종 사업장(공장)" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("factory")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="주요 구매처" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("major_sales")}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my={5}>
                  <InputGroup>
                    <InputLeftAddon children="주요 판매처" />
                    <Input
                      type="text"
                      color={"white"}
                      {...register("major_purchases")}
                    />
                  </InputGroup>
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
