import { useColorMode } from "@chakra-ui/react";

const SvgInvertor = (props) => {
  const { colorMode } = useColorMode();
  const iconColor = colorMode === "light" ? "blakc" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      viewBox="0 0 100 100"
      fill={iconColor}
      {...props}
    >
      <path d="M62.695 41.93h7.738c.93 0 1.684.754 1.684 1.684v16.602c0 .93-.754 1.683-1.684 1.683l-30.922.004-16.28 13.238h55.737V27.008zm12.848 30.188H65.324a1.684 1.684 0 0 1 0-3.367h10.219a1.684 1.684 0 0 1 0 3.367zM81.93 68.289h3.426v4.297H81.93zM14.645 28.695h3.426v4.297h-3.426zM19.754 73.625l14.422-11.727h-2.055c-.93 0-1.683-.754-1.683-1.684l-.004-16.602c0-.93.754-1.683 1.683-1.683h25.594l18.613-17.07-56.57.004zm5.98-46.148c1.887 0 2.715 1.633 3.207 2.61l.2.39c.074-.133.144-.273.199-.39.492-.977 1.32-2.61 3.207-2.61s2.71 1.633 3.203 2.61c.125.245.3.597.43.773a1.684 1.684 0 0 1-.23 3.352c-1.887 0-2.712-1.633-3.204-2.61a19.318 19.318 0 0 0-.2-.387 6.217 6.217 0 0 0-.198.39c-.492.974-1.32 2.606-3.207 2.606s-2.711-1.632-3.207-2.605c-.125-.246-.301-.598-.43-.777a1.682 1.682 0 0 1-1.453-1.668c0-.934.75-1.684 1.684-1.684z" />
      <path d="M33.801 58.535H68.75V45.301l-34.949-.004zm22.93-12.07a1.68 1.68 0 0 1 2.29.656l1.964 3.54 1.969-3.544c.453-.812 1.476-1.105 2.289-.652s1.105 1.477.656 2.29l-3.441 6.19a1.683 1.683 0 0 1-2.946 0l-3.437-6.19a1.687 1.687 0 0 1 .656-2.29zm-2.465 6.96h.84a1.684 1.684 0 0 1 0 3.368h-.84a1.684 1.684 0 0 1 0-3.367zm-5.886 0h1.472a1.684 1.684 0 0 1 0 3.368H48.38a1.684 1.684 0 0 1 0-3.367zm-5.891 0h1.473a1.684 1.684 0 0 1 0 3.368h-1.473a1.684 1.684 0 0 1 0-3.367zm-5.262 0h.84a1.684 1.684 0 0 1 0 3.368h-.84a1.684 1.684 0 0 1 0-3.367z" />
    </svg>
  );
};
export default SvgInvertor;
