import {
  Box,
  Button,
  Container,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  createCompanySign,
  createCustomerSign,
  getUploadURL,
  uploadSign,
} from "../../api";
import SignaturePadWrapper from "../SignaturePadWrapper";

interface SignModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IForm {
  file: File;
}

interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}

export default function InspectionCustomerSign({
  isOpen,
  onClose,
}: SignModalProps) {
  const basicwithe = 330;
  const { inspectionPk } = useParams();
  const methods = useForm<IForm>();
  const { handleSubmit, watch, reset, register } = methods;

  const toast = useToast();
  const createSignMutation = useMutation(createCustomerSign, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sign uploaded!",
        isClosable: true,
      });
      reset();
    },
  });

  const uploadSignMutation = useMutation(uploadSign, {
    onSuccess: ({ result }: any) => {
      if (inspectionPk) {
        createSignMutation.mutate({
          description: "",
          file: `https://imagedelivery.net/LStsWRQowienWhMtIwNrAQ/${result.id}/public`,
          inspectionPk,
        });
      }
    },
  });

  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      uploadSignMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });

  const onSubmit = () => {
    uploadURLMutation.mutate();
  };

  return (
    <ProtectedPage>
      <FormProvider {...methods}>
        <Modal onClose={onClose} isOpen={isOpen}>
          <ModalOverlay
            bg="blackAlpha.800"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent>
            <ModalHeader>고객 서명</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container
                minW={basicwithe}
                maxW={basicwithe}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <VStack as="form" onSubmit={handleSubmit(onSubmit)}>
                  <HStack
                    minW={basicwithe}
                    maxW={basicwithe}
                    justifyContent={"space-between"}
                    py={1}
                  ></HStack>
                  <SignaturePadWrapper />
                  <Box py={10} />
                  <Box py={10} />
                  <Button
                    variant="ghost"
                    type="submit"
                    isLoading={
                      createSignMutation.isLoading ||
                      uploadSignMutation.isLoading ||
                      uploadURLMutation.isLoading
                    }
                    w="full"
                  />
                  <Box py={10} />
                </VStack>
              </Container>
            </ModalBody>
          </ModalContent>
        </Modal>
      </FormProvider>
    </ProtectedPage>
  );
}
