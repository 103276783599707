import { useColorMode } from "@chakra-ui/react";

const SvgMotor = (props) => {
  const { colorMode } = useColorMode();
  const iconColor = colorMode === "light" ? "blakc" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      viewBox="0 0 100 100"
      fill={iconColor}
      {...props}
    >
      <path d="M44.305 15.918h23.262v2.09H44.305zM2.875 48.77h15.793v2.914H2.875zM24.16 67.516a3.98 3.98 0 0 1-3.977-3.977l.004-10.336V36.914a3.977 3.977 0 0 1 3.977-3.972h4.094v34.57zM76.051 81.258a3.59 3.59 0 0 1 3.504 2.824H32.313a3.593 3.593 0 0 1 3.504-2.824 5.118 5.118 0 0 0 5.05-4.348h30.134a5.118 5.118 0 0 0 5.05 4.348zM40.926 72.48h30.02v2.906h-30.02zM68.035 29.492H29.773v41.473h52.316l.004-41.473zm9.152 37.863H34.675a1.805 1.805 0 0 1 0-3.613h42.512c.996 0 1.805.808 1.805 1.808a1.8 1.8 0 0 1-1.805 1.805zm0-7.656H34.675a1.809 1.809 0 0 1 0-3.617h42.512c.996 0 1.805.808 1.805 1.808.004 1-.805 1.809-1.805 1.809zm0-7.668H34.675a1.805 1.805 0 0 1 0-3.61h42.512c.996 0 1.805.809 1.805 1.805.004 1-.805 1.805-1.805 1.805zm0-7.664H34.675a1.806 1.806 0 0 1 0-3.614h42.512c.996 0 1.805.809 1.805 1.805.004 1-.805 1.809-1.805 1.809zm0-7.656H34.675a1.806 1.806 0 0 1 0-3.614h42.512c.996 0 1.805.809 1.805 1.805.004 1-.805 1.809-1.805 1.809zM91.805 26.18h-8.191v48.098h8.191c2.934 0 5.32-2.063 5.32-4.606v-38.89c0-2.54-2.386-4.602-5.32-4.602zm2.223 44.105H86.71a.903.903 0 0 1 0-1.805h7.317a.903.903 0 0 1 0 1.805zm0-4.848H86.71a.903.903 0 0 1 0-1.804h7.317a.903.903 0 0 1 0 1.804zm0-4.844H86.71a.903.903 0 0 1 0-1.804h7.317a.9.9 0 0 1 .902.902.905.905 0 0 1-.902.902zM45.355 19.531v8.441h21.16v-8.441zm5.824 6.328a2.111 2.111 0 0 1 0-4.223 2.11 2.11 0 0 1 2.11 2.11 2.106 2.106 0 0 1-2.11 2.113zm9.508 0a2.111 2.111 0 1 1 .004-4.223 2.111 2.111 0 0 1-.004 4.223z" />
    </svg>
  );
};
export default SvgMotor;
