import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";

export default function OnestopLogoWhite() {
  //
  const pathControls = useAnimationControls();
  const retControls = useAnimationControls();
  const buttonControls = useAnimationControls();
  //
  //
  const pathVariants = {
    initial: {
      opacity: 0,
      pathLength: 0,
      pathOffset: 1,
      fill: "rgba(255, 255, 255,0)",
    },
    animate: {
      display: "path",
      opacity: 1,
      pathLength: 1,
      pathOffset: 0,
      fill: "rgba(255, 255, 255,1)",
      transition: {
        duration: 1.8,
        fill: {
          duration: 1,
          delay: 1.1,
        },
      },
    },
  };

  const retVariants = {
    initial: {
      opacity: 0,
      fill: "rgba(255, 255, 255,0)",
    },
    animate: {
      opacity: 1,
      fill: "url(#SVGID_1_)",
      transition: {
        duration: 1.8,
        fill: {
          duration: 1,
          delay: 1.1,
        },
      },
    },
  };

  // useEffect(() => {
  //   const sequence = async () => {
  //     await pathControls.start({});
  //     return;
  //     // onAnimationComplete();
  //   };
  //   sequence();
  // }, [pathControls, onAnimationComplete]);
  // console.log(onAnimationComplete);

  return (
    <>
      <motion.svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        // width="401.25px"
        width="77rem"
        // height="47px"
        height="50rem"
        viewBox="0 0 401.25 47"
        style={{
          position: "absolute",
          marginRight: "-150px",
        }}
      >
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M7.581,19.979c-1.892-0.002-3.341-0.688-4.346-2.06
	c-1.002-1.37-1.137-3.336-0.41-5.896c0.846-2.965,2.34-5.482,4.486-7.553c2.148-2.071,4.478-3.107,6.99-3.107
	C16.315,1.365,17.76,2.09,18.64,3.54c0.879,1.447,0.984,3.344,0.315,5.686c-0.851,2.993-2.341,5.533-4.469,7.62
	C12.356,18.935,10.056,19.979,7.581,19.979z M8.792,15.854c0.829,0,1.57-0.388,2.22-1.16c0.65-0.773,1.315-1.91,1.99-3.405
	c0.678-1.495,1.089-2.509,1.24-3.036c0.258-0.907,0.265-1.596,0.017-2.063c-0.246-0.466-0.681-0.698-1.308-0.698
	c-1.2,0-2.3,0.919-3.313,2.758c-1.008,1.843-1.715,3.474-2.118,4.892C7.007,14.947,7.431,15.851,8.792,15.854z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}

          // O 글자
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M37.974,1.826l-7.72,17.705l-3.559-0.002l-1.142-6.801
	c-0.306-1.857-0.417-3.328-0.33-4.41c-0.056,0.26-0.107,0.464-0.151,0.611c-0.138,0.482-0.377,1.124-0.724,1.92l-3.785,8.68
	l-3.906-0.003l7.718-17.704l4.317,0.001l0.595,4.099c0.411,2.838,0.609,4.937,0.592,6.294c0.078-0.356,0.14-0.62,0.189-0.794
	c0.142-0.502,0.523-1.462,1.139-2.88l2.931-6.718L37.974,1.826z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // n 글자
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M46.631,15.462l-1.78,4.073l-11.813-0.004l7.719-17.705L52.273,1.83
	l-1.691,3.878l-6.875-0.003l-1.205,2.776l5.775,0.002l-1.679,3.839l-5.768-0.002l-1.363,3.14L46.631,15.462z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // e 글자
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M71.288,5.947l-4.45,1.153c0.034-0.528-0.143-0.943-0.524-1.245
	c-0.385-0.303-0.858-0.453-1.421-0.453c-1.059-0.002-1.697,0.386-1.918,1.165c-0.069,0.251-0.058,0.467,0.038,0.648
	c0.097,0.181,0.451,0.407,1.064,0.679l2.262,1.002c0.589,0.262,1.099,0.646,1.526,1.149c0.429,0.507,0.682,1.059,0.761,1.659
	c0.079,0.603,0.022,1.245-0.172,1.926c-0.509,1.789-1.566,3.266-3.168,4.433c-1.605,1.167-3.468,1.751-5.589,1.748
	c-1.815,0-3.284-0.406-4.402-1.221c-1.121-0.812-1.707-2.054-1.765-3.723l4.911-0.998c0.042,1.255,0.85,1.879,2.424,1.879
	c0.657,0.003,1.17-0.13,1.539-0.392c0.367-0.267,0.599-0.565,0.695-0.902c0.063-0.217,0.061-0.407-0.007-0.57
	c-0.068-0.167-0.155-0.285-0.262-0.358c-0.107-0.072-0.644-0.33-1.605-0.775c-1.301-0.598-2.195-1.075-2.688-1.424
	c-0.49-0.351-0.836-0.87-1.035-1.563c-0.2-0.69-0.166-1.503,0.099-2.436c0.462-1.617,1.39-2.984,2.784-4.104
	c1.394-1.118,3.201-1.678,5.414-1.676c1.691,0,3.029,0.395,4.01,1.183C70.789,3.518,71.283,4.59,71.288,5.947z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // s 글자
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M80.596,5.86l-5.975,13.685l-4.656-0.003l5.974-13.684L72.51,5.857
	l1.783-4.075L85.9,1.787l-1.77,4.074L80.596,5.86z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // t
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M161.419,1.363c-3.909,0-7.962,1.302-12.157,3.905
	c-4.19,2.583-7.067,5.629-8.621,9.133c-1.897,4.27-1.728,7.439,0.509,9.521l5.747,5.318c0.063,0.107,0.068,0.232,0.001,0.381
	c-0.38,0.862-0.968,1.288-1.763,1.288h-17.793l-5.978,13.453h26.881c3.814,0,7.805-1.185,11.97-3.56
	c4.152-2.394,6.987-5.293,8.494-8.69c1.822-4.099,1.485-7.363-1.015-9.799l-6.103-5.862c-0.084-0.064-0.084-0.201,0.007-0.41
	c0.374-0.84,0.961-1.261,1.756-1.261h19.057l5.962-13.418H161.419z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 큰 S
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M191.388,1.363l-5.961,13.418h29.336c0.957,0,1.645,0.325,2.057,0.977
	c0.436,0.654,0.461,1.408,0.08,2.271c-0.385,0.858-1.084,1.617-2.092,2.273c-0.986,0.625-1.959,0.938-2.916,0.938h-29.339
	l-10.269,23.124h17.966l5.736-12.915h16.144c4.398,0,8.973-1.526,13.713-4.566c4.746-3.049,7.992-6.536,9.738-10.46
	c1.758-3.951,1.611-7.446-0.426-10.49c-2.045-3.046-5.262-4.568-9.662-4.568H191.388z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 큰 p
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M242.744,37.142c-0.33-0.419-0.346-0.951-0.063-1.596
	c0.262-0.588,0.658-1.074,1.191-1.452c0.535-0.376,1.307-0.782,2.309-1.216l2.342,3.526c-1.699,0.91-3.066,1.366-4.092,1.366
	C243.635,37.771,243.072,37.562,242.744,37.142 M255.023,20.613c0.279-0.616,0.77-1.142,1.484-1.574
	c0.713-0.437,1.449-0.654,2.197-0.654c0.766,0,1.301,0.2,1.611,0.61c0.309,0.407,0.318,0.929,0.035,1.576
	c-0.633,1.428-2.111,2.417-4.426,2.958C254.906,22.524,254.605,21.555,255.023,20.613 M248.973,13.955
	c-3.131,1.778-5.283,3.999-6.463,6.658c-1.027,2.309-0.844,4.591,0.557,6.842c-6.842,2.243-11.146,5.353-12.914,9.328
	c-0.982,2.209-0.865,4.027,0.361,5.448c1.227,1.423,3.422,2.132,6.592,2.132c2.1,0,4.393-0.328,6.879-0.983
	c2.48-0.658,4.887-1.597,7.205-2.819l2.148,3.277h16.145l-6.563-10.143c3.645-2.337,7.285-5.076,10.924-8.211h-9.051
	c-1.604,1.637-3.15,2.956-4.635,3.948l-2.008-3.106c4.232-2.13,6.977-4.597,8.217-7.396c0.998-2.241,0.803-4.073-0.57-5.501
	c-1.379-1.433-3.652-2.145-6.818-2.145C255.439,11.285,252.102,12.176,248.973,13.955"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 큰 &
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M312.674,1.363c-4.758,0-9.422,1.02-13.994,3.058
	c-4.57,2.037-8.563,4.714-11.973,8.021c-3.406,3.304-5.92,6.783-7.535,10.424c-1.615,3.636-2.191,7.115-1.723,10.423
	c0.473,3.303,2.084,5.977,4.83,8.015c2.752,2.042,6.516,3.06,11.291,3.06h23.951l11.945-26.88h-20.938l-4.785,10.761h8.945
	l-2.377,5.353h-8.963c-2.299,0-4.174-0.425-5.619-1.281c-1.449-0.858-2.326-2.092-2.617-3.713c-0.297-1.611,0.047-3.522,1.027-5.726
	c0.838-1.89,2.143-3.651,3.918-5.297c1.771-1.644,3.797-2.963,6.084-3.958c2.285-0.999,4.539-1.495,6.764-1.495h23.949l4.783-10.764
	H312.674z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 큰 G
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M87.07,20.003c-1.894-0.003-3.342-0.688-4.345-2.059
	c-1.004-1.37-1.141-3.337-0.412-5.896c0.846-2.967,2.34-5.483,4.488-7.555c2.146-2.071,4.479-3.106,6.99-3.105
	c2.013,0.001,3.458,0.725,4.337,2.175c0.878,1.449,0.985,3.344,0.317,5.687c-0.852,2.993-2.341,5.533-4.47,7.621
	C91.844,18.959,89.543,20.003,87.07,20.003z M88.279,15.876c0.83,0,1.572-0.388,2.221-1.16c0.65-0.773,1.314-1.908,1.992-3.405
	c0.675-1.495,1.088-2.508,1.241-3.036c0.258-0.907,0.263-1.594,0.017-2.061c-0.248-0.468-0.684-0.7-1.311-0.7
	c-1.197,0-2.3,0.919-3.31,2.76c-1.011,1.842-1.717,3.473-2.122,4.891C86.495,14.972,86.917,15.875,88.279,15.876z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // stop의 O
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M103.639,13.053l-2.839,6.499l-4.654-0.001l7.717-17.706l5.297,0.003
	c1.245,0,2.136,0.058,2.668,0.176c0.536,0.117,1.039,0.397,1.52,0.843c0.48,0.445,0.793,1.042,0.937,1.792
	c0.143,0.747,0.095,1.549-0.152,2.405c-0.472,1.659-1.363,3.073-2.674,4.24c-1.311,1.167-2.876,1.751-4.696,1.749H103.639z
	 M105.295,9.264l1.104,0.003c1.686,0,2.707-0.631,3.064-1.887c0.321-1.129-0.221-1.694-1.631-1.694h-0.974L105.295,9.264z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // stop의 p
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M13.952,44.293h-3.888l3.735-8.448h-0.906
	c-1.17,2.513-2.413,4.235-3.734,5.167s-2.629,1.397-3.924,1.397c-1.296,0-2.183-0.567-2.661-1.702
	c-0.479-1.133-0.134-3.015,1.03-5.647c1.176-2.657,2.5-4.546,3.977-5.666c1.474-1.124,2.861-1.685,4.158-1.685
	c1.297,0,2.172,0.43,2.627,1.291c0.454,0.861,0.323,2.328-0.39,4.399h0.908l2.749-6.215h3.89L13.952,44.293z M9.434,31.21
	c-0.527,0.702-1.203,1.987-2.027,3.85c-0.825,1.86-1.283,3.146-1.378,3.85c-0.096,0.703,0,1.054,0.289,1.054
	c0.316,0,0.739-0.351,1.266-1.054c0.527-0.703,1.205-1.989,2.027-3.85c0.824-1.862,1.285-3.147,1.378-3.85
	c0.096-0.703-0.016-1.057-0.332-1.057C10.37,30.153,9.962,30.507,9.434,31.21z M18.532,44.293h-3.89l7.569-17.108h3.892
	L18.532,44.293z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 에
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M40.254,44.328h-4.322l0.616-1.396H25.012
	c-0.548,0-0.927-0.134-1.14-0.4c-0.213-0.27-0.223-0.623-0.025-1.065l6.071-13.723h4.322l-5.638,12.746h9.031l2.193-4.959h-4.58
	l1.081-2.444h4.581l2.627-5.936h4.32L40.254,44.328z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 너
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M65.638,27.29h4.322l-7.521,17.003h-4.323l1.266-2.862l-2.882,1.536
	c-0.596-0.604-1.118-1.349-1.562-2.234c-0.446-0.885-0.766-1.849-0.964-2.897c-1.057,0.954-2.205,1.875-3.445,2.757
	c-1.243,0.887-2.424,1.653-3.549,2.305l-1.903-1.85c2.063-1.211,3.98-2.684,5.758-4.417c1.773-1.735,3.212-3.847,4.315-6.336H51
	l1.083-2.445h12.358l-1.083,2.445h-3.89c-1.121,2.537-1.556,4.597-1.307,6.18c0.251,1.581,0.758,3.002,1.529,4.259L65.638,27.29z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 지
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M88.402,36.297h-4.666l1.357-3.072h-2.723
	c0.18,0.188,0.392,0.362,0.636,0.524c0.244,0.163,0.583,0.372,1.018,0.628l-3.345,1.99c-0.775-0.396-1.388-0.796-1.844-1.205
	c-0.452-0.405-0.862-0.947-1.223-1.622c-0.866,0.651-1.851,1.246-2.95,1.78c-1.101,0.535-2.224,0.956-3.365,1.256l-1.715-1.885
	c1.432-0.44,2.769-0.982,4-1.622c1.236-0.642,2.303-1.555,3.201-2.74h-3.626l1.078-2.445h11.841l-1.083,2.445h-3.672
	c-0.114,0.579-0.076,1.097,0.11,1.552c0.19,0.454,0.441,0.845,0.757,1.17l1.006-2.27h2.982l1.529-3.457h4.666L88.402,36.297z
	 M70.081,44.016c-0.55,0-0.926-0.141-1.136-0.42c-0.204-0.279-0.21-0.64-0.015-1.081l1.405-3.179h12.403l0.184-0.42H70.522
	l0.926-2.093h16.723l-2.038,4.607H73.73l-0.216,0.487h12.401l-0.927,2.098H70.081z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 절
        />
        <motion.path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth={"0.3"}
          stroke-miterlimit="10"
          d="M106.008,44.363H91.271c-0.547,0-0.926-0.137-1.137-0.41
	c-0.208-0.274-0.213-0.638-0.011-1.094l2.485-5.619h16.552L106.008,44.363z M106.905,28.132c0.131,0.188,0.114,0.468-0.054,0.846
	c-0.705,1.597-2.453,3.266-5.236,5c-2.782,1.733-5.786,2.693-9.01,2.879l-1.421-2.059c2.361-0.326,4.358-0.867,5.992-1.626
	c1.628-0.755,2.975-1.715,4.037-2.878h-6.784l1.083-2.445h10.543C106.486,27.849,106.769,27.941,106.905,28.132z M94.862,41.918
	h7.907l0.988-2.232H95.85L94.862,41.918z M112.203,30.361h2.159l-1.08,2.446h-2.16l-1.838,4.153h-4.323l4.279-9.671h4.32
	L112.203,30.361z"
          variants={pathVariants}
          initial="initial"
          animate={pathControls}
          // 감
        />
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="2.3638"
          y1="23.2207"
          x2="114.3623"
          y2="23.2207"
        >
          <stop offset="0" style={{ stopColor: "#ED2024" }} />
          <stop offset="0.0083" style={{ stopColor: "#EC2824" }} />
          <stop offset="0.1258" style={{ stopColor: "#DF6326" }} />
          <stop offset="0.2128" style={{ stopColor: "#D77A27" }} />
          <stop offset="0.259" style={{ stopColor: "#D58228" }} />
          <stop offset="0.3861" style={{ stopColor: "#D38729" }} />
          <stop offset="0.5667" style={{ stopColor: "#D28929" }} />
          <stop offset="0.8531" style={{ stopColor: "#99B03B" }} />
          <stop offset="1" style={{ stopColor: "#73BF44" }} />
        </linearGradient>
        <motion.rect
          variants={retVariants}
          initial="initial"
          animate={retControls}
          x="2.364"
          y="22.14"
          width="111.999"
          height="2.161"
        />
      </motion.svg>
      <motion.button
        style={{
          position: "absolute",
          color: "white",
          width: "500px",
          height: "120px",
          // border: "1px solid black",
          borderRadius: "20px",
          textShadow: "2px 2px 0.5px black",
          fontSize: "77px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px",
        }}
        initial={{ opacity: 1 }}
        animate={buttonControls}
        // animate={pathControls}
        transition={{ delay: 1, duration: 1 }}
        onClick={() => {
          pathControls.start({
            display: "path",
            opacity: 1,
            pathLength: 1,
            pathOffset: 0,
            fill: "rgba(255, 255, 255,1)",
            transition: {
              duration: 1.8,
              fill: {
                duration: 1,
                delay: 1.1,
              },
            },
          });
          retControls.start({
            opacity: 1,
            fill: "url(#SVGID_1_)",
            transition: {
              duration: 1.8,
              fill: {
                duration: 1,
                delay: 1.1,
              },
            },
          });
          buttonControls.start({
            opacity: 0,
            transition: {
              duration: 1.8,
            },
          });
        }}
      >
        Start
      </motion.button>
    </>
  );
}
