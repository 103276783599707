import { useEffect, useState } from "react";
import ProtectedPage from "../ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Heading, Spinner, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { editCompanyAnalsys, uploadCompanyAnalsys } from "../../api";
import { RootState } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, IEditCompanyAnalsys } from "../../types";
import { useForm } from "react-hook-form";

export default function SalesCompanyAnalysisEditStepFinal() {
  const { companyPk } = useParams();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IEditCompanyAnalsys>();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();
  const companyAnalysisData = useSelector<RootState>((state) => {
    return {
      ...state.step1,
      ...state.step2,
      ...state.step3,
      ...state.step4,
      ...state.step5,
      ...state.step6,
      ...state.step7,
      ...state.step8,
      ...state.step9,
      ...state.step10,
      ...state.step11,
      ...state.step12,
      ...state.step13,
      ...state.step14,
      ...state.step15,
    };
  });
  // console.log(companyAnalysisData); // 정상 데이터가 들어옴.
  const toast = useToast();
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    editCompanyAnalsys,
    {
      onSuccess: () => {
        toast({
          status: "success",
          title: "관리업체 분석표가 수정되었습니다!",
          position: "top",
        });
        navigate(`/sales/company-analysis/${companyPk}`);
      },
      onError: (error: any) => {
        // 오류 메시지를 추출하고 결합하는 재귀 함수
        const extractErrorMessage = (errorData: any): string => {
          if (Array.isArray(errorData)) {
            return errorData.map((e) => extractErrorMessage(e)).join(", ");
          } else if (typeof errorData === "object" && errorData !== null) {
            return Object.entries(errorData)
              .map(([key, value]) => `${key}: ${extractErrorMessage(value)}`)
              .join("\n");
          }
          return errorData.toString();
        };

        // 오류 메시지 추출
        const errorMessage =
          error.response && error.response.data
            ? extractErrorMessage(error.response.data)
            : "An unexpected error occurred.";

        // 마침표 뒤에 개행 문자를 추가하여 오류 메시지를 줄 바꿈으로 구분
        const formattedErrorMessage = errorMessage.replace(/\. /g, ".\n");
        // 사용자에게 오류 메시지를 보여주는 토스트 메시지 생성
        toast({
          status: "error",
          title: "Error",
          description: formattedErrorMessage,
          position: "bottom",
          isClosable: true,
          duration: 90,
        });
        // navigate(`sales/company-analysis/:companyPk/`);
      },
    }
  );

  useEffect(() => {
    if (!hasSubmitted) {
      mutate(companyAnalysisData as IEditCompanyAnalsys);
      setHasSubmitted(true);
    }
  }, [companyAnalysisData, mutate]);

  return (
    <ProtectedPage>
      <VStack
        marginTop={-70}
        h={"100vh"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        // onSubmit={handleSubmit(onSubmit)}
        justifyContent={"center"}
      >
        <Heading color={"white"}>Processing upload...</Heading>
        <Text color={"white"}>Don't go anywhere.</Text>
        <Spinner color="white" size="xl" />
      </VStack>
    </ProtectedPage>
  );
}
