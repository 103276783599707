import { useQuery } from "@tanstack/react-query";
import { getExecutiveStatus } from "../api";
import { useParams } from "react-router-dom";
import { IExecutiveStatus, IExecutiveStatusDetail } from "../types";
import { HStack, Text } from "@chakra-ui/react";

export default function ExecutiveStatus({
  rcept_no,
  corp_cls,
  corp_code,
  corp_name,
  nm,
  sexdstn,
  birth_ym,
  ofcps,
  rgist_exctv_at,
  fte_at,
  chrg_job,
  main_career,
  mxmm_shrholdr_relate,
  hffc_pd,
  tenure_end_on,
}: IExecutiveStatusDetail) {
  return (
    <div>
      <HStack>
        <Text>{rcept_no}</Text>
        <Text>{corp_cls}</Text>
        <Text>{corp_code}</Text>
        <Text>{corp_name}</Text>
        <Text>{nm}</Text>
        <Text>{sexdstn}</Text>
        <Text>{birth_ym}</Text>
        <Text>{ofcps}</Text>
        <Text>{rgist_exctv_at}</Text>
        <Text>{fte_at}</Text>
        <Text>{chrg_job}</Text>
        <Text>{main_career}</Text>
        <Text>{mxmm_shrholdr_relate}</Text>
        <Text>{hffc_pd}</Text>
        <Text>{tenure_end_on}</Text>
      </HStack>
    </div>
  );
}
