import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import {
  FaMoon,
  FaNode,
  FaPython,
  FaReact,
  FaSearch,
  FaSun,
} from "react-icons/fa";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import SpngLogo from "./Customicons";
import useUser from "../lib/useUser";
import { Link, useNavigate } from "react-router-dom";
import { logOut } from "../api";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import GridSearchComp from "./routes/GridSearchComp";
import SpngLogoWhite from "./SpngLogoWhtie";
import ServiceDashBoard from "./routes/ServiceDashBoard";
import ServiceHome from "./routes/ServiceHome";
import UnAuthorizedUser from "./UnAuthorizedUser";

export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const onLogOut = async () => {
    const toastId = toast({
      title: "Login out...",
      description: "Sad to see you go...",
      status: "loading",
      position: "bottom-right",
    });
    await logOut();
    queryClient.refetchQueries(["me"]);
    toast.update(toastId, {
      status: "success",
      title: "Done!",
      description: "See you later!",
    });
  };
  const navigate = useNavigate(); // useNavigate 훅 추가
  const handleUser = () => {
    if (userLoading) {
      return <Spinner />;
    }

    if (isLoggedIn) {
      if (user?.is_developer) {
        navigate(`/developer`);
      }
      if (user?.is_salesman) {
        navigate(`/sales`);
      }
      if (user?.is_new_business) {
        navigate(`/newBusiness`);
      }
      if (user?.is_service_salesman) {
        navigate(`/service`);
      }
      if (user?.is_service_engineer) {
        navigate(`/service`);
      } else return <UnAuthorizedUser />;
    }
  };

  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      py={"3"}
      px={"3"}
      // backgroundColor={"#292A69"}
      // bgGradient="linear(to-r, blue.500, green.500)"
      bgGradient="linear(to-r, #2A2B6E, #085C94)"
      direction={{
        sm: "column", // 에어비앤비 아이콘의 위치를 잡기위해서 추가하는 부분.....
        md: "row",
      }}
      spacing={{
        sm: 3,
        md: 0,
      }}
      // borderBottomWidth={1}
    >
      <Tooltip hasArrow bg="facebook.600" label="Go First Page!" fontSize="md">
        <Button backgroundColor={"unset"} onClick={handleUser}>
          <SpngLogoWhite />
        </Button>
        {/* <Link to={"/"}>
          <SpngLogoWhite />
        </Link> */}
      </Tooltip>
      <HStack spacing={"3"}>
        {user?.is_service_salesman ||
        user?.is_service_engineer ||
        user?.is_developer ? (
          <>
            <Link to={"compressors/searchcomp"}>
              <IconButton
                aria-label="Search Comp"
                icon={<FaSearch color="white" />}
                fontSize={"3xl"}
                bg="transparent"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ outline: "none" }}
              />
            </Link>
          </>
        ) : null}

        {/* <Tooltip
          hasArrow
          bg="red.600"
          label="Dark Mode... Don't Use it..."
          fontSize="md"
        >
          <IconButton
            onClick={toggleColorMode}
            size={"xs"}
            variant={"ghost"}
            aria-label="Toggle dark mode"
            icon={<Icon />}
          />
        </Tooltip> */}

        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button
                onClick={onLoginOpen}
                size={"xs"}
                variant={"solid"}
                colorScheme="twitter"
              >
                Log in
              </Button>
              {/* <Button onClick={onSignUpOpen} size={"xs"} colorScheme="red">
                Sign up
              </Button> */}
            </>
          ) : (
            <Menu>
              <MenuButton>
                <Box position={"relative"}>
                  <Avatar name={user?.name} src={user?.avatar} />
                  <Box position={"absolute"} bottom={-1} right={-3}>
                    <Badge
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="blue"
                    >
                      {user?.position_title}
                    </Badge>
                  </Box>
                </Box>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={onLogOut}>Log out</MenuItem>
                {user?.is_staff ? (
                  <>
                    <Link to={`/makeemail/`}>
                      <MenuItem>Make email</MenuItem>
                    </Link>
                  </>
                ) : null}
                {user?.is_developer ? (
                  <>
                    <Link to={`/users/servicedashboard`}>
                      <MenuItem>Dash Board</MenuItem>
                    </Link>
                    <Link to={`/customers/searchcustomer`}>
                      <MenuItem>CRM</MenuItem>
                    </Link>
                  </>
                ) : null}
              </MenuList>
            </Menu>
          )
        ) : null}
      </HStack>
      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
      {/* <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} /> */}
    </Stack>
  );
}
