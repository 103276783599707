import { useDispatch } from "react-redux";
import ProtectedPage from "../ProtectedPage";
import { useEffect } from "react";
import { resetStore } from "../SalesCompanyAnalysisRegStore";
import {
  Box,
  Button,
  Input,
  Select,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import SearchCustomers from "../SearchCustomers";

export default function CompanyAnalysisRegAllSteps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetStore());
    // navigate(`/sales/company-analysis-router/step1`);
  }, [dispatch]);

  return (
    <ProtectedPage>
      <VStack bgGradient="linear(to-r, #2A2B6E, #085C94)" h={"100vh"} p={5}>
        <Button
          colorScheme="cyan"
          shadow={"dark-lg"}
          onClick={() => navigate(`/sales/company-analysis-router/step1`)}
        >
          신규고객 관리업체 분석표 등록
        </Button>
        <Box py={5} />
        <Text color={"white"}>거래중인 고객 조회</Text>
        {/* <SearchCustomers /> */}
      </VStack>
    </ProtectedPage>
  );
}
