import {
  Button,
  Container,
  Divider,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { createOrganizer, getUploadURL, uploadImage } from "../../api";
import { useNavigate } from "react-router-dom";

interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}

export default function OrgReg() {
  const { register, handleSubmit, watch } = useForm();
  const navigate = useNavigate();
  const toast = useToast();
  const createOrganizerMutation = useMutation(createOrganizer, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "주관기관이 등록되었습니다!",
        isClosable: true,
      });
      navigate(-1);
    },
  });
  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      createOrganizerMutation.mutate({
        organizer_name: watch("organizer_name") || "",
        organizer_cis: {
          // pk: "", <<< interface에서 pk 를 pk? 로 설정하여 해결함...
          file: `https://imagedelivery.net/LStsWRQowienWhMtIwNrAQ/${result.id}/public`,
        },
      });
    },
  });
  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      uploadImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });
  const onSubmit = () => {
    uploadURLMutation.mutate();
  };

  console.log(watch);

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Container minW={900} px={3} paddingBottom="50px">
          <Text
            py={3}
            color={"white"}
            fontSize={"2xl"}
            fontWeight="extrabold"
            align={"center"}
          >
            주관기관 등록
          </Text>
          <Divider />
        </Container>
        <Container minW={900}>
          <Stack spacing={5}>
            <Stack mb={"3rem"}>
              <HStack>
                <Text fontSize={"2xl"} color={"white"}>
                  1. 주관기관 명
                </Text>
                <Text fontSize={"sm"} color={"tomato"}>
                  ※ 화면에 보여지는 내용을 입력하세요
                </Text>
              </HStack>
              <Input
                placeholder="예)한국에너지공단"
                backgroundColor={"white"}
                {...register("organizer_name")}
                type="text"
              />
            </Stack>
            <Stack>
              <Text mr={20} fontSize={"2xl"} color={"white"}>
                2. 주관기관 CI 이미지 업로드
              </Text>
              <Input
                {...register("file")}
                backgroundColor={"white"}
                type="file"
                accept="image/*"
              />
            </Stack>
            <Button
              isLoading={
                createOrganizerMutation.isLoading ||
                uploadImageMutation.isLoading ||
                uploadURLMutation.isLoading
              }
              type="submit"
              w="full"
              colorScheme={"red"}
            >
              등록하기
            </Button>
          </Stack>
        </Container>
      </VStack>
    </ProtectedPage>
  );
}
