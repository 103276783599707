import { Spinner } from "@chakra-ui/react";
import useUser from "../../lib/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import UnAuthorizedUser from "../UnAuthorizedUser";

export default function MainHome() {
  const { userLoading, isLoggedIn, user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  // 사용자가 로딩 중이라면 로딩 인디케이터 표시
  useEffect(() => {
    // 사용자가 로그인하고 루트 경로에 있을 때만 리디렉션을 수행합니다.
    if (isLoggedIn && location.pathname === "/") {
      if (user?.is_developer) {
        navigate("/developer");
      } else if (user?.is_salesman) {
        navigate("/sales");
      } else if (user?.is_new_business) {
        navigate("/newBusiness");
      } else if (user?.is_service_salesman || user?.is_service_engineer) {
        navigate("/service");
      }
    }
  }, [isLoggedIn, location, navigate, user]); // 의존성 배열 추가

  if (userLoading) {
    return <Spinner />;
  }

  if (!isLoggedIn) {
    return <UnAuthorizedUser />;
  }

  return <div></div>;
}
