import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep9 } from "../SalesCompanyAnalysisRegStore";
import { ICompanyAnalsys, StepData } from "../../types";

export default function CompanyAnalysisRegStep9() {
  const { companyPk } = useParams();
  const step9Data = useSelector((state: RootState) => state.step9);
  const { control, handleSubmit, register } = useForm<ICompanyAnalsys>({
    defaultValues: step9Data,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step9"]) => {
    dispatch(updateStep9(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step8`); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step10`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          variant={"solid"}
          shadow={"dark-lg"}
          w={"100%"}
          bgGradient="linear(to-r, teal.500, green.500)"
        >
          <Text color={"white"} fontSize={"xl"} fontWeight={"bold"}>
            #3-2. 유지보수 정보
          </Text>
        </Button>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="현재 서비스 업체" />
            <Input
              color={"white"}
              type="text"
              placeholder="예시)우주유체"
              {...register("current_service_provider")}
            />
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="비품사용여부" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              {...register("is_genuine")}
            >
              <option value="all">전체</option>
              <option value="two">2종</option>
              <option value="four">4종</option>
              <option value="acc">ACC</option>
              <option value="none">정품사용</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          교체주기
        </Text>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="AIR FILTER / OIL FILTER" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              {...register("exchange_filters")}
            >
              <option value="two">2000시간</option>
              <option value="three">4000시간</option>
              <option value="four">8000시간</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl my={5}>
          <InputGroup>
            <InputLeftAddon children="OIL / OIL SEPARATOR" />
            <Select
              color={"tomato"}
              placeholder="Choose a kind"
              {...register("exchange_oils")}
            >
              <option value="four">4000시간</option>
              <option value="six">6000시간</option>
              <option value="eight">8000시간</option>
            </Select>
          </InputGroup>
        </FormControl>
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
    </ProtectedPage>
  );
}
