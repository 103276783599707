import { Grid, GridItem, HStack, Text, Tooltip } from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { IGItem } from "../../types";
import { getGItems } from "../../api";
import { useEffect } from "react";

interface IGrantDetailItemListProps {
  index: number;
}

export default function GrantDetailItemList({
  index,
}: IGrantDetailItemListProps) {
  const { register, setValue, watch, getValues } = useFormContext();
  const { data: gItemData } = useQuery<IGItem[]>([`gItem`], getGItems);

  return (
    <ProtectedPage>
      <Grid templateColumns={"repeat(3, 1fr)"} gap={3}>
        {gItemData?.map((gItem) => (
          <GridItem key={gItem.id}>
            <HStack>
              <input
                type="checkbox"
                value={gItem.id}
                {...register(`grant_areas.${index}.grants_gitem` as const)}
              />
              <Tooltip hasArrow label={gItem.item_description}>
                <Text color={"white"}>{gItem.grant_item_name}</Text>
              </Tooltip>
            </HStack>
          </GridItem>
        ))}
      </Grid>
    </ProtectedPage>
  );
}
