import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const fonts = {
  body: "Noto Sans KR, sans-serif",
  heading: "Noto Sans KR, sans-serif",
  mono: "Menlo, monospace", // 기본 monospace 폰트. 필요하면 변경하세요.
};

const theme = extendTheme({ config, fonts });

export default theme;
