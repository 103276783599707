import {
  Box,
  Button,
  Container,
  Divider,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useForm } from "react-hook-form";
import { IGItem } from "../../types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { uploadGItem } from "../../api";

export default function GrantDetailItemReg() {
  const { handleSubmit, register, watch } = useForm<IGItem>();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation(uploadGItem, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Report created",
        position: "bottom-right",
      });
      navigate(-1);
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        description: error.response.data.detail,
        position: "bottom",
      });
    },
  });

  const onSubmit = (data: IGItem) => {
    mutation.mutate(data);
  };

  return (
    <ProtectedPage>
      <VStack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        marginTop={-5}
        h={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
      >
        <Container minW={900} px={3} paddingBottom="50px">
          <Text
            py={3}
            color={"white"}
            fontSize={"2xl"}
            fontWeight="extrabold"
            align={"center"}
          >
            세부아이템 등록
          </Text>
          <Divider />
        </Container>
        <Container minW={900}>
          <Stack>
            <HStack>
              <Text fontSize={"2xl"} color={"white"}>
                1. 아이템 이름
              </Text>
              <Text fontSize={"sm"} color={"tomato"}>
                ※ 화면에 보여지는 내용을 입력하세요
              </Text>
            </HStack>
            <Input
              placeholder="예)인버터 제어형 공기압축기"
              backgroundColor={"white"}
              {...register("grant_item_name")}
            />
          </Stack>
          <Box my={"3rem"} />
          <Stack>
            <HStack>
              <Text fontSize={"2xl"} color={"white"}>
                2. 아이템 설명
              </Text>
              <Text fontSize={"sm"} color={"tomato"}>
                ※ 화면에 보여지는 내용을 입력하세요
              </Text>
            </HStack>
            <Textarea
              placeholder="예)인버터 주파수조절을 통해 모터 회전수를 제어하여, 수요에 맞게 압축공기를 생산하는 설비"
              backgroundColor={"white"}
              {...register("item_description")}
            />
          </Stack>
          <Box my={"3rem"} />
          <Button
            isLoading={mutation.isLoading}
            type="submit"
            w="full"
            colorScheme={"red"}
          >
            등록하기
          </Button>
        </Container>
      </VStack>
    </ProtectedPage>
  );
}
