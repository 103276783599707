import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Header from "./Header";
import useUser from "../lib/useUser";
import UnAuthorizedUser from "./UnAuthorizedUser";
import { useQueryClient } from "@tanstack/react-query";
import { kakaoLogin } from "../api";
import { useEffect } from "react";

export default function Root() {
  const { user } = useUser();
  const { search, pathname } = useLocation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirmLogin = async () => {
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code) {
      const status = await kakaoLogin(code);
      if (status === 200) {
        toast({
          status: "success",
          title: "Welcome!",
          position: "bottom-right",
          description: "Happy to have you back!",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/");
      }
    }
  };
  useEffect(() => {
    confirmLogin();
  }, []);

  const isPrintURLRoute =
    pathname.startsWith("/inspections") &&
    pathname !== "/inspections" &&
    pathname.split("/").length === 4;

  return (
    <Box
    // bgGradient="linear(to-r, #2A2B6E, #085C94)"
    >
      <Header />
      {isPrintURLRoute ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          {user?.is_spng ? (
            <>
              <Outlet />
              <ReactQueryDevtools />
            </>
          ) : (
            <>
              <UnAuthorizedUser />
            </>
          )}
        </>
      )}
    </Box>
  );
}
