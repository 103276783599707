import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  NumberInput,
  NumberInputField,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  InputGroup,
  InputRightAddon,
  SliderMark,
  HStack,
  VStack,
  Heading,
  Checkbox,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { IFindGrantsVariables } from "../../api";

export default function EnergyCal({ gridItemColor }: any) {
  //
  //
  //
  const [kWh, setkWh] = useState(0);
  const [tCO2, settCO2] = useState(0);
  const [toe, settoe] = useState(0);
  const [changedBy, setChangedBy] = useState<string | null>(null);

  const kWhHandleChange = (value: any) => {
    setkWh(value);
    setChangedBy("kWh");
  };
  const tCO2HandleChange = (value: any) => {
    settCO2(value);
    setChangedBy("tCO2");
  };
  const toeHandleChange = (value: any) => {
    settoe(value);
    setChangedBy("toe");
  };

  useEffect(() => {
    if (changedBy === "kWh") {
      settCO2(Math.floor((kWh * 0.4747) / 1000));
      settoe(Math.floor((kWh * 0.229) / 1000));
    } else if (changedBy === "tCO2") {
      setkWh(Math.floor((tCO2 * 1000) / 0.4747));
      settoe(Math.floor((tCO2 * 1000 * 0.229) / 0.4747 / 1000));
    } else if (changedBy === "toe") {
      setkWh(Math.floor((toe * 1000) / 0.229));
      settCO2(Math.floor((toe * 1000 * 0.4747) / 0.229 / 1000));
    }
    setChangedBy(null); // 원인 상태 초기화
  }, [kWh, tCO2, toe]);

  // 숫자를 천단위마다 콤마로 포맷팅하는 함수
  // const numberWithCommas = (x: string | number) => {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };

  const getColorBasedOnValue = (value: any) => {
    if (value < 4146)
      return { track: "gray.100", fill: "gray.500", thumb: "gray.500" };
    if (value < 15000)
      return { track: "blue.100", fill: "blue.500", thumb: "blue.500" };
    if (value < 25000)
      return { track: "green.100", fill: "green.500", thumb: "green.500" };
    return { track: "red.100", fill: "tomato", thumb: "tomato" };
  };

  const colors = getColorBasedOnValue(tCO2);

  const { register, watch, setValue } = useFormContext<IFindGrantsVariables>();

  useEffect(() => {
    if (tCO2 < 4146) {
      setValue("energy_size_emission", false);
      setValue("energy_size_goal", false);
      setValue("energy_size_high", false);
      setValue("energy_size_all", true);
    } else if (tCO2 < 15000) {
      setValue("energy_size_emission", false);
      setValue("energy_size_goal", false);
      setValue("energy_size_high", true);
      setValue("energy_size_all", false);
    } else if (tCO2 < 25000) {
      setValue("energy_size_emission", false);
      setValue("energy_size_goal", true);
      setValue("energy_size_high", false);
      setValue("energy_size_all", false);
    } else {
      setValue("energy_size_emission", true);
      setValue("energy_size_goal", false);
      setValue("energy_size_high", false);
      setValue("energy_size_all", false);
      // 다른 경우의 수를 처리합니다.
    }
  }, [tCO2, setValue]);

  return (
    <Box
      p={"3vh"}
      m={"3vh"}
      maxW={"700px"}
      // border={"1px"}
      // borderWidth={"3px"}
      // borderColor={"blue.700"}
      // borderRadius={"30px"}
      shadow={"2xl"}
      bgColor={gridItemColor}
    >
      <Heading py={"3vh"} fontWeight={"thin"} color={"white"}>
        ✔ 에너지 사용량을 입력하십시오.
      </Heading>

      <HStack p={"5"} spacing={"10"}>
        <Slider
          aria-label="tCO2 Slider"
          orientation="vertical"
          value={tCO2}
          max={47470}
          minH="300"
          mr="300px"
          onChange={tCO2HandleChange}
        >
          <SliderMark pl={"5"} color={"blue.500"} value={4146}>
            ◀_Energy_Intensive_at_2,000_TOE
          </SliderMark>
          <SliderMark pl={"5"} color={"green.500"} value={15000}>
            ◀_Target_Management_Entity
          </SliderMark>
          <SliderMark pl={"5"} color={"red.500"} value={25000}>
            ◀_Emission_Trading_Scheme(ETS)Participant
          </SliderMark>
          <SliderTrack borderRadius={"30px"} width={"30px"} bg={colors.track}>
            <SliderFilledTrack bg={colors.fill} />
          </SliderTrack>
          <SliderThumb boxSize={10} boxShadow={"dark-lg"}>
            <Box color={colors.thumb}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                tCO2
              </Text>
            </Box>
          </SliderThumb>
        </Slider>
        <VStack spacing={"10"}>
          <InputGroup>
            <NumberInput
              value={kWh}
              color={"white"}
              // format={numberWithCommas}
              onChange={kWhHandleChange}
            >
              <NumberInputField textAlign="right" />
            </NumberInput>
            <InputRightAddon children="kW/h" />
          </InputGroup>
          <InputGroup>
            <NumberInput
              value={tCO2}
              color={"white"}
              // format={numberWithCommas}
              onChange={tCO2HandleChange}
            >
              <NumberInputField textAlign="right" />
            </NumberInput>
            <InputRightAddon children="tCO2" />
          </InputGroup>
          <InputGroup>
            <NumberInput
              value={toe}
              color={"white"}
              // format={numberWithCommas}
              onChange={toeHandleChange}
            >
              <NumberInputField textAlign="right" />
            </NumberInput>
            <InputRightAddon children="TOE" />
          </InputGroup>
          <Checkbox hidden {...register("energy_size_emission")}>
            emission
          </Checkbox>
          <Checkbox hidden {...register("energy_size_goal")}>
            goal
          </Checkbox>
          <Checkbox hidden {...register("energy_size_high")}>
            high
          </Checkbox>
          <Checkbox hidden {...register("energy_size_all")}>
            전체
          </Checkbox>
        </VStack>
      </HStack>
    </Box>
  );
}
