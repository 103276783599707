import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { ChangeEvent, useReducer, useState } from "react";
import { MdFactory } from "react-icons/md";
import { getGItems, getOrganizers, uploadGrantDetail } from "../../api";
import { IGItem, IGrantDetail, IOrganizer } from "../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApplyReg from "./NewBusinessGrantApplyReg";
import GrantItemReg from "./NewBusinessGrantItemReg";

// .########.##.....##.##....##..######..########.####..#######..##....##
// .##.......##.....##.###...##.##....##....##.....##..##.....##.###...##
// .##.......##.....##.####..##.##..........##.....##..##.....##.####..##
// .######...##.....##.##.##.##.##..........##.....##..##.....##.##.##.##
// .##.......##.....##.##..####.##..........##.....##..##.....##.##..####
// .##.......##.....##.##...###.##....##....##.....##..##.....##.##...###
// .##........#######..##....##..######.....##....####..#######..##....##

export default function GrantReg() {
  //
  // ..######...########.########.....#######..##.....##.########.########..##....##..######.
  // .##....##..##..........##.......##.....##.##.....##.##.......##.....##..##..##..##....##
  // .##........##..........##.......##.....##.##.....##.##.......##.....##...####...##......
  // .##...####.######......##.......##.....##.##.....##.######...########.....##.....######.
  // .##....##..##..........##.......##..##.##.##.....##.##.......##...##......##..........##
  // .##....##..##..........##.......##....##..##.....##.##.......##....##.....##....##....##
  // ..######...########....##........#####.##..#######..########.##.....##....##.....######.
  const { data: organizersData } = useQuery<IOrganizer[]>(
    ["organizers"],
    getOrganizers
  );

  //
  // .########.####.########.##.......########.....###....########..########.....###....##....##
  // .##........##..##.......##.......##.....##...##.##...##.....##.##.....##...##.##....##..##.
  // .##........##..##.......##.......##.....##..##...##..##.....##.##.....##..##...##....####..
  // .######....##..######...##.......##.....##.##.....##.########..########..##.....##....##...
  // .##........##..##.......##.......##.....##.#########.##...##...##...##...#########....##...
  // .##........##..##.......##.......##.....##.##.....##.##....##..##....##..##.....##....##...
  // .##.......####.########.########.########..##.....##.##.....##.##.....##.##.....##....##...
  //
  const methods = useForm<IGrantDetail>();
  const { register, control, handleSubmit, reset, trigger, setError, watch } =
    methods;

  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation(uploadGrantDetail, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Report created",
        position: "bottom-right",
      });
      // navigate(-1);
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        description: error.response.data.detail,
        position: "bottom",
      });
    },
  });

  const onSubmit = (data: IGrantDetail) => {
    mutation.mutate(data);
  };

  console.log(watch());
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // .########..########.########.##.....##.########..##....##
  // .##.....##.##..........##....##.....##.##.....##.###...##
  // .##.....##.##..........##....##.....##.##.....##.####..##
  // .########..######......##....##.....##.########..##.##.##
  // .##...##...##..........##....##.....##.##...##...##..####
  // .##....##..##..........##....##.....##.##....##..##...###
  // .##.....##.########....##.....#######..##.....##.##....##
  //

  return (
    <ProtectedPage>
      <FormProvider {...methods}>
        <VStack
          marginTop={-5}
          h={"100%"}
          bgGradient="linear(to-r, #2A2B6E, #085C94)"
          padding={"3rem"}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container minW={900} px={3} paddingBottom="50px">
            <Text
              py={3}
              color={"white"}
              fontSize={"2xl"}
              fontWeight="extrabold"
              align={"center"}
            >
              지원사업 등록 시스템
            </Text>
            <Divider />
          </Container>
          {/* //
        ..######..########..######..########.####..#######..##....##.......##..
        .##....##.##.......##....##....##.....##..##.....##.###...##.....####..
        .##.......##.......##..........##.....##..##.....##.####..##.......##..
        ..######..######...##..........##.....##..##.....##.##.##.##.......##..
        .......##.##.......##..........##.....##..##.....##.##..####.......##..
        .##....##.##.......##....##....##.....##..##.....##.##...###.......##..
        ..######..########..######.....##....####..#######..##....##.....######
        // */}
          <Container minW={900}>
            <HStack>
              <Text marginBottom={"1rem"} fontSize={"2xl"} color={"white"}>
                1. 공단 혹은 지자체 선택
              </Text>
              <Text fontSize={"sm"} color={"yellow"}>
                ※ 선택지에 대상이 없는경우 강석원 팀장에게 문의 바랍니다.
              </Text>
            </HStack>
            <Select
              p={"2rem"}
              backgroundColor={"white"}
              placeholder={"주관처를 선택하십시오!"}
              {...register("organizer")}
            >
              {organizersData?.map((organizer) => (
                <option key={organizer.id} value={organizer.id}>
                  {organizer.organizer_name}
                </option>
              ))}
            </Select>
            <Divider my={"3rem"} />
            {/* //
          ..######..########..######..########.####..#######..##....##.....#######.
          .##....##.##.......##....##....##.....##..##.....##.###...##....##.....##
          .##.......##.......##..........##.....##..##.....##.####..##...........##
          ..######..######...##..........##.....##..##.....##.##.##.##.....#######.
          .......##.##.......##..........##.....##..##.....##.##..####....##.......
          .##....##.##.......##....##....##.....##..##.....##.##...###....##.......
          ..######..########..######.....##....####..#######..##....##....#########
          // */}
            <Stack>
              <HStack>
                <Text fontSize={"2xl"} color={"white"}>
                  2. 사업 기본정보
                </Text>
                <Text fontSize={"sm"} color={"tomato"}>
                  ※ 화면에 보여지는 내용을 입력하세요
                </Text>
              </HStack>
              <Stack p={"2rem"} gap={5}>
                <HStack>
                  <Text minW={120} fontSize={"md"} color={"white"}>
                    1) 사업명
                  </Text>
                  <Input
                    backgroundColor={"white"}
                    {...register("grant_name")}
                  ></Input>
                </HStack>
                <HStack>
                  <Text minW={120} fontSize={"md"} color={"white"}>
                    2) 사업기준년도
                  </Text>
                  <NumberInput
                    borderRadius={"md"}
                    maxW={200}
                    backgroundColor={"white"}
                  >
                    <NumberInputField {...register("base_year")} />
                  </NumberInput>
                  <Text fontSize={"sm"} color={"yellow"}>
                    ※ 정수로 입력해 주세요!
                  </Text>
                </HStack>
                <HStack>
                  <Text minW={120} fontSize={"md"} color={"white"}>
                    3) 사업공고일
                  </Text>
                  <Input
                    maxW={200}
                    backgroundColor={"white"}
                    type={"date"}
                    {...register("announcement_date")}
                  ></Input>
                  <Text fontSize={"sm"} color={"yellow"}>
                    ※ 날짜를 선택하거나 직접 입력해 주세요!
                  </Text>
                </HStack>
                <HStack>
                  <Text minW={120} fontSize={"md"} color={"white"}>
                    4) 회차
                  </Text>
                  <NumberInput
                    borderRadius={"md"}
                    maxW={200}
                    backgroundColor={"white"}
                  >
                    <NumberInputField {...register("announcement")} />
                  </NumberInput>
                  <Text fontSize={"sm"} color={"yellow"}>
                    ※ 정수로 입력해 주세요!
                  </Text>
                </HStack>
              </Stack>
            </Stack>
            <Divider my={"3rem"} />
            {/* //
          ..######..########..######..########.####..#######..##....##.....#######.
          .##....##.##.......##....##....##.....##..##.....##.###...##....##.....##
          .##.......##.......##..........##.....##..##.....##.####..##...........##
          ..######..######...##..........##.....##..##.....##.##.##.##.....#######.
          .......##.##.......##..........##.....##..##.....##.##..####...........##
          .##....##.##.......##....##....##.....##..##.....##.##...###....##.....##
          ..######..########..######.....##....####..#######..##....##.....#######.
          // */}
            <Stack>
              <HStack>
                <Text fontSize={"2xl"} color={"white"}>
                  3. 사업 개요
                </Text>
                <Text fontSize={"sm"} color={"tomato"}>
                  ※ 화면에 보여지는 내용을 입력하세요
                </Text>
              </HStack>
              <Box p={"2rem"}>
                <Textarea
                  backgroundColor={"white"}
                  {...register("business_overview")}
                />
              </Box>
            </Stack>
            <Divider my={"3rem"} />
            {/* //
          ..######..########..######..########.####..#######..##....##....##.......
          .##....##.##.......##....##....##.....##..##.....##.###...##....##....##.
          .##.......##.......##..........##.....##..##.....##.####..##....##....##.
          ..######..######...##..........##.....##..##.....##.##.##.##....##....##.
          .......##.##.......##..........##.....##..##.....##.##..####....#########
          .##....##.##.......##....##....##.....##..##.....##.##...###..........##.
          ..######..########..######.....##....####..#######..##....##..........##.
          // */}
            <Stack>
              <Text fontSize={"2xl"} color={"white"}>
                4. 사업 내용
              </Text>
              <Stack p={"2rem"} gap={"2rem"}>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      1) 지원 내용
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 화면에 보여지는 내용을 입력하세요
                    </Text>
                  </HStack>
                  <Textarea
                    backgroundColor={"white"}
                    {...register("support_details")}
                  />
                </VStack>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      2) 지원 대상
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 화면에 보여지는 내용을 입력하세요
                    </Text>
                  </HStack>
                  <Textarea
                    backgroundColor={"white"}
                    {...register("support_target")}
                  />
                </VStack>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      ※ 지원 대상을 체크해 주세요!
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 체크하지 않으면 조회가 되지 않습니다.
                    </Text>
                  </HStack>
                  <VStack align={"start"} gap={"1rem"} p={"1rem"}>
                    <VStack align={"start"}>
                      <Button
                        leftIcon={<MdFactory />}
                        colorScheme="orange"
                        variant="solid"
                        isDisabled
                      >
                        기업 규모를 선택해 주세요!!!
                      </Button>
                      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("company_size_all")}
                        >
                          전체
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("company_size_small")}
                        >
                          중소기업
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("company_size_mid")}
                        >
                          중견기업
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("company_size_major")}
                        >
                          대기업
                        </Checkbox>
                      </Grid>
                    </VStack>
                    <VStack align={"start"}>
                      <Button
                        leftIcon={<MdFactory />}
                        colorScheme="green"
                        variant="solid"
                        isDisabled
                      >
                        에너지 사용 규모를 선택해 주세요!!!
                      </Button>
                      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("energy_size_all")}
                        >
                          전체
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("energy_size_high")}
                        >
                          에너지다소비
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("energy_size_goal")}
                        >
                          목표관리
                        </Checkbox>
                        <Checkbox
                          size="lg"
                          ml={3}
                          color={"white"}
                          {...register("energy_size_emission")}
                        >
                          배출권할당
                        </Checkbox>
                      </Grid>
                    </VStack>
                  </VStack>
                </VStack>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      3) 지원 예산 (사업의 전체예산)
                    </Text>
                    <Text fontSize={"sm"} color={"yellow"}>
                      ※ 단위에 맞게 숫자로만 입력하세요
                    </Text>
                  </HStack>
                  <InputGroup>
                    <NumberInput>
                      <NumberInputField
                        backgroundColor={"white"}
                        {...register("budget")}
                      />
                    </NumberInput>
                    <InputRightAddon children="억원" />
                  </InputGroup>
                </VStack>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      4) 지원 금액 및 비율
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 화면에 보여지는 내용을 입력하세요
                    </Text>
                  </HStack>
                  <Textarea
                    backgroundColor={"white"}
                    {...register("support_ratio_amount")}
                  />
                </VStack>
              </Stack>
            </Stack>
            <Divider my={"3rem"} />
            {/* //
          ..######..########..######..########.####..#######..##....##....########
          .##....##.##.......##....##....##.....##..##.....##.###...##....##......
          .##.......##.......##..........##.....##..##.....##.####..##....##......
          ..######..######...##..........##.....##..##.....##.##.##.##....#######.
          .......##.##.......##..........##.....##..##.....##.##..####..........##
          .##....##.##.......##....##....##.....##..##.....##.##...###....##....##
          ..######..########..######.....##....####..#######..##....##.....######.
          // */}
            <Stack>
              <Text fontSize={"2xl"} color={"white"}>
                5. 사업 신청 및 일정
              </Text>
              <Stack p={"2rem"} gap={"2rem"}>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      1) 신청 일정
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 날짜와 시간 선택하거나 입력 하십시오.
                    </Text>
                  </HStack>
                  <InputGroup>
                    <InputLeftAddon>시작일</InputLeftAddon>
                    <Input
                      marginRight={"1rem"}
                      type="datetime-local"
                      backgroundColor={"white"}
                      {...register("submit_start_date")}
                    />
                    <InputLeftAddon>종료일</InputLeftAddon>
                    <Input
                      type="datetime-local"
                      backgroundColor={"white"}
                      {...register("submit_end_date")}
                    />
                  </InputGroup>
                </VStack>
                <VStack align={"start"}>
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      2) 사업 기간
                    </Text>
                    <Text fontSize={"sm"} color={"tomato"}>
                      ※ 날짜를 선택하거나 입력 하십시오.
                    </Text>
                  </HStack>
                  <InputGroup>
                    <InputLeftAddon>시작일</InputLeftAddon>
                    <Input
                      marginRight={"1rem"}
                      type="date"
                      backgroundColor={"white"}
                      {...register("business_start_date")}
                    />
                    <InputLeftAddon>종료일</InputLeftAddon>
                    <Input
                      type="date"
                      backgroundColor={"white"}
                      {...register("business_end_date")}
                    />
                  </InputGroup>
                </VStack>
                <VStack align="start">
                  <HStack>
                    <Text fontSize={"lg"} color={"white"}>
                      3) 사업 절차
                    </Text>
                    <Text fontSize={"sm"} color={"yellow"}>
                      ※ 첫 단계부터 입력 후 추가 버튼을 클릭하세요
                    </Text>
                  </HStack>
                  <ApplyReg />
                </VStack>
              </Stack>
            </Stack>
            <Divider my={"3rem"} />
            {/* //
          ..######..########..######..########.####..#######..##....##.....#######.
          .##....##.##.......##....##....##.....##..##.....##.###...##....##.....##
          .##.......##.......##..........##.....##..##.....##.####..##....##.......
          ..######..######...##..........##.....##..##.....##.##.##.##....########.
          .......##.##.......##..........##.....##..##.....##.##..####....##.....##
          .##....##.##.......##....##....##.....##..##.....##.##...###....##.....##
          ..######..########..######.....##....####..#######..##....##.....#######.
          // */}
            <Stack>
              <Text fontSize={"2xl"} color={"white"}>
                6. 사업 아이템 등록
              </Text>
              <GrantItemReg />
            </Stack>
            <Divider my={"3rem"} />
            {/* //
          ..######..##.....##.########..##.....##.####.########....########..##.....##.########.########..#######..##....##
          .##....##.##.....##.##.....##.###...###..##.....##.......##.....##.##.....##....##.......##....##.....##.###...##
          .##.......##.....##.##.....##.####.####..##.....##.......##.....##.##.....##....##.......##....##.....##.####..##
          ..######..##.....##.########..##.###.##..##.....##.......########..##.....##....##.......##....##.....##.##.##.##
          .......##.##.....##.##.....##.##.....##..##.....##.......##.....##.##.....##....##.......##....##.....##.##..####
          .##....##.##.....##.##.....##.##.....##..##.....##.......##.....##.##.....##....##.......##....##.....##.##...###
          ..######...#######..########..##.....##.####....##.......########...#######.....##.......##.....#######..##....##
          // */}
            <Button
              minW={900}
              backgroundColor={"red"}
              color={"white"}
              type="submit"
              isLoading={mutation.isLoading}
            >
              저장
            </Button>
          </Container>
        </VStack>
      </FormProvider>
    </ProtectedPage>
  );
}
