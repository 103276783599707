import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ICompanyStatus, ICustomer, IExecutiveStatus } from "../../types";
import { getCompanyStatus, getCustomer, getExecutiveStatus } from "../../api";
import ExecutiveStatus from "../ExecutiveStatus";
import { Grid, Skeleton } from "@chakra-ui/react";

export default function CustomerDetail() {
  const { customerPk } = useParams();
  const { data: customerData } = useQuery<ICustomer>(
    [`customers`, customerPk],
    getCustomer
  );

  const {
    isLoading,
    data: executivestatusData,
    error: executivestatuserror,
  } = useQuery<IExecutiveStatus>(
    [`customers`, customerPk, `executivestatus`],
    getExecutiveStatus
  );

  const { data: companystatusData, error: companystatuserror } =
    useQuery<ICompanyStatus>(
      [`customers`, customerPk, `companystatus`],
      getCompanyStatus
    );

  console.log(executivestatusData?.list);

  return (
    <div>
      <h1>고객 상세 정보</h1>
      <p>Dart Code : {companystatusData?.corp_code}</p>
      <p>정식명칭: {companystatusData?.corp_name}</p>
      <p>영문명칭: {companystatusData?.corp_name_eng}</p>
      <p>
        종목명(상장사) 또는 약식명칭(기타법인): {companystatusData?.stock_name}
      </p>
      <p>상장회사인 경우 주식의 종목코드: {companystatusData?.stock_code}</p>
      <p>대표자명: {companystatusData?.ceo_nm}</p>
      <p>법인구분: {companystatusData?.corp_cls}</p>
      <p>※ Y(유가), K(코스닥), N(코넥스), E(기타)</p>
      <p>법인등록번호: {companystatusData?.jurir_no}</p>
      <p>사업자등록번호: {companystatusData?.bizr_no}</p>
      <p>주소: {companystatusData?.adres}</p>
      <p>홈페이지: {companystatusData?.hm_url}</p>
      <p>IR홈페이지: {companystatusData?.ir_url}</p>
      <p>전화번호: {companystatusData?.phn_no}</p>
      <p>팩스번호: {companystatusData?.fax_no}</p>
      <p>업종코드: {companystatusData?.induty_code}</p>
      <p>설립일: {companystatusData?.est_dt}</p>
      <p>결산월: {companystatusData?.acc_mt}</p>
      <h1>임원 정보</h1>
      <Grid gap={1}>
        {isLoading ? (
          <>
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
            <Skeleton rounded="2xl" h={70} mb={3} />
          </>
        ) : null}
        {executivestatusData?.list?.map((executivestatus) => (
          <ExecutiveStatus
            key={executivestatus.rcept_no}
            rcept_no={executivestatus.rcept_no}
            corp_cls={executivestatus.corp_cls}
            corp_code={executivestatus.corp_code}
            corp_name={executivestatus.corp_name}
            nm={executivestatus.nm}
            sexdstn={executivestatus.sexdstn}
            birth_ym={executivestatus.birth_ym}
            ofcps={executivestatus.ofcps}
            rgist_exctv_at={executivestatus.rgist_exctv_at}
            fte_at={executivestatus.fte_at}
            chrg_job={executivestatus.chrg_job}
            main_career={executivestatus.main_career}
            mxmm_shrholdr_relate={executivestatus.mxmm_shrholdr_relate}
            hffc_pd={executivestatus.hffc_pd}
            tenure_end_on={executivestatus.tenure_end_on}
          />
        ))}
      </Grid>
    </div>
  );
}
