import {
  Button,
  Container,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import download from "downloadjs";
import { useEffect, useRef, useState } from "react";
import { EditorRef, EmailEditor } from "react-email-editor";
import useManagerOnlyPage from "../ManagerOnlyPage";
import ProtectedPage from "../ProtectedPage";

export default function MakeEmail() {
  const editorRef = useRef<EditorRef>(null);
  const [designName, setDesignName] = useState("");
  const [designList, setDesignList] = useState<string[]>([]);
  const {
    isOpen: isOpenSave,
    onOpen: onOpenSave,
    onClose: onCloseSave,
  } = useDisclosure();
  const {
    isOpen: isOpenLoad,
    onOpen: onOpenLoad,
    onClose: onCloseLoad,
  } = useDisclosure();
  const {
    isOpen: isOpenExport,
    onOpen: onOpenExport,
    onClose: onCloseExport,
  } = useDisclosure();

  const handleSaveOpen = () => {
    onOpenSave();
  };

  const handleLoadOpen = () => {
    onOpenLoad();
  };

  const handleSave = () => {
    editorRef.current?.saveDesign((design) => {
      localStorage.setItem(designName, JSON.stringify(design));
      console.log("Design JSON", design);
      onCloseSave();
      setDesignList((prevDesignList) =>
        prevDesignList.includes(designName)
          ? prevDesignList
          : [...prevDesignList, designName]
      );
    });
  };

  const handleLoad = () => {
    const designJson = localStorage.getItem(designName);
    if (designJson) {
      const design = JSON.parse(designJson);
      editorRef.current?.loadDesign(design);
    }
    onCloseLoad();
  };
  const handleExportHtml = () => {
    editorRef.current?.exportHtml((data) => {
      download(data.html, "email.html", "text/html"); // HTML 파일 다운로드
    });
  };

  useEffect(() => {
    const designs = Object.keys(localStorage);
    setDesignList(designs.filter((d) => d !== "chakra-ui-color-mode"));
  }, []);

  useManagerOnlyPage();

  return (
    <ProtectedPage>
      <HStack>
        <Button onClick={handleSaveOpen}>Save</Button>
        <Button onClick={handleLoadOpen}>Load</Button>
        <Button onClick={handleExportHtml}>Export HTML</Button>
      </HStack>
      <Flex h="85vh">
        <EmailEditor ref={editorRef} />
      </Flex>

      <Modal isOpen={isOpenSave} onClose={onCloseSave}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Design</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Design name"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={onCloseSave}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenLoad} onClose={onCloseLoad}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Load Design</ModalHeader>
          <ModalBody>
            <Select
              placeholder="Select Design"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
            >
              {designList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleLoad}>Load</Button>
            <Button onClick={onCloseLoad}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ProtectedPage>
  );
}
