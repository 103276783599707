import { useInfiniteQuery } from "@tanstack/react-query";
import { getGridFanMotor, getGridPart, getGridScrew } from "../../api";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Parts from "./Parts";
import { ICompressors } from "../../types";

interface FieldValues {
  pk: number;
}

export default function GridFanMotor() {
  //
  const cololMode = useColorModeValue("green.400", "white");
  //
  //
  //
  //
  //
  // 수정전 + chatGPT 코드 활용  아래는 성공한 코드이므로 건드리지 말것.
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(["gridfanmotor"], getGridFanMotor, {
      getNextPageParam: (lastpage) =>
        lastpage && lastpage.next ? lastpage.next.split("=")[1] : null,
    });
  //
  //
  //
  //
  //
  //
  //
  //
  //  chatGPT 가 수정해준 코드. 일단 작동은함.
  //
  // const { isLoading, data, fetchNextPage, hasNextPage } = useInfiniteQuery(
  //   ["gridscrew"],
  //   ({ pageParam = 1 }) => getGridScrew({ pageParam }),
  //   {
  //     getNextPageParam: (lastpage) => lastpage.next.split("=")[1],
  //   }
  // );
  // console.log(data);
  //
  //
  //
  //
  //
  // 수정전 코드!!!!!!!!!!!!!

  //
  //
  //
  //
  //
  const methods = useForm<FieldValues>();
  const { handleSubmit, register, watch } = methods;
  const onSubmit: SubmitHandler<FieldValues> = (data) => console.log(data);
  // console.log(data);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid
          mt={3}
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
            "2xl": "repeat(1, 1fr)",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
              <Skeleton rounded="2xl" h={70} mb={3} />
            </>
          ) : null}
          {data?.pages.map((page, next) => (
            <React.Fragment key={next}>
              {page.results.map((compressor: ICompressors) => (
                <Parts
                  key={compressor.pk}
                  pk={compressor.pk}
                  imageUrl={compressor.company.customer_ci_photo?.file}
                  name={compressor.company.name}
                  model_name={compressor.model_name?.part_name || "없음"}
                  serial_number={compressor.serial_number}
                  nick_name={compressor.nick_name}
                  percentage_c_part_exchange={
                    compressor.percentage_c_part_exchange
                  }
                  percentage_screw_overhaul={
                    compressor.percentage_screw_overhaul
                  }
                  percentage_main_motor_repair={
                    compressor.percentage_main_motor_repair
                  }
                  percentage_fan_motor_repair={
                    compressor.percentage_fan_motor_repair
                  }
                  percentage_invertor_overhaul={
                    compressor.percentage_invertor_overhaul
                  }
                  percentage_cooler_cleaning={
                    compressor.percentage_cooler_cleaning
                  }
                  check_email={compressor.check_email}
                />
              ))}
            </React.Fragment>
          ))}
        </Grid>
        <Box position="fixed" bottom="10px" right="10px">
          <Button
            colorScheme="linkedin"
            variant="solid"
            onClick={() => fetchNextPage()}
          >
            Load More!!!
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
