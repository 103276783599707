import { PayloadAction, configureStore, createSlice } from "@reduxjs/toolkit";
import { StepData } from "../types";

const today = new Date().toISOString().substr(0, 10); // 현재 날짜를 가져옴 (YYYY-MM-DD 형식)

const initialState: StepData = {
  step1: {
    id: "",
    company_employees: [],
    sales_man: [],
    sales_manager: [],
    servie_sales_man: [],
    service_engineer_man: [],
    servie_manager: [],
    developer: [],
    name: "",
    ceo: "",
    address: "",
    sales_amount: 0,
    credit_rating: "",
    company_size: "",
    working_hours: 0,
    working_days: 0,
    tel: "",
    tax_id: "",
  },
  step2: {
    sector: "",
    main_products: "",
    used_air: "",
    total_annual_power_cost: 0,
    energy_size: "",
    kw_cost: 0,
    kepco_number: "",
    kepco_power_planer_id: "",
    kepco_power_planer_pass: "",
  },
  step3: {
    group_company: "",
    factory: "",
    major_sales: "",
    major_purchases: "",
    factories: [],
    group_companies: [],
    sales_to: [],
    purchases_from: [],
  },
  step4: {
    customer_employees: [],
  },
  step5: {
    applicable_item: [],
  },
  step6: {
    proposed_grant: [],
  },
  step7: {
    compressors_companys: [],
  },
  step8: {
    air_tanks: "",
    refrigerated_dryers: "",
    desiccant_dryers: "",
    after_cooler: false,
    oil_water_separator: false,
    auto_trap: false,
    representative_opinion: "",
  },
  step9: {
    current_service_provider: "",
    is_genuine: "",
    exchange_filters: "",
    exchange_oils: "",
  },
  step10: {
    hot_water: false,
    heating: false,
    process_use: false,
    use_electricity: false,
    use_lpg: false,
    use_oil: false,
    other_load_machines: "",
  },
  step11: {
    environments: [],
  },
  step12: {
    price_sensitivity: 0,
    genuine_preference: 0,
    importance_technical: 0,
    importance_facility_management: 0,
    relationship_preferences: 0,
    importance_relationship: 0,
  },
  step13: {
    direct_export: false,
    indirect_export: false,
    export_country: "",
    export_customer: "",
    large_company_primary_vendor: false,
    large_company_secondary_vendor: false,
    large_company_name: "",
    large_company_annual_sales_amount: 0,
    required_sustainability_report: false,
    required_iso50001_certification: false,
    required_company_name: "",
    company_analysis_date: today,
  },
  step14: {
    facility: [],
  },
  step15: {
    energy: [],
  },
  // 추가
};

const companyAnalysissSlice = createSlice({
  name: "companyAnalysis",
  initialState,
  reducers: {
    updateStep1: (state, action: PayloadAction<StepData["step1"]>) => {
      state.step1 = action.payload;
    },
    updateStep2: (state, action: PayloadAction<StepData["step2"]>) => {
      state.step2 = action.payload;
    },
    updateStep3: (state, action: PayloadAction<StepData["step3"]>) => {
      state.step3 = action.payload;
    },
    updateStep4: (state, action: PayloadAction<StepData["step4"]>) => {
      state.step4 = action.payload;
    },
    updateStep5: (state, action: PayloadAction<StepData["step5"]>) => {
      state.step5 = action.payload;
    },
    updateStep6: (state, action: PayloadAction<StepData["step6"]>) => {
      state.step6 = action.payload;
    },
    updateStep7: (state, action: PayloadAction<StepData["step7"]>) => {
      state.step7 = action.payload;
    },
    updateStep8: (state, action: PayloadAction<StepData["step8"]>) => {
      state.step8 = action.payload;
    },
    updateStep9: (state, action: PayloadAction<StepData["step9"]>) => {
      state.step9 = action.payload;
    },
    updateStep10: (state, action: PayloadAction<StepData["step10"]>) => {
      state.step10 = action.payload;
    },
    updateStep11: (state, action: PayloadAction<StepData["step11"]>) => {
      state.step11 = action.payload;
    },
    updateStep12: (state, action: PayloadAction<StepData["step12"]>) => {
      state.step12 = action.payload;
    },
    updateStep13: (state, action: PayloadAction<StepData["step13"]>) => {
      state.step13 = action.payload;
    },
    updateStep14: (state, action: PayloadAction<StepData["step14"]>) => {
      state.step14 = action.payload;
    },
    updateStep15: (state, action: PayloadAction<StepData["step15"]>) => {
      state.step15 = action.payload;
    },
    updateAllSteps: (state, action: PayloadAction<StepData>) => {
      return action.payload; // 전체 스텝 데이터로 상태 업데이트
    },
    resetStore: () => initialState,
    // 스텝 추가
  },
});

export const {
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
  updateStep5,
  updateStep6,
  updateStep7,
  updateStep8,
  updateStep9,
  updateStep10,
  updateStep11,
  updateStep12,
  updateStep13,
  updateStep14,
  updateStep15,
  updateAllSteps,
  resetStore,
} = companyAnalysissSlice.actions;

// export default configureStore({ reducer: companyAnalysissSlice.reducer });

const store = configureStore({ reducer: companyAnalysissSlice.reducer });

export type RootState = ReturnType<typeof store.getState>;

export default store;
// export default store;
