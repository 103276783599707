import { Box, Button } from "@chakra-ui/react";
import useUser from "../../lib/useUser";
import ProtectedPage from "../ProtectedPage";
import GrantsSearch from "./SalesGrantsSearch";
import IntOnestop from "./SalesIntOnestop";
import { Link, Outlet } from "react-router-dom";

export default function SalesHome() {
  const { userLoading, isLoggedIn, user } = useUser();

  return (
    <ProtectedPage>
      <Box
        marginTop={-5}
        h={"100vh"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
      >
        {/* <Link to={`/customers/searchcustomer`}>
          <Button margin={"1rem"} shadow={"dark-lg"}>
            고객 조회
          </Button>
        </Link> */}
        <Link to={`/sales/company-analysis-list`}>
          <Button margin={"1rem"} shadow={"dark-lg"}>
            관리업체분석표 조회
          </Button>
        </Link>

        <Link to={`/sales/company-analysis-router`}>
          <Button
            margin={"1rem"}
            shadow={"dark-lg"}
            bgColor={"tomato"}
            color={"white"}
          >
            고객분석표 신규등록
          </Button>
        </Link>

        <Link to={`presentation`}>
          <Button margin={"1rem"} shadow={"dark-lg"}>
            Presentation
          </Button>
        </Link>
      </Box>
      <Outlet />
    </ProtectedPage>
  );
}
