import { useNavigate, useParams } from "react-router-dom";
import ProtectedPage from "../ProtectedPage";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Text,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStep14 } from "../SalesCompanyAnalysisRegStore";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ICompanyAnalsys, StepData } from "../../types";
import { useRef, useState } from "react";

export default function CompanyAnalysisRegStep14() {
  const { companyPk } = useParams();
  const step14Data = useSelector((state: RootState) => state.step14);
  const { control, handleSubmit, register, watch } = useForm<ICompanyAnalsys>({
    defaultValues: step14Data,
  });
  console.log(watch());

  const { fields, append, remove } = useFieldArray({
    control,
    name: "facility", // 폼 필드 배열의 이름
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(); // 현재 선택된 항목의 인덱스를 저장

  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleDelete = (index: any) => {
    setCurrentIndex(index); // 삭제할 항목의 인덱스를 저장
    onOpen(); // 대화 상자를 엽니다
  };

  const confirmDelete = () => {
    remove(currentIndex); // 실제로 항목을 삭제합니다
    onClose(); // 대화 상자를 닫습니다
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data: StepData["step14"]) => {
    dispatch(updateStep14(data)); // Redux 액션 디스패치
    // 다음 페이지로 이동 또는 이전 페이지로 이동하는 로직은 여기에 포함시키지 않습니다.
  };

  const submitHandler = handleSubmit(onSubmit);

  const goBack = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step13`); // 다음 페이지로 이동
    // navigate(-1); // 이전 페이지로 이동
  };

  const goForward = () => {
    submitHandler();
    navigate(`/sales/company-analysis-router/step15`); // 다음 페이지로 이동
  };
  const goFinalStep = () => {
    submitHandler();
    navigate(`/sales/company-analysis/${companyPk}/edit-stepfinal`); // 다음 페이지로 이동
  };

  return (
    <ProtectedPage>
      <VStack
        marginTop={-5}
        h={"100%"}
        w={"100%"}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        padding={"3rem"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          my={5}
          w={"100%"}
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          color={"white"}
          fontSize={"xl"}
          fontWeight={"bold"}
          shadow={"dark-lg"}
        >
          #14 부하기기조사
        </Button>
        <Box py={5} />
        <Button
          colorScheme="green"
          w={"100%"}
          onClick={() =>
            append({
              facility_type: "",
              facility_name: "",
              facility_capacity: 0,
              facility_capacity_unit: "",
              facility_energy_source: "",
              total_annual_energy_usage: 0,
            })
          }
        >
          + 부하기기 추가
        </Button>
        <Grid
          columnGap={3}
          rowGap={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
        >
          {fields.map((field, index) => (
            <GridItem key={field.id}>
              <Controller
                control={control}
                name={`facility.${index}`}
                render={({ field }) => (
                  <>
                    <Container justifyContent={"center"} alignItems={"center"}>
                      <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                          <AccordionButton boxShadow="dark-lg">
                            <Box
                              as="b"
                              flex="1"
                              textAlign="center"
                              color={"white"}
                            >
                              <Text>부하기기 #{index + 1}</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="설비구분" />
                                <Select
                                  color={"tomato"}
                                  placeholder="Choose a kind"
                                  {...register(
                                    `facility.${index}.facility_type` as const
                                  )}
                                >
                                  <option value="production">생산설비</option>
                                  <option value="utility">비생산설비</option>
                                </Select>
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="설비명" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `facility.${index}.facility_name` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="설비용량" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `facility.${index}.facility_capacity` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="용량단위" />
                                <Input
                                  color={"white"}
                                  type="text"
                                  {...register(
                                    `facility.${index}.facility_capacity_unit` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="사용에너지" />
                                <Select
                                  color={"tomato"}
                                  placeholder="Choose a kind"
                                  {...register(
                                    `facility.${index}.facility_energy_source` as const
                                  )}
                                >
                                  <option value="electricity">전기</option>
                                  <option value="gas">가스</option>
                                  <option value="ets">기타</option>
                                </Select>
                              </InputGroup>
                            </FormControl>

                            <FormControl my={5}>
                              <InputGroup>
                                <InputLeftAddon children="에너지사용량" />
                                <Input
                                  color={"white"}
                                  type="number"
                                  {...register(
                                    `facility.${index}.total_annual_energy_usage` as const
                                  )}
                                />
                              </InputGroup>
                            </FormControl>
                            <Button
                              colorScheme="red"
                              w={"100%"}
                              onClick={() => handleDelete(index)}
                            >
                              삭 제
                            </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Container>
                  </>
                )}
              />
            </GridItem>
          ))}
        </Grid>
        <Box py={3} />
        <Box py={5} />
        {companyPk ? (
          <>
            <Button
              fontSize={"2xl"}
              w={"full"}
              colorScheme="blackAlpha"
              shadow={"2xl"}
              type="submit"
              onClick={goFinalStep}
            >
              수정사항 업데이트 하기
            </Button>
          </>
        ) : (
          <>
            <Button
              colorScheme="blue"
              shadow={"dark-lg"}
              w={"full"}
              onClick={goBack}
              type="submit"
            >
              이전페이지
            </Button>
            <Button
              colorScheme="red"
              shadow={"dark-lg"}
              w={"full"}
              type="submit"
              onClick={goForward}
            >
              다음페이지
            </Button>
          </>
        )}
      </VStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              항목 삭제
            </AlertDialogHeader>

            <AlertDialogBody>
              이 항목을 정말로 삭제하시겠습니까?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                삭제
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ProtectedPage>
  );
}
