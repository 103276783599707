import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Skeleton,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import EnergyCal from "./EnergyCal";
import GrantProgramList from "./SalesGrantProgramList";
import { FormProvider, useForm } from "react-hook-form";
import { IFindGrantsVariables, getSearchGrants } from "../../api";
import ProtectedPage from "../ProtectedPage";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { IGrantList } from "../../types";
import { motion } from "framer-motion";

export default function GrantsSearch() {
  //
  //
  const gridItemColor = "blue.900";
  //
  const methods = useForm<IFindGrantsVariables>();
  const { handleSubmit, register, watch } = methods;

  const [skeletonLoading, setSkeletonLoading] = useState(false);

  const toast = useToast();
  const mutation = useMutation(getSearchGrants, {
    onMutate: () => {
      setSkeletonLoading(true);
    },
    onSuccess: (data) => {
      setTimeout(() => {
        setSkeletonLoading(false);
      }, 1000);
      setGrantData(data);
      toast({
        status: "success",
        title: "Submit Success",
        position: "bottom-left",
      });
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        position: "bottom-left",
      });
    },
  });

  const { isLoading } = mutation;

  const onSubmit = (data: IFindGrantsVariables) => {
    mutation.mutate(data);
  };

  const [grantData, setGrantData] = useState<IGrantList[]>([]);

  const list = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <ProtectedPage>
      <FormProvider {...methods}>
        <Box
          // backgroundColor={"#2A2B6E"}
          marginTop={-5}
          h={"100vh"}
          bgGradient="linear(to-r, #2A2B6E, #085C94)"
        >
          <Container marginTop={"20px"} minW={"100%"}>
            <HStack justifyContent={"center"}>
              {/* <Box maxW={300}>
                <Image src="https://imagedelivery.net/LStsWRQowienWhMtIwNrAQ/625e403e-b710-4ac9-affc-433b6296d700/public" />
              </Box> */}
              <Heading
                // fontWeight={"thin"}
                color={"#DFFF00"}
              >
                지원사업 및 아이템 선정 System
              </Heading>
            </HStack>
          </Container>
          <HStack
            as="form"
            m="3vh"
            spacing={50}
            onSubmit={handleSubmit(onSubmit)}
          >
            <VStack maxW={"800px"} spacing={"30px"}>
              <Box
                p={"2vh"}
                minW={"100%"}
                // borderRadius={"20px"}
                bgColor={gridItemColor}
                // bgGradient="linear(to-r, #2A2B6E, #085C94)"
                shadow={"2xl"}
              >
                <Heading fontWeight={"thin"} color={"white"}>
                  ✔ 기업규모를 선택하십시오.
                </Heading>
                <Stack
                  paddingTop={"20px"}
                  direction="row"
                  justifyContent={"space-between"}
                  mx={"3vh"}
                >
                  <Checkbox
                    fontWeight={"thin"}
                    color={"white"}
                    size={"lg"}
                    {...register("company_size_all")}
                  >
                    전체
                  </Checkbox>
                  <Checkbox
                    fontWeight={"thin"}
                    color={"white"}
                    size={"lg"}
                    {...register("company_size_small")}
                  >
                    중소기업
                  </Checkbox>
                  <Checkbox
                    fontWeight={"thin"}
                    color={"white"}
                    size={"lg"}
                    {...register("company_size_mid")}
                  >
                    중견기업
                  </Checkbox>
                  <Checkbox
                    fontWeight={"thin"}
                    color={"white"}
                    size={"lg"}
                    {...register("company_size_major")}
                  >
                    대기업
                  </Checkbox>
                </Stack>
              </Box>
              <EnergyCal gridItemColor={gridItemColor} />
              <Button
                type="submit"
                colorScheme="facebook"
                w={"100%"}
                fontSize={"lg"}
              >
                Submit
              </Button>
            </VStack>
            {skeletonLoading ? (
              <HStack>
                <Box
                  // height={"50vh"}
                  // width={"50vW"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingRight={10}
                  // position={"absolute"}
                  // right={0}
                  // zIndex={1}
                >
                  <Spinner color={"white"} width="300px" height="300px" />
                </Box>
                <Heading color={"white"}>Loading...</Heading>
              </HStack>
            ) : (
              <motion.div variants={list} initial="hidden" animate="visible">
                {grantData?.map((grant) => (
                  <GrantProgramList
                    pk={grant.pk}
                    grant_name={grant.grant_name}
                    announcement_date={grant.announcement_date}
                    budget={grant.budget}
                    announcement={grant.announcement}
                    base_year={grant.base_year}
                    support_ratio_amount={grant.support_ratio_amount}
                    // grants_gitem={grant.grants_gitem}
                    organizer={grant.organizer}
                  />
                ))}
              </motion.div>
            )}
          </HStack>
        </Box>
      </FormProvider>
    </ProtectedPage>
  );
}
