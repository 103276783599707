import ReactApexChart from "react-apexcharts";

export default function ServiceDashBoard() {
  //
  const options = {
    chart: {
      type: "bar" as const,
      // height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
  };
  //
  const series = [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ];

  return (
    <div>
      <p>전체관리고객사</p>
      <p>전체관리장비</p>
      <p>OVER 100%, 75%, 50%, 25%, 0%</p>
      <p>PART</p>
      <p>SCREWOVERHAUL</p>
      <p>MAINMOTOR</p>
      <p>FANMOTOR</p>
      <p>INVERTOR</p>
      <p>COOLER</p>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="900px"
      />
    </div>
  );
}
