import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../ProtectedPage";
import { useQuery } from "@tanstack/react-query";
import { IGrantList } from "../../types";
import { getGrantList } from "../../api";
import { Link } from "react-router-dom";

export default function GrantList() {
  const { data } = useQuery<IGrantList[]>([`grant`], getGrantList);

  return (
    <ProtectedPage>
      <Grid
        templateColumns="repeat(1, 1fr)"
        gap={5}
        bgGradient="linear(to-r, #2A2B6E, #085C94)"
        p={"2rem"}
      >
        {data?.map((grant) => (
          <GridItem
            key={grant.pk}
            shadow={"dark-lg"}
            p={"1rem"}
            borderRadius={"2xl"}
          >
            <Link to={`/grants/${grant.pk}`}>
              <HStack>
                <Box maxW={200} marginRight={10}>
                  <Image src={grant.organizer.organizer_cis.file} />
                </Box>
                <VStack>
                  <Text fontSize={"2xl"} color={"white"}>
                    {grant.grant_name}
                  </Text>
                  <HStack gap={5}>
                    <Text color={"white"} fontWeight={"thin"}>
                      공시일자:
                      {grant.announcement_date}
                    </Text>
                    <Text color={"white"} fontWeight={"thin"}>
                      회차:
                      {grant.announcement}차
                    </Text>
                    <Text color={"white"} fontWeight={"thin"}>
                      기준년도:
                      {grant.base_year}차
                    </Text>
                    <Text color={"white"} fontWeight={"thin"}>
                      예산규모:
                      {grant.budget} 억원
                    </Text>
                    <Text color={"white"} fontWeight={"thin"}>
                      지원금액 및 비율 : {grant.support_ratio_amount}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            </Link>
          </GridItem>
        ))}
      </Grid>
    </ProtectedPage>
  );
}
